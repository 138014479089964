import { getReportConsolidatedInRange } from '@/requests/report'
import { showDefaultErrorMessage } from '@/utils/createMessage'
import {
  SET_REPORT,
  CHANGE_LOADING
} from '@/store/mutationsTypes'
const GENERATE_TABLE_VIEW_BY_PEROPT = 'TOGGLE_ADD_PERCENT'
const CHANGE_TABLE_VIEW_CHECK = 'CHANGE_TABLE_VIEW'
const CHANGE_SCHECKED_STATUS_ON_COLUMNS = 'CHANGE_SCHECKED_STATUS_ON_COLUMNS'
const CHANGE_IS_SHOW_HIDE_COLUMNS = 'CHANGE_IS_SHOW_HIDE_COLUMNS'
const SET_CHANGE = 'SET_CHANGE'
const state = () => ({
  tableView: [],
  isLoading: true,
  isShowHideColumns: false,
  change: 'common',

  hideColumns: ['ГИБДД', 'Депозит', 'Омыватель', 'Внутренняя страховка', 'Рассрочка', 'Внутренний штраф', 'Другое', 'Эффективность', 'Опоздания', 'Не заправлен БЕНЗИН', 'Не заправлен ГАЗ', 'Курение', 'Грязный авто', 'ДТП', 'Смена', 'Смена НДС', 'Работа камеры', 'Крепеж камеры', 'Авто в личных целях'],
  consolidatedReport: [],
  columnsDesctiption: [
    {
      columnName: 'Всего возможных аренд (12ч) - ВСЕГО',
      description: 'Общее количество возможных арендных периодов (12ч), учитывая все автомобили, в т.ч. в ремонте и органическом простое.'
    },
    {
      columnName: 'Количество простоев (12ч) из-за ремонта',
      description: 'Общее количесто смен, в которое автомобили находились в краткосрочном, среднесрочном и долгосрочном ремонте.'
    },
    {
      columnName: 'Количество упущенных аренд ВСЕГО',
      description: 'Общее количесто смен, в которое автомобили находились в органическом простое, без учета автомобилей в ремонте'
    },
    {
      columnName: '% загрузки',
      description: 'Средний % загрузки автопарка, учитывая органический и ремонтный простой.'
    },
    {
      columnName: '% ремонта',
      description: 'Средний % арендных периодов затраченных на ремонт относительно общего количества арендных периодов.'
    },
    {
      columnName: '% простоя с ремонтом',
      description: 'Средний % органического простоя. Автомобили в работоспособном состоянии, но не сданные в аренду.'
    },
    {
      columnName: 'Накат',
      description: 'Общий накат, полученный от использования автомобилей'
    },
    {
      columnName: 'Накат',
      description: 'Общий накат, полученный от использования автомобилей'
    },
    {
      columnName: 'Валовый доход арендатора',
      description: 'Общий доход арендаторов, полученный от использования автомобилей без учета комиссий агрегаторов, партнера и т.д.'
    }

  ]
})
const helpers = {
  lineСontainsСhange (string, change, type) {
    if (change === 'common') return false
    if (!string.includes('(')) return false
    if (type === 'morning' && change === 'night') return false
    if (type === 'night' && change === 'morning') return false
    return true
  }
}
const getters = {
  getCheckedColumns: (state) => {
    return state.consolidatedReport.reduce((accamulatorReport, reportCurrentValue) => {
      const { columns } = reportCurrentValue
      if (!columns) return [...accamulatorReport]
      const redusedColumns = columns.reduce((prevValue, currentValue) => {
        const { title, result, type } = currentValue
        if (state?.tableView.filter(i => i?.title === title)[0]?.checked) {
          if (helpers.lineСontainsСhange(title, state.change, type)) {
            return { ...prevValue }
          }
          return { ...prevValue, [title]: result, ...reportCurrentValue.period }
        }
        return { ...prevValue }
      }, {})
      return [redusedColumns, ...accamulatorReport]
    }, [])
  },
  getOnlyTitlesCheckedColumns: (state) => {
    return state.tableView.filter(element => element.checked && !helpers.lineСontainsСhange(element.title, state.change, element.type)).map(element => element.title)
  },
  checkDesctiptionItem: (state) => {
    return (columnName) => {
      return state?.columnsDesctiption.filter(t => t.columnName.includes(columnName))
    }
  }
}

const mutations = {

  [CHANGE_TABLE_VIEW_CHECK]: (state, index) => {
    state.tableView[index].checked = !state.tableView[index].checked
  },
  [SET_REPORT]: (state, report) => {
    state.consolidatedReport = report
  },
  [CHANGE_IS_SHOW_HIDE_COLUMNS]: (state) => {
    state.isShowHideColumns = !state.isShowHideColumns
  },
  [CHANGE_SCHECKED_STATUS_ON_COLUMNS]: (state) => {
    state.tableView = state.tableView.reduce((prevValue, currentValue, index, array) => {
      if (state.hideColumns.some(v => currentValue.title === v)) {
        return [...prevValue, { title: currentValue.title, checked: state.isShowHideColumns }]
      }
      return [...prevValue, { ...currentValue }]
    }, []) || []
  },
  [CHANGE_LOADING]: (state, value) => {
    state.isLoading = value
  },
  [SET_CHANGE]: (state, value) => {
    state.change = value
  },
  [GENERATE_TABLE_VIEW_BY_PEROPT]: (state) => {
    if (state.tableView.length > 0 && state.tableView.length === state?.consolidatedReport[0]?.columns.length && state.tableView[0]?.type) return
    state.tableView = state?.consolidatedReport[0]?.columns.reduce((prevValue, currentValue, index, array) => {
      return [...prevValue, { title: currentValue?.title, checked: true }]
    }, []) || []
  }
}

const actions = {

  async changeTableColumnVisibility ({ commit }, getIndex) {
    try {
      commit(CHANGE_TABLE_VIEW_CHECK, getIndex)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async setCheckedOrUncheckedSpecificColumns ({ commit }) {
    try {
      commit(CHANGE_IS_SHOW_HIDE_COLUMNS)
      commit(CHANGE_SCHECKED_STATUS_ON_COLUMNS)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async setChange ({ commit }, someChange) {
    try {
      commit(SET_CHANGE, someChange)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async fetchReportConsolidatedInRange ({ commit }, { start, end, region_park_id }) {
    try {
      commit(CHANGE_LOADING, true)
      const report = await getReportConsolidatedInRange({ start, end, region_park_id })
      await commit(SET_REPORT, report)

      commit(GENERATE_TABLE_VIEW_BY_PEROPT, report)
      commit(CHANGE_SCHECKED_STATUS_ON_COLUMNS)
      commit(CHANGE_LOADING, false)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
