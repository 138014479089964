import moment from 'moment'
export const groupCarDates = (carDates, currentWeek) => {
  // Group two objects, day and night, by date for the schedule

  // Create initial object for next reduce
  const initialObj = currentWeek.reduce((acc, date) => {
    acc[date] = {
      day: null,
      night: null
    }

    return acc
  }, {})
  return carDates.reduce((accumulator, currentDate) => {
    const {
      date,
      day,
      night
    } = currentDate
    // const timesOfDay = day !== null ? 'day' : 'night'

    // Add data at the correct time of day
    // accumulator[date][timesOfDay] = {
    //   ...day,
    //   ...night,
    //   schedule_id,
    //   is_documents_exists
    // }

    if (day) {
      accumulator[date].day = {
        ...day
      }
    }

    if (night) {
      accumulator[date].night = {
        ...night
      }
    }
    return accumulator
  }, initialObj)
}

// export const filterShedule = (state_filter, schedule) => {
//   const { carCallsign, carNumber, carModel } = state_filter
//   const filterParams = (currentObject) => {
//     const { car_callsign, car_number, car_model } = currentObject
//     return (car_callsign.includes(carCallsign)) && (car_number.includes(carNumber)) && (car_model.includes(carModel))
//   }
//   return schedule.filter(v => filterParams(v))
// }
export const filterShedule = (carCallsign, carNumber, carModel) => {
  return (currentObject) => {
    return currentObject.car_callsign.includes(carCallsign) && currentObject.car_number.includes(carNumber) && currentObject.car_model.includes(carModel)
  }
}
export const formatDateToISO = (date) => date.toISOString().slice(0, 10)

export const getDatesInRange = (start, end) => {
  // Get all dates array in a range from and to in ISO format
  // Convert dates into an easy-to-use format
  const startDate = new Date(start)
  const endDate = new Date(end)

  // Get the number of days in a range from the difference of start and Конечная датаs
  const timeDifference = Math.abs(endDate.getTime() - startDate.getTime())
  const daysNum = Math.ceil(timeDifference / (1000 * 3600 * 24))

  // Init array for dates
  const datesArray = []

  // Set the first day in the range
  datesArray.push(formatDateToISO(startDate))

  // Add the rest of the dates to the array
  for (let i = 0; i < daysNum; i++) {
    startDate.setDate(startDate.getDate() + 1)
    datesArray.push(formatDateToISO(startDate))
  }

  return datesArray
}
export const searchDriver = (lastName) => {
  return (currentObject) => {
    const x = currentObject.last_name.toLowerCase()

    const y = lastName.toLowerCase()

    return x.includes(y)
  }
}
/**
  * @param state state - расписания.
  * @param data данные которые нужно фильтровать.
  * @info Фильтруем по тем у кого статус соответствует state.filters.indicators.is_working
  */
export const filtredByIndicatorIsWorking = (state, data) => {
  return data.reduce((acc, value) => {
    const dataReuce = Object.keys(value.dates).reduce((accDatas, valueDataes) => {
      const { day, night } = value.dates[valueDataes]
      if (!value.dates[valueDataes]?.day && !value.dates[valueDataes]?.night) {
        accDatas = {
          ...accDatas,
          [valueDataes]: {
            day, night
          }
        }
        return accDatas
      }
      accDatas = { ...accDatas, [valueDataes]: { day, night } }
      if (value.dates[valueDataes]?.day?.indicators?.is_working !== undefined && value.dates[valueDataes]?.day?.indicators?.is_working !== state.filter?.indicators?.is_working) {
        accDatas[valueDataes].day = null
      }
      if (value.dates[valueDataes]?.night?.indicators?.is_working !== undefined && value.dates[valueDataes]?.night?.indicators?.is_working !== state.filter?.indicators?.is_working) {
        accDatas[valueDataes].night = null
      }
      return accDatas
    }, {})

    acc = [...acc, {
      ...value,
      dates: dataReuce
    }]
    return acc
  }, [])
}

/**
* @param driver_id id водителя
* @param state state schedule
* @info Вернет true если водитель был найден в списке игнорируемых в state и время его индикатора не стлекло
*/
const getDriverOnIgnoreWorkingIndicatorList = (driver_id, state) => {
  const result = state?.ignoringDriverWorking.filter(driver => driver?.driver_id === driver_id)
  if (result?.length === 0) {
    return { state: false, isDelete: false }
  }
  const { expirationDate } = result[0]
  if (moment().isBefore(expirationDate)) {
    return { state: true, isDelete: false }
  } else {
    // const deleteDriver = state?.ignoringDriverWorking.filter(driver => driver?.driver_id === driver_id)
    return { state: false, isDelete: true }
  }
}
export const deleteDriversIfExpDateLost = (ignoringDriverWorking) => {
  return ignoringDriverWorking.reduce((acc, value) => {
    acc = [...acc]
    if (moment().isBefore(value.expirationDate)) {
      acc.push(value)
    }
    return acc
  }, [])
}
/**
  * @param state state - расписания.
  * @param data данные которые нужно фильтровать.
  * @info Заменяем is_working на true если водитель есть в списке игнорируемых
  */
export const reduceByIgnoreListIndicatorsIsWorking = (state, data) => {
  return data.reduce((acc, value) => {
    const dataReuce = Object.keys(value.dates).reduce((accDatas, valueDataes) => {
      const { day, night } = value.dates[valueDataes]
      if (!value.dates[valueDataes]?.day && !value.dates[valueDataes]?.night) {
        accDatas = {
          ...accDatas,
          [valueDataes]: {
            day, night
          }
        }
        return accDatas
      }
      accDatas = { ...accDatas, [valueDataes]: { day, night } }
      /**
      * @param change Смена day/night
      * @info Заменяет значение в аккамуляторе
      */
      const checkChangeAndReplaceData = (change) => {
        if (value.dates[valueDataes][change]) {
          const { id } = value.dates[valueDataes][change]
          if (getDriverOnIgnoreWorkingIndicatorList(id, state).state) {
            accDatas[valueDataes][change].indicators.is_working = true
          }
        }
      }
      checkChangeAndReplaceData('day')
      checkChangeAndReplaceData('night')
      // if (value.dates[valueDataes]?.day) {
      //   const { id } = value.dates[valueDataes]?.day
      //   if (getDriverOnIgnoreWorkingIndicatorList(id)) {
      //     accDatas[valueDataes].day = {
      //       ...day,
      //       indicators: {
      //         is_working: true
      //       }
      //     }
      //   }
      // }
      // if (value.dates[valueDataes]?.night) {
      //   const { id } = value.dates[valueDataes]?.night
      //   if (getDriverOnIgnoreWorkingIndicatorList(id)) {
      //     accDatas[valueDataes].night = {
      //       ...night,
      //       indicators: {
      //         is_working: true
      //       }
      //     }
      //   }
      // }
      return accDatas
    }, {})

    acc = [...acc, {
      ...value,
      dates: dataReuce
    }]
    return acc
  }, [])
}
