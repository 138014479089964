import request from '../utils/request'

export const getCarsLoadData = (startDate, endDate) => request({
  method: 'get',
  url: `/taxi/statistics/car_load?start_date=${startDate}&end_date=${endDate}`
})

export const getDriversIncomeData = (startDate, endDate) => request({
  method: 'get',
  url: `/taxi/statistics/drivers_income?start_date=${startDate}&end_date=${endDate}`
})

export const getFines = (startDate, endDate) => request({
  method: 'get',
  url: `/taxi/statistics/fines?start_date=${startDate}&end_date=${endDate}`
})

export const getGeneralFinance = (startDate, endDate) => request({
  method: 'get',
  url: `/taxi/statistics/general_finance?start_date=${startDate}&end_date=${endDate}`
})

export const getDriversDebts = (startDate, endDate) => request({
  method: 'get',
  url: `/taxi/statistics/drivers_debts?start_date=${startDate}&end_date=${endDate}`
})
