import {
  acceptMaintenance, createMaintenanceWork,
  deleteMaintenanceWork, fetchMaintenanceHistoryById, fetchMaintenanceTypes, fetchMaintenanceWork,
  fetchMaintenanceWorkById, fetchMaintenanceWorkHistory,
  updateMaintenanceWork, returnCarToOnline
} from '@/requests/techInspection'
import {
  showErrorMessage,
  showSuccessMessage
} from '@/utils/createMessage'
import moment from 'moment'
const TOGGLE_LOADING = 'TOGGLE_LOADING'
const TOGGLE_LOADING_TYPES = 'TOGGLE_LOADING_TYPES'
// const TOGGLE_LOADING_MAINTENANCE_TRUE = 'TOGGLE_LOADING_TYPES'
// const TOGGLE_LOADING_MAINTENANCE_FALSE = 'TOGGLE_LOADING_TYPES'
const SET_MAINTENANCE_WORK = 'SET_MAINTENANCE_WORK'
const ADD_MAINTENANCE_WORK = 'ADD_MAINTENANCE_WORK'
const REMOVE_MAINTENANCE_WORK = 'REMOVE_MAINTENANCE_WORK'
const UPDATE_MAINTENANCE_WORK = 'UPDATE_MAINTENANCE_WORK'
const SET_MAINTENANCE_TYPES = 'SET_MAINTENANCE_TYPES'
const SET_MAINTENANCE_HISTORY = 'SET_MAINTENANCE_HISTORY'
const ADD_MAINTENANCE_HISTORY = 'ADD_MAINTENANCE_HISTORY'
const SET_FILTERS = 'SET_FILTERS'
const state = {
  isLoading: true,
  isLoadingTypes: true,
  // isLoadingMaintenanceRequestFalse: true,
  // isLoadingMaintenanceRequestTrue: true,
  maintenanceWork: [],
  maintenanceTypes: [],
  maintenanceHistory: [],
  filters: {
    stateNumber: '',
    serviceTypeId: '',
    sortiong: null
  }
}

const mutations = {
  [TOGGLE_LOADING] (state, bool) {
    state.isLoading = bool
  },
  [TOGGLE_LOADING_TYPES] (state, bool) {
    state.isLoadingTypes = bool
  },
  // [TOGGLE_LOADING_MAINTENANCE_TRUE] (state, bool) {
  //   state.isLoadingMaintenanceRequestTrue = bool
  // },
  // [TOGGLE_LOADING_MAINTENANCE_FALSE] (state, bool) {
  //   state.isLoadingMaintenanceRequestFalse = bool
  // },
  [SET_MAINTENANCE_WORK] (state, data) {
    state.maintenanceWork = data
  },

  [ADD_MAINTENANCE_WORK] (state, item) {
    state.maintenanceWork.push(item)
  },

  [SET_MAINTENANCE_TYPES] (state, array) {
    state.maintenanceTypes = array
  },

  [UPDATE_MAINTENANCE_WORK] (state, item) {
    const index = state.maintenanceWork.findIndex(e => e.id === item.id)
    state.maintenanceWork.splice(index, 1, item)
  },

  [REMOVE_MAINTENANCE_WORK] (state, id) {
    const index = state.maintenanceWork.findIndex(e => e.id === id)
    state.maintenanceWork.splice(index, 1)
  },

  [SET_MAINTENANCE_HISTORY] (state, array) {
    state.maintenanceHistory = array
  },

  [ADD_MAINTENANCE_HISTORY] (state, item) {
    state.maintenanceHistory.push(item)
  },

  [SET_FILTERS] (state, filters) {
    state.filters = filters
  }
}
const actions = {
  async fetchMaintenanceTypes ({ commit }) {
    try {
      commit(TOGGLE_LOADING_TYPES, true)

      const response = await fetchMaintenanceTypes()

      commit(SET_MAINTENANCE_TYPES, response)
    } catch (err) {
      showErrorMessage('Не удалось загрузить типы ремонта')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING_TYPES, false)
    }
  },

  async fetchMaintenanceWorkHistory ({ commit }) {
    try {
      commit(TOGGLE_LOADING, true)

      const response = await fetchMaintenanceWorkHistory()

      commit(SET_MAINTENANCE_HISTORY, response)
    } catch (err) {
      showErrorMessage('Не удалось загрузить историю ремонта')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },

  async fetchMaintenanceHistoryById ({ commit }, historyId) {
    try {
      return await fetchMaintenanceHistoryById(historyId)
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    }
  },

  async fetchMaintenanceWork ({ commit }, { on_request }) {
    try {
      commit(TOGGLE_LOADING, true)

      const response = await fetchMaintenanceWork({ on_request })

      commit(SET_MAINTENANCE_WORK, response)
    } catch (err) {
      showErrorMessage('Не удалось загрузить записи тех обслуживаний')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },

  async fetchMaintenanceWorkById ({ commit }, id) {
    try {
      commit(TOGGLE_LOADING, true)

      return await fetchMaintenanceWorkById(id)
    } catch (err) {
      showErrorMessage('Не удалось получить запись')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },

  async createMaintenanceWork ({ commit }, dto) {
    try {
      commit(TOGGLE_LOADING, true)

      const record = await createMaintenanceWork(dto)
      record.type = dto.type
      record.car_millage = dto.millage
      record.repair_sum = dto.repair_sum
      record.comment = dto.comment
      commit(ADD_MAINTENANCE_WORK, record)

      showSuccessMessage('Запись создана')
    } catch (err) {
      showErrorMessage('Не удалось создать запись')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },

  async deleteMaintenanceWork ({ commit }, { request, comment }) {
    try {
      commit(TOGGLE_LOADING, true)
      await deleteMaintenanceWork({
        id: request.id,
        comment_deleted: comment
      })

      request.is_deleted = true
      request.comment_deleted = comment

      commit(UPDATE_MAINTENANCE_WORK, request)

      showSuccessMessage('Заявка удалена')
    } catch (err) {
      showErrorMessage('Не удалось удалить заявку')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },

  async updateMaintenanceWork ({ commit }, data) {
    try {
      commit(TOGGLE_LOADING, true)

      const response = await updateMaintenanceWork(data)

      commit(UPDATE_MAINTENANCE_WORK, response)
    } catch (err) {
      showErrorMessage('Не удалось обновить заявку')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },

  async acceptMaintenance ({ commit }, { record, data }) {
    try {
      commit(TOGGLE_LOADING, true)

      await acceptMaintenance({ id: record.id, data })

      commit(REMOVE_MAINTENANCE_WORK, record.id)
      commit(ADD_MAINTENANCE_HISTORY, record)
    } catch (err) {
      showErrorMessage('Не удалось обновить заявку')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },

  async returnCarToOnline ({ commit, getters }, id) {
    try {
      await returnCarToOnline(id)
      // console.log(getters.setOnlineStatusCar(id))
    } catch (err) {
      showErrorMessage('Не удалось обновить заявку')
      return Promise.reject(err)
    }
  },

  setFilters ({ commit }, filters) {
    commit(SET_FILTERS, filters)
  }
}

const getters = {
  sortedDataByFilter (state) {
    return (arrProps) => {
      let { stateNumber, serviceTypeId, sortiong } = state.filters
      stateNumber = stateNumber.toLowerCase()
      // if (!stateNumber && !serviceTypeId && !sortiong) return arrProps
      if (stateNumber) {
        arrProps = arrProps.filter(e => e.car_number.toLowerCase().indexOf(stateNumber) !== -1)
      }
      if (serviceTypeId) {
        arrProps = arrProps.filter(e => e.type === serviceTypeId)
      }
      if (sortiong === 'new') {
        arrProps = arrProps.sort((a, b) => {
          console.log(moment(a.created_at).valueOf(), moment(b.created_at).valueOf())
          return moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
        })
      }
      if (sortiong === 'old') {
        arrProps = arrProps.sort((a, b) => b.created_at.valueOf() - a.created_at.valueOf())
      }
      return arrProps
    }
  },
  sortedDataByFilterHistory (state) {
    return (arrProps) => {
      let { stateNumber, serviceTypeId } = state.filters
      if (!stateNumber && !serviceTypeId) return arrProps
      stateNumber = stateNumber.toLowerCase()
      return arrProps.filter(e => (serviceTypeId ? e.type === serviceTypeId : true) && (stateNumber ? e.car_number.toLowerCase().indexOf(stateNumber) !== -1 : true))
    }
  },
  setOnlineStatusCar (state) {
    return (car_id) => {
      return state.maintenanceWork.filter(e => !e.offline && e.on_request && !e.is_deleted && e.car_id === car_id)
    }
  },

  offlineTechInspectionRequests (state) {
    return state.maintenanceWork.filter(e => e.offline && e.on_request && !e.is_deleted)
  },

  onlineTechInspectionRequests (state) {
    return state.maintenanceWork.filter(e => !e.offline && e.on_request && !e.is_deleted)
  },

  deletedTechInspectionRequests (state) {
    return state.maintenanceWork.filter(e => e.is_deleted && e.on_request)
  },
  getMaintenanceTypesNames (state) {
    const types = state.maintenanceTypes

    // Собираем родительские типы в удобном формате
    return types.reduce((acc, type) => {
      const { id, name, alias } = type

      acc.push({
        id,
        name,
        alias
      })

      return acc
    }, [])
  },
  groupedMaintenanceTypes (state) {
    const types = state.maintenanceTypes

    // Собираем родительские типы в удобном формате
    const parentTypes = types.reduce((acc, type) => {
      if (type.parent_id !== '00000000-0000-0000-0000-000000000000') return acc

      const { id, name, alias } = type

      acc[id] = {
        name,
        alias,
        children: []
      }

      return acc
    }, {})

    // Добавляем подтипы в children
    for (const index in types) {
      const type = types[index]

      if (type.parent_id === '00000000-0000-0000-0000-000000000000') continue

      parentTypes[type.parent_id].children.push(type.id)
      // TODO: Избавиться от type.name
      parentTypes[type.parent_id].children.push(type.name)
    }

    return parentTypes
  },

  getMaintenanceWorkByTypeIds (state) {
    return (typeIds) => {
      return state.maintenanceWork.filter(e => typeIds.includes(e.type))
    }
  },

  getMaintenanceHistoryByTypeIds (state) {
    return (typeIds, isLazyLoad = false) => {
      return state.maintenanceHistory.filter((e, index) => typeIds.includes(e.type) && (isLazyLoad ? index < 35 : true))
    }
  },

  getHistoryNameById: (state) => (id) => {
    if (state.maintenanceTypes.length === 0) return
    return state.maintenanceTypes.filter(e => e.id === id)[0].name
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
