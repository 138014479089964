import {
  fetchRegionPark, createPegionPark,
  updatePegionPark, deletePegionPark, driverUnbind, driverBind,
  getPegionParkByID,
  fetchParkSettingsMinMaxSum,
  createParkSettings
} from '@/requests/regionPark'

import { deleteParkSettings } from '@/requests/settings'
import {
  showErrorMessage,
  showDefaultErrorMessage,
  showSuccessMessage
} from '@/utils/createMessage'
import { Message } from 'element-ui'

const TOGGLE_LOADING = 'TOGGLE_LOADING'
const TOGGLE_LOADING_MIN_MAX = 'TOGGLE_LOADING_MIN_MAX'
const SET_REGION_PARK = 'SET_REGION_PARK'
const ADD_REGION_PARK = 'ADD_REGION_PARK'
const REMOVE_REGION_PARK = 'REMOVE_REGION_PARK'
const UPDATE_REGION_PARK = 'UPDATE_REGION_PARK'
const REMOVE_SETTINGS_ID_DATA_PARK = 'REMOVE_SETTINGS_ID_DATA_PARK'
const ADD_SETTINGS_ID_DATA_PARK = 'ADD_SETTINGS_ID_DATA_PARK'

const state = {
  isLoading: true,
  isLoadingMinMax: true,
  regionPark: []
}

const mutations = {
  [TOGGLE_LOADING] (state, bool) {
    state.isLoading = bool
  },
  [TOGGLE_LOADING_MIN_MAX] (state, bool) {
    state.isLoadingMinMax = bool
  },

  [SET_REGION_PARK] (state, data) {
    state.regionPark = data
  },

  [ADD_REGION_PARK] (state, item) {
    state.regionPark.push(item)
  },

  [UPDATE_REGION_PARK] (state, item) {
    const index = state.regionPark.findIndex(e => e.id === item.id)
    state.regionPark.splice(index, 1, item)
  },
  [REMOVE_SETTINGS_ID_DATA_PARK]: (state, settings_id) => {
    const index = state.regionPark.findIndex(e => e.settings_id === settings_id)
    if (index === -1) return
    state.regionPark[index] = {
      ...state.regionPark[index],
      settings_id: null
    }
  },
  [ADD_SETTINGS_ID_DATA_PARK]: (state, settings_id) => {
    const index = state.regionPark.findIndex(e => e.settings_id === settings_id)
    if (index === -1) return
    state.regionPark[index] = {
      ...state.regionPark[index],
      settings_id
    }
  },
  [REMOVE_REGION_PARK] (state, id) {
    const index = state.regionPark.findIndex(e => e.id === id)
    state.regionPark.splice(index, 1)
  }

}
const actions = {

  async fetchRegionPark ({
    dispatch,
    commit,
    getters,
    rootGetters
  }, park_id) {
    try {
      commit(TOGGLE_LOADING, true)
      park_id = park_id || rootGetters['settings/getDefaultParkPk']
      console.log(rootGetters['settings/getDefaultParkPk'])
      const response = await fetchRegionPark(park_id)

      commit(SET_REGION_PARK, response)
    } catch (err) {
      showErrorMessage('Не удалось загрузить региональные парки')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },
  createParkSettings ({ commit }, data) {
    return new Promise((resolve, reject) => {
      createParkSettings(data).then(res => {
        Message({
          message: 'Настройки были созданы',
          type: 'success',
          duration: 5 * 1000
        })
        commit(ADD_SETTINGS_ID_DATA_PARK, {
          settings_id: data?.id,
          park_id: data?.park_id
        })
        resolve(res)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  async fetchParkSettingsMinMaxSum ({
    dispatch,
    commit,
    getters,
    rootGetters
  }, park_id) {
    try {
      commit(TOGGLE_LOADING_MIN_MAX, true)
      const response = await fetchParkSettingsMinMaxSum(park_id)
      commit(TOGGLE_LOADING_MIN_MAX, false)
      return response
    } catch (err) {
      showErrorMessage('Не удалось загрузить максимальные значения удержаний в парках')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING_MIN_MAX, false)
    }
  },
  async driverUnbind ({ commit }, data) {
    try {
      return await driverUnbind(data)
    } catch (err) {
      showErrorMessage('Не удалось временно освободить водителя')
      return Promise.reject(err)
    }
  },
  async driverBind ({ commit }, data) {
    try {
      return await driverBind(data)
    } catch (err) {
      showErrorMessage('Не удалось вернуть водителя в парк')
      return Promise.reject(err)
    }
  },
  async createPegionPark ({ commit }, data) {
    try {
      // return await createPegionPark({ name, park_id })
      return new Promise((resolve, reject) => {
        createPegionPark(data).then(res => {
          showSuccessMessage('Региональный парк успешно создан')
          commit(ADD_REGION_PARK, res)
          resolve(res)
        }).catch(error => {
          showErrorMessage('Не удалось создать региональный парк')
          reject(error)
        })
      })
    } catch (err) {
      showErrorMessage('Не удалось создать региональный парк')
      return Promise.reject(err)
    }
  },
  async deleteParkSettings ({
    commit,
    state
  }, settings_id) {
    try {
      console.log(settings_id)
      return await deleteParkSettings(settings_id)
        .then(data => {
          console.log(data)
          commit('REMOVE_SETTINGS_ID_DATA_PARK', settings_id)
          showSuccessMessage('Региональный парк успешно удален')
          return data
        })
        .catch((error) => {
          showDefaultErrorMessage()
          return error
        })
      // commit('SET_CHANGES', StatisticShanges)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async updatePegionPark ({ commit }, data) {
    try {
      commit(TOGGLE_LOADING, true)
      const response = await updatePegionPark(data)
      commit(UPDATE_REGION_PARK, response)
      showSuccessMessage('Региональные парки успешно обновленны')
    } catch (err) {
      showErrorMessage('Не удалось обновить региональные парки')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },
  async updateSettingsIdPegionPark ({
    commit,
    state
  }, {
    id,
    settings_id
  }) {
    try {
      commit(TOGGLE_LOADING, true)
      const getData = state.regionPark.filter(x => x.id === id)[0]
      const response = await updatePegionPark({
        ...getData,
        settings_id
      })
      commit(UPDATE_REGION_PARK, response)
    } catch (err) {
      showErrorMessage('Не удалось обновить региональные парки')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },
  async deletePegionPark ({ commit }, id) {
    try {
      commit(TOGGLE_LOADING, true)
      await deletePegionPark(id)

      commit(REMOVE_REGION_PARK, id)

      showSuccessMessage('Региональный парк удален')
    } catch (err) {
      showErrorMessage('Не удалось удалить региональный парк')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },
  async getPegionParkByID ({ commit }, id) {
    try {
      // commit(TOGGLE_LOADING, true)
      const res = await getPegionParkByID(id)

      // commit(UPDATE_REGION_PARK, res)
      return res
      // showSuccessMessage('Региональный парк удален')
    } catch (err) {
      showErrorMessage('Не удалось получить региональный парк')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  }
}

const getters = {
  getRegionalCarParkByID (state) {
    return (park_id) => {
      return state.regionPark.filter(e => e.id === park_id)[0] || false
    }
  },
  getBaseInfo () {
    return state.regionPark.map(e => {
      return {
        id: e.id,
        name: e.name
      }
    })
  },
  getSettingsIdByParkId (state) {
    return (parkId) => {
      if (!parkId || parkId === 'all') return undefined
      const park = state.regionPark.filter(e => e.id === parkId)[0]
      return park.settings_id || park.parent_settings_id || undefined
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
