import * as helpers from '@/store/modules/schedule/helpers'
import _ from 'lodash'

export const getters = {
  filteredSchedule: (state) => {
    // let preSortSchedule = helpers.filterShedule(state.filter, state.schedule)
    let preSortSchedule = state.schedule
      .filter(helpers.filterShedule(state.filter.carCallSign, state.filter.carNumber, state.filter.carModel))
    if (typeof state.filter?.indicators?.is_working !== 'undefined') {
      preSortSchedule = helpers.filtredByIndicatorIsWorking(state, preSortSchedule)
    }
    if (state?.ignoringDriverWorking?.length > 0) {
      state.ignoringDriverWorking = helpers.deleteDriversIfExpDateLost(state.ignoringDriverWorking)
      preSortSchedule = helpers.reduceByIgnoreListIndicatorsIsWorking(state, preSortSchedule)
    }
    if (state.filter.loadSort) {
      preSortSchedule = _.sortBy(preSortSchedule, ({ dates }) => {
        let loadLevel = 0

        for (const date in dates) {
          if (dates[date].day !== null) loadLevel++
          if (dates[date].night !== null) loadLevel++
        }

        return loadLevel
      })

      // preSortSchedule = _.reverse(preSortSchedule)
    }
    if (state.filter.modelSort) {
      preSortSchedule = _.sortBy(preSortSchedule, (object) => {
        return object.car_model
      })
    }
    return preSortSchedule
  },

  getFilteredDrivers: (state) => {
    const newDrivers = JSON.parse(JSON.stringify(state.availableDrivers))
    return newDrivers.filter(helpers.searchDriver(state.filter.last_name))
  },
  getCars: (state) => {
    return state.schedule.map((element) => {
      return {
        car_callsign: element.car_callsign,
        car_id: element.car_id,
        car_model: element.car_model,
        car_number: element.car_number
      }
    })
  },
  getCurrentDriversOnThePark: (state, getters) => {
    if (!state.currentRegionParkID) return getters.getFilteredDrivers
    return getters.getFilteredDrivers.filter(driver => driver.region_park_id === state.currentRegionParkID)
  },
  getNotAssignedDrivers: (state, getters) => {
    return getters.getFilteredDrivers.filter(driver => driver.region_park_id === '00000000-0000-0000-0000-000000000000')
  },
  getDetachedParkDrivers: (state, getters) => {
    return getters.getFilteredDrivers.filter(driver => driver.detached_park === true)
  },
  findDriverInChangeByName: (state) => (name) => {
    return state.driversInChange.filter(driver => {
      const driverName = driver.driver_full_name.toLowerCase()

      return driverName.includes(name.toLowerCase())
    })
  }
}
