import { Message } from 'element-ui'
import { formulaCheck } from '@/requests/сonfigurator'
import { fetchParkSettingsFormulas, updateParkSettingsFormulas } from '@/requests/settings'
import { FormulsNaming, NamesTranslation } from '@/enums/сonfigurator'

const state = {
  data: [],
  formula_check: {
    details_income: 0,
    earn_amount: 0,
    common_tenants_income: 0,
    company_profit: 0,
    give_out_amount: 0
  },
  isLoading: true
}

const mutations = {
  SET: (state, data) => {
    state.data = data
  },
  UPDATE_FORMULS: (state, formuls) => {
    // const getIndexFormulaByName = state.data.findIndex(e => e.formula_name === new_formula.formula_name)
    // if (getIndexFormulaByName === -1) return false
    state.data = formuls
    // state.data = [
    //   new_formula,
    //   ...state.data.filter((v, i) => i !== getIndexFormulaByName)
    // ]
  },
  SET_FORMULA_CHECK_RETURN: (state, value) => {
    state.formula_check = value
  },
  START_LOADING: (state) => {
    state.isLoading = true
  },
  STOP_LOADING: (state) => {
    state.isLoading = false
  }
}
const getters = {
  testG: (state) => state.data[0],
  formulsCheckToNumberFormat: (state) => Object.keys(state.formula_check).reduce((acc, value) => {
    acc = { ...acc, [value]: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'RUB' }).format(state.formula_check[value]) }
    return acc
  }, {}),
  getFormulsTranslete: (state) => {
    console.log('UPDATE getFormulsTranslete')
    const normalFormatFourmuls = Object.keys(state.data).map((x, y) => {
      return {
        formula_value: state.data[x],
        formula_name: x
      }
    })
    console.log(normalFormatFourmuls)
    return normalFormatFourmuls.reduce((acc, value) => {
      const formula_translete = FormulsNaming[value.formula_name] || 'Неизвестная формула'
      const formula__value_translete = value.formula_value?.split(' ').map(x => NamesTranslation[x] || x).join(' ') || 'Неизвестная формула'
      const formula_splited = value.formula_value?.split(' ') || []

      acc = [...acc, {
        ...value,
        formula_translete,
        formula__value_translete,
        formula_splited
      }]
      return acc
    }, [])
  }
}
const actions = {
  async fetchFormuls ({ commit }, settings_id) {
    try {
      commit('START_LOADING')
      const data = await fetchParkSettingsFormulas(settings_id)
      commit('SET', data)
      commit('STOP_LOADING')
      Promise.resolve(data)
    } catch (e) {
      commit('STOP_LOADING')
      return Promise.reject(e)
    }
  },
  updateFormula ({ commit, state }, { data, settings_id }) {
    return new Promise((resolve, reject) => {
      updateParkSettingsFormulas(settings_id, { ...state.data, ...data }).then(res => {
        commit('UPDATE_FORMULS', res)
        Message({
          message: 'Информация была изменена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  formulaCheck ({ commit }, { data, region_park_id }) {
    return new Promise((resolve, reject) => {
      formulaCheck(data, { region_park_id }).then(res => {
        commit('SET_FORMULA_CHECK_RETURN', res)
        Message({
          message: 'Информация была получена!',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(res)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
