import request from '../utils/request'

export const getStockData = () => request({
  method: 'get',
  url: '/stock/parts'
})

export const addStockData = (data) => {
  return request({
    method: 'post',
    url: '/stock/parts',
    data: data
  })
}

export const changeStockData = (id, data) => {
  return request({
    method: 'patch',
    url: `/stock/parts/${id}`,
    data: data
  })
}
