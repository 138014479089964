import request from '../utils/request'

export const getData = (id) => request({
  method: 'get',
  url: `/taxi/cars/${id}/conditions`
})

export const addData = (id, data) => {
  return request({
    method: 'post',
    url: `/taxi/cars/${id}/conditions`,
    data: data
  })
}

export const changeData = (id, data) => {
  return request({
    method: 'patch',
    url: `/taxi/car_conditions/${id}`,
    data: data
  })
}

export const removeData = (id) => {
  return request({
    method: 'delete',
    url: `/taxi/car_conditions/${id}`
  })
}
