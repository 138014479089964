
import { getData } from '@/requests/driversStatistic'

const state = {
  data: []
}

const mutations = {
  SET: (state, data) => {
    state.data = data
  }
}

const actions = {
  fetchDriversStatistic ({ commit }, { startDate, endDate }) {
    return getData(startDate, endDate)
      .then(data => {
        commit('SET', data)
        return data
      })
      .catch((error) => {
        return error
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
