import request from '../utils/request'

export const getOrdersData = () => request({
  method: 'get',
  url: '/stock/orders'
})

export const addOrderData = (data) => {
  return request({
    method: 'post',
    url: '/stock/orders',
    data: data
  })
}

export const changeOrderData = (id, data) => {
  return request({
    method: 'patch',
    url: `/stock/orders/${id}`,
    data: data
  })
}
