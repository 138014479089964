import request from '../utils/request'

export const fetchRegionPark = (park_id) => {
  return request({
    method: 'get',
    url: '/schedule/region_park/',
    params: {
      park_id
    }
  })
}
export const createPegionPark = (data) => request({
  method: 'post',
  url: '/schedule/region_park/',
  data
})

export const updatePegionPark = (data) => request({
  method: 'put',
  url: `/schedule/region_park/${data.id}/`,
  data
})

export const deletePegionPark = (id) => request({
  method: 'delete',
  url: `/schedule/region_park/${id}`
})
export const getPegionParkByID = (id) => request({
  method: 'get',
  url: `/schedule/region_park/${id}`
})
export const driverUnbind = (data) => request({
  method: 'post',
  url: '/schedule/region_park/drivers_unbind/',
  data
})
export const driverBind = (data) => request({
  method: 'post',
  url: '/schedule/region_park/drivers_bind/',
  data
})
export const createParkSettings = (data) => request({
  method: 'post',
  url: '/settings/park_settings/',
  data
})
export const fetchParkSettings = (params) => request({
  method: 'get',
  url: '/settings/park_settings/',
  params
})
export const updateParkSettings = (id, data) => request({
  method: 'put',
  url: `/settings/park_settings/${id}/`,
  data
})
export const fetchParkSettingsMinMaxSum = (id) => request({
  method: 'get',
  url: `/settings/park_settings/min_max_sum/${id}/`
})
