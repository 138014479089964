import { showDefaultErrorMessage } from '@/utils/createMessage'
import { getReportInRange } from '@/requests/report'

import {
  SET_REPORT,
  CHANGE_LOADING
} from '@/store/mutationsTypes'

const months = {
  1: 'Январь',
  2: 'Февраль',
  3: 'Март',
  4: 'Апрель',
  5: 'Май',
  6: 'Июнь',
  7: 'Июль',
  8: 'Август',
  9: 'Сентябрь',
  10: 'Октябрь',
  11: 'Ноябрь',
  12: 'Декабрь'
}

const state = {
  report: [],
  isLoading: true
}

const mutations = {
  [SET_REPORT]: (state, report) => {
    state.report = report
  },

  [CHANGE_LOADING]: (state, value) => {
    state.isLoading = value
  }
}

const actions = {
  async fetchReportInRange ({ commit }, { start, end }) {
    try {
      commit(CHANGE_LOADING, true)

      const report = await getReportInRange({ start, end })

      commit(SET_REPORT, report)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }
}

const getters = {
  reportHeadTitles (state) {
    if (!state.report.length) return []

    // Return the names of groups and their widths
    // And the names of all columns (of all groups) in the array
    return state.report[0].cols.reduce((acc, currentGroup) => {
      acc.groups.push({
        name: currentGroup.group_name,
        colspan: currentGroup.columns.length
      })

      acc.columns.push(...currentGroup.columns.map(column => column.title))

      return acc
    }, { groups: [], columns: [] })
  },

  reportRows (state) {
    // Expand all week values to the array from deep object
    return state.report.map(week => {
      const groups = week.cols.map(group => group.columns.map(column => column.result || 0)).flat()

      return [
        week.period.year,
        months[week.period.month],
        week.period.weekday,
        ...groups
      ]
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
