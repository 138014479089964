export const CHANGE_DIALOG_VISIBLE = 'CHANGE_DIALOG_VISIBLE'
export const SET_CURRENT_DRIVER = 'SET_CURRENT_DRIVER'
export const SET_CURRENT_DRIVER_INFO = 'SET_CURRENT_DRIVER_INFO'
export const SET_CURRENT_SCHEDULE = 'SET_CURRENT_SCHEDULE'
export const SET_DRIVER_IN_SCHEDULE = 'SET_DRIVER_IN_SCHEDULE'
export const SET_AVALIABLE_DRIVERS = 'SET_AVALIABLE_DRIVERS'
export const SET_CURRENT_SCHEDULE_INFO = 'SET_CURRENT_SCHEDULE_INFO'
export const SET_RATINGS = 'SET_RATINGS'
export const SET_CHANGES = 'SET_CHANGES'
export const SET_VISIBLE_CAR_DIALOG = 'SET_VISIBLE_CAR_DIALOG'
export const SET_DATA_CAR_DIALOG = 'SET_DATA_CAR_DIALOG'
export const SET_CURRENT_CAR = 'SET_CURRENT_CAR'
export const SET_DRIVERS_IN_CHANGE = 'SET_DRIVERS_IN_CHANGE'
export const SET_CURRENT_REGION_PARK_ID = 'SET_CURRENT_REGION_PARK_ID'
export const REMOVE_SCHEDULE_BY_CAR_ID = 'REMOVE_SCHEDULE_BY_CAR_ID'
