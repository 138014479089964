import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { ROOT_CONFIG } from '@/utils/config'

// element-ui
import '@/assets/scss/custom-theme/main.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import locale from 'element-ui/lib/locale'
import lang from 'element-ui/lib/locale/lang/ru-RU'

// custom-element-ui
import DatePicker from '@/components/elementUiCustom/date-picker/index'
import Dialog from '@/components/elementUiCustom/dialog/index'
import Input from '@/components/elementUiCustom/input/index'
import Loading from '@/components/elementUiCustom/loading/index'

// icons
import Icons from '@/icons-injection'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// v-the-mask
import VueTheMask from 'vue-the-mask'

// huita
// import { registerLicense } from '@syncfusion/ej2-base';
// registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RiWn9Yc3VVT2ZZUEc=');
import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons'
import { DocumentEditorContainerPlugin } from '@syncfusion/ej2-vue-documenteditor'
import { ListViewPlugin } from '@syncfusion/ej2-vue-lists'
import { DropDownButtonPlugin } from '@syncfusion/ej2-vue-splitbuttons'
// Vue.use(AccordionPlugin)
// import { AccordionPlugin } from '@syncfusion/ej2-vue-navigations'

Vue.use(DocumentEditorContainerPlugin)
Vue.use(DropDownButtonPlugin)
Vue.use(ButtonPlugin)
Vue.use(ListViewPlugin)
// eslint-disable-next-line import/first
import './authGuard'
// eslint-disable-next-line import/first
import '@/assets/scss/main.scss'

locale.use(lang)
const moment = require('moment')
require('moment/locale/ru')

Vue.use(require('vue-moment'), { moment })
Vue.use(VueTheMask)
Vue.component('fa-icon', FontAwesomeIcon)
library.add(Icons)

Vue.config.productionTip = ROOT_CONFIG.productionTip
Vue.config.devtools = ROOT_CONFIG.devtools

// element-ui and custom components install
Vue.use(ElementUI)
Input.install(Vue)
DatePicker.install(Vue)
Loading.install(Vue)
Dialog.install(Vue)

router.beforeEach((to, from, next) => {
  if (!ROOT_CONFIG?.available) {
    console.log('Иди на ***')
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
