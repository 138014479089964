import * as helpers from './helpers'
import {
  CHANGE_DIALOG_VISIBLE,
  REMOVE_SCHEDULE_BY_CAR_ID,
  SET_AVALIABLE_DRIVERS,
  SET_CHANGES, SET_CURRENT_CAR,
  SET_CURRENT_DRIVER,
  SET_CURRENT_DRIVER_INFO,
  SET_CURRENT_REGION_PARK_ID,
  SET_CURRENT_SCHEDULE,
  SET_CURRENT_SCHEDULE_INFO,
  SET_DATA_CAR_DIALOG,
  SET_DRIVER_IN_SCHEDULE, SET_DRIVERS_IN_CHANGE, SET_RATINGS,
  SET_VISIBLE_CAR_DIALOG
} from './constants'

export const mutations = {
  SET_SHEDULE: (state, schedule) => {
    state.schedule = schedule.map(item => {
      return {
        ...item,
        dates: helpers.groupCarDates(item.dates, state.currentWeek)
      }
    })
  },

  [SET_DRIVER_IN_SCHEDULE]: (state, data) => {
    const {
      timesDay,
      date
    } = data

    state.schedule.some((car, index) => {
      if (car.car_id === data.carId) {
        state.schedule[index].dates[date][timesDay] = { ...data.schedule }
        return true
      }
      return false
    })
  },

  [SET_CURRENT_SCHEDULE_INFO]: (state, info) => {
    state.currentScheduleInfo = info
  },
  [REMOVE_SCHEDULE_BY_CAR_ID]: (state, car_id) => {
    const index = state.schedule.findIndex(item => item.car_id === car_id)
    state.schedule.splice(index, 1)
  },
  [SET_AVALIABLE_DRIVERS]: (state, drivers) => {
    state.availableDrivers = drivers
  },

  SET_FILTER: (state, dto) => {
    state.filter.carCallSign = dto.carCallsign
    state.filter.carNumber = dto.carNumber
    state.filter.carModel = dto.carModel
    state.filter.loadSort = dto.loadSort
    state.filter.modelSort = dto.modelSort
    state.filter.dateTime = dto.dateTime
    state.filter.indicators = dto.indicators
  },
  [SET_CHANGES]: (state, change) => {
    state.changesStatistics = change
  },
  SET_DRIVERS_FILTER (state, secondName) {
    state.filter.last_name = secondName
  },

  TOOGLE_LOADING: (state, value) => {
    state.isLoading = value
  },
  SET_IGNORING_DRIVER_WORKING: (state, value) => {
    console.log('SET_IGNORING_DRIVER_WORKING', value)
    state.ignoringDriverWorking = [...state.ignoringDriverWorking, value]
  },
  [SET_CURRENT_REGION_PARK_ID]: (state, value) => {
    state.currentRegionParkID = value
  },

  SET_CURRENT_WEEK: (state, dates) => {
    state.currentWeek = dates
  },

  [CHANGE_DIALOG_VISIBLE]: (state, {
    dialogName,
    value
  }) => {
    state[dialogName] = value
  },

  [SET_CURRENT_DRIVER]: (state, driver) => {
    state.currentDriver = driver
  },
  [SET_VISIBLE_CAR_DIALOG]: (state, status) => {
    state.chooseCarDialog = status
  },
  [SET_DATA_CAR_DIALOG]: (state, data) => {
    state.carDialogData = data
  },
  [SET_CURRENT_DRIVER_INFO]: (state, info) => {
    state.currentDriverInfo = info
  },

  [SET_CURRENT_SCHEDULE]: (state, schedule) => {
    state.currentSchedule = schedule
  },

  [SET_RATINGS]: (state, ratings) => {
    state.ratings = ratings
  },

  [SET_CURRENT_CAR]: (state, id) => {
    state.currentCar = id
  },

  [SET_DRIVERS_IN_CHANGE]: (state, drivers) => {
    state.driversInChange = drivers
  },

  SET_CURRENT_REGION_PARK_SETTINGS_ID: (state, value) => {
    state.currentRegionParkSettingsId = value
  }
}
