import {
  getTaxiCars,
  getTaxiCarsCommon,
  saveTaxiCarsFromYandex,
  getCarById,
  getTaxiCarsShort,
  putTaxiCar
} from '@/requests/carPark'

const state = {
  carParkData: [],
  isLoading: true,
  filters: {
    brand: '',
    model: '',
    number: '',
    year: '',
    callsign: ''
  },
  currentRegionParkID: null
}

const mutations = {
  SET_CARS: (state, carData) => {
    state.carParkData = carData
  },
  SET_FILTERS: (state, filters) => {
    state.filters = filters
  },
  TOOGLE_LOADING (state, value) {
    state.isLoading = value
  },
  UPDATE_CAR_BY_ID: (state, { data, id }) => {
    const carIndex = state.carParkData.findIndex(d => d.id === id)
    state.carParkData[carIndex] = data
  },
  SET_CURRENT_REGION_PARK_ID: (state, value) => {
    state.currentRegionParkID = value
  }

}

const actions = {
  setFilters ({ commit }, SomeFilters) {
    commit('SET_FILTERS', SomeFilters)
  },
  setCurrentRegionParkID ({ commit }, region_park_id) {
    commit('SET_CURRENT_REGION_PARK_ID', region_park_id)
  },
  fetchCarPark ({ commit, state }) {
    commit('TOOGLE_LOADING', true)
    return getTaxiCars({ region_park: state.currentRegionParkID })
      .then(carParkData => {
        commit('SET_CARS', carParkData)
        commit('TOOGLE_LOADING', false)
        return carParkData
      })
      .catch((error) => {
        commit('TOOGLE_LOADING', false)
        return Promise.reject(error)
      })
  },
  fetchCarParkCommon ({ commit, state }) {
    commit('TOOGLE_LOADING', true)
    return getTaxiCarsCommon({ region_park: state.currentRegionParkID })
      .then(carParkData => {
        commit('SET_CARS', carParkData)
        commit('TOOGLE_LOADING', false)
        return carParkData
      })
      .catch((error) => {
        commit('TOOGLE_LOADING', false)
        return Promise.reject(error)
      })
  },
  fetchCarParkShort ({ commit, state }) {
    commit('TOOGLE_LOADING', true)
    return getTaxiCarsShort({ region_park: state.currentRegionParkID })
      .then(carParkData => {
        commit('SET_CARS', carParkData)
        commit('TOOGLE_LOADING', false)
        return carParkData
      })
      .catch((error) => {
        commit('TOOGLE_LOADING', false)
        return Promise.reject(error)
      })
  },
  async getCarById ({ commit }, id) {
    try {
      return await getCarById(id)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async putTaxiCar ({ commit }, { data, id }) {
    try {
      const responseUpdate = await putTaxiCar(data, id)
      commit('UPDATE_CAR_BY_ID', { data: responseUpdate, id })
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async generateCarParkFromYandexData () {
    try {
      return await saveTaxiCarsFromYandex()
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

const getters = {
  GET_ONLY_BRANDS: (state) => {
    const onlyBrands = state.carParkData.map(a => a.brand)
    return [...new Set(onlyBrands)]
  },
  GET_ONLY_MODELS: (state) => {
    const onlyModels = state.carParkData.map(a => a.model)
    return [...new Set(onlyModels)]
  },
  GET_OBLY_REPAIRING_CARS: (state) => {
    const repairedCars = state.carParkData.filter(xu => xu.status === 'repairing')
    return [...new Set(repairedCars)]
  },
  GET_ONLY_NUMBERS: (state) => {
    const onlyNumbers = state.carParkData.map(a => a.number)
    return [...new Set(onlyNumbers)]
  },
  GET_ONLY_YEARS: (state) => {
    const onlyYears = state.carParkData.map(a => a.year)
    return [...new Set(onlyYears)]
  },
  GET_ONLY_CALLSIGN: (state) => {
    const onlyCallsign = state.carParkData.map(a => a.callsign)
    return [...new Set(onlyCallsign)]
  },
  GET_CAR_BY_ID: (state) => prop_id => {
    return state.carParkData.filter(item => item.id === prop_id)[0]
  },
  GREAT_NAME_BY_ID: (state) => prop_id => {
    if (!prop_id) return 'Выберете автомобиль'
    return state.carParkData.filter(item => item.id === prop_id).map(car => `${car.brand} ${car.model} — ${car.number} — ${car.callsign}`)[0]
  },
  sortedDataByFilter: (state) => {
    const { brand, model, number, status, year, callsign } = state.filters
    if (!brand && !model && !number && !status && !year && !callsign) return []
    return state.carParkData.filter(e =>
      (brand ? e.brand === brand : true) &&
       (model ? e.model === model : true) &&
       (number ? e.number === number : true) &&
       (status ? e.status === status : true) &&
       (year ? e.year === year : true) &&
       (callsign ? e.callsign === callsign : true))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
