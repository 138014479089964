import { getRetentionsMinMaxGlobal, updateDriverWorkRulesById, getDriverWorkRules, putRetentionsMinMaxGlobal, fetchParkSettingsMinMaxSum, updateParkSettingsMinMaxSum, fetchParkSettingsFormulas, updateParkSettings, fetchParkSettings, addData, addParkData, getData, getParksData, removeParkData, selectParkData } from '@/requests/settings'
import { Message } from 'element-ui'
import formatter from '@/mixin/formatter'
import { showDefaultErrorMessage } from '@/utils/createMessage'
const state = {
  data: {},
  retentionsMinMax: {},
  parks: [],
  driver_work_rules: [],
  isBaseLoading: true
}

const getters = {

  getDefaultParkName: state =>
    state.parks.length
      ? state.parks.some(p => p.is_active === true)
        ? state.parks.find(p => p.is_active === true).name
        : 'Парк не выбран'
      : 'Нет парков',

  getDefaultParkId: state =>
    state.parks.length
      ? state.parks.some(p => p.is_active === true)
        ? state.parks.find(p => p.is_active === true).taxi_service_id
        : ''
      : '',

  getDefaultParkPk: state =>
    state.parks.length
      ? state.parks.some(p => p.is_active === true)
        ? state.parks.find(p => p.is_active === true).id
        : ''
      : '',

  getTaxometrLink: (_state, getters) =>
    driverId =>
      `https://fleet.taxi.yandex.ru/drivers/${driverId}/parks/${getters.getDefaultParkId}/card/`,
  getTaxometrLinkOrders: (_state, getters) =>
    driverId =>
      `https://fleet.taxi.yandex.ru/drivers/${driverId}/orders?park_id=${getters.getDefaultParkId}`,

  getRetentionTypes: state => (byName) => state.data?.retentions?.reduce((acc, currentRetention) => {
    // Преобразовать массив типов удержаний в объект, где ключи - это, либо id, либо name, удержания (зависит от byName).

    if (byName) {
      const { name, ...retention } = currentRetention
      acc[name] = retention
    } else {
      const { id, ...retention } = currentRetention
      acc[id] = retention
    }

    return acc
  }, {}),
  getFuelTypes: state => (byName) => state.data?.fuels?.reduce((acc, currentRetention) => {
    // Преобразовать массив типов удержаний в объект, где ключи - это, либо id, либо name, удержания (зависит от byName).

    if (byName) {
      const { name, ...retention } = currentRetention
      acc[name] = retention
    } else {
      const { id, ...retention } = currentRetention
      acc[id] = retention
    }

    return acc
  }, {}),
  getTransactionTypesToDropdown: state => {
    if (!state.data?.retentions?.length) return []
    return state.data.retentions
      .map(e => {
        return {
          description: e.description,
          name: e.name,
          id: e.id
        }
      })
  },
  autoExecRetentionIds: state => state.data.retentions.filter(r => r.auto_exec).map(r => r.id),

  driverSurchargeRetentionIds: state => state.data.retentions.filter(r => r.driver_surcharge).map(r => r.id),

  fines: state => state.data.fines.reduce((acc, fines) => {
    // Преобразовать массив объектов fines, где в объекте только один ключ, в один объект
    const name = Object.keys(fines)
    acc[name] = fines[name]

    return acc
  }, {}),

  surcharges: state => state.data.surcharges.reduce((acc, currentSurcharge) => {
    // Преобразовать массив типов доплат в объект, где ключи - это id доплаты.
    const { id, ...surcharge } = currentSurcharge
    acc[id] = surcharge

    return acc
  }, {}),

  surchargesByName: state => state.data.surcharges.reduce((acc, currentSurcharge) => {
    // Преобразовать массив типов доплат в объект, где ключи - это name доплаты.
    const { name, ...surcharge } = currentSurcharge
    acc[name] = surcharge

    return acc
  }, {}),
  fuelsByName: state => state.data.fuels.reduce((acc, currentSurcharge) => {
    // Преобразовать массив типов доплат в объект, где ключи - это name доплаты.
    const { name, ...surcharge } = currentSurcharge
    acc[name] = surcharge

    return acc
  }, {}),
  mergeMinMaxRetentions: state => {
    if (!Object.keys(state.retentionsMinMax)?.length) return []
    const { min, max } = state.retentionsMinMax
    const getNamesMin = min.map(x => x.name)
    const getNamesMax = max.map(x => x.name)
    const mergeAndUnsetDublicats = [...new Set([...getNamesMin, ...getNamesMax])].filter(x => x !== '')
    if (!mergeAndUnsetDublicats?.length) return []
    return mergeAndUnsetDublicats.reduce((acc, value) => {
      const getMinValueByName = min.filter(x => x.name === value).map(x => x.value)[0] || null
      const getMaxValueByName = max.filter(x => x.name === value).map(x => x.value)[0] || null
      acc = [...acc, { name: value, min: getMinValueByName || 0, max: getMaxValueByName || 0 }]
      return acc
    }, [])
  },
  getRetentionMinMaxById: state => id => {
    if (!id) return false
    const getRetentionNameById = state.data.retentions.filter(x => x.id === id)
    if (!getRetentionNameById.length) return false
    const nameRetentionById = getRetentionNameById[0].name
    const getMaxRetentionByName = state.retentionsMinMax?.max?.filter(y => y.name === nameRetentionById)
    if (!getMaxRetentionByName?.length) return false
    return getMaxRetentionByName[0]?.value
  }
  // getRetentionNameMinMaxById: state => name => {
  //   if (!name) return false
  //   const getMaxRetentionByName = state.retentionsMinMax.max.filter(y => y.name === name)
  //   if (!getMaxRetentionByName.length) return false
  //   return getMaxRetentionByName[0]
  // }
}

const mutations = {
  SET: (state, data) => {
    state.data = data
  },
  SET_DATA_PARK: (state, data) => {
    state.data = Object.assign(state.data, data)
  },
  SET_PARKS: (state, data) => {
    state.parks = data
  },
  SET_DRIVER_WORK_RULES: (state, data) => {
    state.driver_work_rules = data
  },
  UPDATE_DRIVER_WORK_RULES: (state, data) => {
    const { service_id } = data
    const getIdx = state.driver_work_rules.findIndex(x => x.service_id === service_id)
    const elemByIdx = state.driver_work_rules[getIdx]
    state.driver_work_rules = [...state.driver_work_rules.filter(x => x.service_id !== service_id), {
      ...elemByIdx,
      ...data
    }]
  },
  ADD_PARK: (state, data) => {
    state.parks.push(data)
  },
  SET_RETENTIONS_MIN_MAX: (state, data) => {
    state.retentionsMinMax = data
  },
  SET_BASE_LOADING: (state, data) => {
    state.isBaseLoading = data
  }
}

const actions = {

  async getCrmSettings ({ commit }) {
    await getData()
      .then(data => {
        if (typeof data.drivers_percents === 'string') {
          data.drivers_percents = JSON.parse(data.drivers_percents)
        }
        if (typeof data.fines === 'string') {
          data.fines = JSON.parse(data.fines)
        }
        commit('SET', data)
        return data
      })
      .catch((error) => {
        return error
      })

    await getParksData()
      .then(data => {
        commit('SET_PARKS', data)
        return data
      })
      .catch((error) => {
        return error
      })
    commit('SET_BASE_LOADING', false)
  },

  addPark ({ commit }, data) {
    addParkData(data).then(res => {
      commit('ADD_PARK', res)
      Message({
        message: 'Парк был добавлен',
        type: 'success',
        duration: 5 * 1000
      })
      return data
    })
      .catch((error) => {
        return error
      })
  },
  setDataPark ({ commit }, data) {
    console.log('setDataPark')
    commit('SET_DATA_PARK', data)
  },
  async putRetentionsMinMax ({ commit, state }, { settings_id, data, isEdit }) {
    try {
      const addParameterMaxToRetentions = state.retentionsMinMax?.max.map(x => {
        return {
          ...x,
          parameter: 'max'

        }
      }).filter(y => y.name !== data[0].name && isEdit)
      console.log('isEdit', isEdit)
      const res = await updateParkSettingsMinMaxSum(settings_id, [...data, ...addParameterMaxToRetentions])
      commit('SET_RETENTIONS_MIN_MAX', res)
      Message({
        message: 'Успешная операция!',
        type: 'success',
        duration: 5 * 1000
      })
      // commit('SET_CHANGES', StatisticShanges)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async fetchParkSettingsFormulas ({ commit, state }, settings_id) {
    try {
      const res = await fetchParkSettingsFormulas(settings_id)
      return res
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  getDriverWorkRulesById ({ commit, state }, id) {
    try {
      return state.driver_work_rules.find((x) => x.id === id)
    } catch (err) {
      console.log(err)
    }
  },
  async updateDriverWorkRulesById ({ commit, state }, data) {
    try {
      const res = await updateDriverWorkRulesById(data?.id, data)
      commit('UPDATE_DRIVER_WORK_RULES', res)
      Message({
        message: 'Успешно измененно!',
        type: 'success',
        duration: 5 * 1000
      })
      return res
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async getDriverWorkRules ({ commit, state }) {
    try {
      const res = await getDriverWorkRules()
      commit('SET_DRIVER_WORK_RULES', res)
      return res
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async getRetentionsMinMax ({ commit, state }, id) {
    try {
      await fetchParkSettingsMinMaxSum(id)
        .then(data => {
          commit('SET_RETENTIONS_MIN_MAX', data)
          return data
        })
        .catch((error) => {
          showDefaultErrorMessage()
          return error
        })
      // commit('SET_CHANGES', StatisticShanges)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async getRetentionsMinMaxGlobal ({ commit, state }, id) {
    try {
      await getRetentionsMinMaxGlobal(id)
        .then(data => {
          commit('SET_RETENTIONS_MIN_MAX', data)
          return data
        })
        .catch((error) => {
          showDefaultErrorMessage()
          return error
        })
      // commit('SET_CHANGES', StatisticShanges)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async putRetentionsMinMaxGlobal ({ commit, state }, { data }) {
    try {
      const res = await putRetentionsMinMaxGlobal(data)
      commit('SET_RETENTIONS_MIN_MAX', res)
      Message({
        message: 'Успешная операция!',
        type: 'success',
        duration: 5 * 1000
      })
      // commit('SET_CHANGES', StatisticShanges)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async getRegionParkSettings ({ commit, state }, settings_id) {
    try {
      return await fetchParkSettings({ id: settings_id })
        .then(data => {
          return data[0]
        })
        .catch((error) => {
          showDefaultErrorMessage()
          return error
        })
      // commit('SET_CHANGES', StatisticShanges)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async deleteRetentionsMinMax ({ commit, state }, { data, settings_id }) {
    try {
      const addParameterMaxToRetentions = state.retentionsMinMax?.max.map(x => {
        return {
          ...x,
          parameter: 'max'

        }
      }).filter(y => y.name !== data.name)
      console.log(addParameterMaxToRetentions)
      const res = await updateParkSettingsMinMaxSum(settings_id, [...addParameterMaxToRetentions])
      commit('SET_RETENTIONS_MIN_MAX', res)
      // const res = await deleteRetentionsMinMax(data)
      // commit('SET_RETENTIONS_MIN_MAX', res)
      Message({
        message: 'Вы удалили удержание',
        type: 'success',
        duration: 5 * 1000
      })
      // commit('SET_CHANGES', StatisticShanges)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  removePark ({ commit }, id) {
    removeParkData(id).then(() => {
      getParksData()
        .then(data => {
          commit('SET_PARKS', data)
          Message({
            message: 'Вы удалили таксопарк',
            type: 'success',
            duration: 5 * 1000
          })
          return data
        })
        .catch((error) => {
          return error
        })
    })
      .catch((error) => {
        Message({
          message: 'Не удалось удалить таксопарк',
          type: 'success',
          duration: 5 * 1000
        })
        return error
      })
  },

  selectPark ({ commit }, id) {
    selectParkData(id).then(() => {
      getParksData()
        .then(data => {
          commit('SET_PARKS', data)
          Message({
            message: 'Вы изменили действующий таксопарк',
            type: 'success',
            duration: 5 * 1000
          })
          return data
        })
        .catch((error) => {
          return error
        })
    })
      .catch((error) => {
        Message({
          message: 'Не удалось изменить таксопарк',
          type: 'error',
          duration: 5 * 1000
        })
        return error
      })
  },

  createCrmSettings ({ commit }, data) {
    return new Promise((resolve, reject) => {
      addData(data).then(res => {
        Message({
          message: 'Настройки CRM были изменены',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  updateParkSettings ({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      updateParkSettings(id, data).then(res => {
        Message({
          message: 'Настройки были изменены',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(res)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  changePercentCrmSettings ({ commit, state }, data) {
    const dataSettings = { ...state.data }

    delete dataSettings.surcharges
    delete dataSettings.retentions

    dataSettings.drivers_percents.sort((prev, next) => Object.keys(prev)[0] - Object.keys(next)[0])

    const driversPercentsString = formatter.methods.formatObjectToString(data)
    const finesString = formatter.methods.formatObjectToString(dataSettings.fines)

    dataSettings.drivers_percents = driversPercentsString
    dataSettings.fines = finesString
    return new Promise((resolve, reject) => {
      addData(dataSettings).then(res => {
        Message({
          message: 'Настройки CRM были изменены',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(dataSettings)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
