import request from '../utils/request'

export const callUpdateScheduleFiveMinutely = (params) => request({
  method: 'post',
  url: '/tasker/call_update_schedule_five_minutely/',
  params
})
export const callUpdateScheduleDriver = (params) => request({
  method: 'post',
  url: '/tasker/call_update_schedule_driver/',
  params
})
