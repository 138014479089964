<template>
  <component :is="layout">
    <router-view/>
  </component>

</template>

<script>
import emptyLayout from '@/layouts/emptyLayout'
import mainLayout from '@/layouts/mainLayout'

export default {
  components: {
    emptyLayout,
    mainLayout
  },
  computed: {
    layout () {
      return this.$route.meta.layout
    }
  }
}
</script>

<style lang="scss">

body {
  margin: 0;
  padding: 0;
}

.app {
  height: max-content;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
}

</style>
