import request from '../utils/request'

export const getMaintenancesData = () => request({
  method: 'get',
  url: '/taxi/maintenances'
})

export const addMaintenanceData = (data) => request({
  method: 'post',
  url: '/taxi/maintenances',
  data: data
})

export const acceptMaintenanceData = (id) => request({
  method: 'post',
  url: `/taxi/maintenances/${id}/done`
})

export const changeMaintenanceData = (id, data) => request({
  method: 'put',
  url: `/taxi/maintenances/${id}`,
  data: data
})
