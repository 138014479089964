import { errorResponseMessage, showDefaultErrorMessage } from '@/utils/createMessage'

import {
  getContracts,
  getContractsPart,
  createContracts
} from '@/requests/contracts'

import {
  SET_CONTRACTS_LIST,
  ADD_CONTRACTS,
  INCREASE_PAGINATION,
  SET_PAGINATION,
  SET_SEARCH_VALUE,
  CHANGE_LOADING
} from '@/store/mutationsTypes'

const state = {
  isLoading: true,
  contracts: [],
  searchValue: '',
  pagination: [0, 9]
}

const mutations = {
  [SET_CONTRACTS_LIST]: (state, contracts) => {
    state.contracts = contracts
  },

  [ADD_CONTRACTS]: (state, contracts) => {
    state.contracts.push(...contracts)
  },

  [INCREASE_PAGINATION]: (state, num) => {
    state.pagination = state.pagination.map(item => item + num)
  },

  [SET_PAGINATION]: (state, pagination) => {
    state.pagination = pagination
  },

  [SET_SEARCH_VALUE]: (state, driver) => {
    state.searchValue = driver
  },

  [CHANGE_LOADING]: (state, value) => {
    state.isLoading = value
  }
}

const actions = {
  async fetchContracts ({ commit }) {
    try {
      commit(CHANGE_LOADING, true)

      const contractsList = await getContracts()

      commit(SET_CONTRACTS_LIST, contractsList)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  async fetchContractsPart ({ state, commit }) {
    try {
      commit(CHANGE_LOADING, true)

      const contracts = await getContractsPart({
        search: state.searchValue || null,
        start: state.pagination[0],
        end: state.pagination[1]
      })

      commit(ADD_CONTRACTS, contracts)
      commit(INCREASE_PAGINATION, 10)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  async searchContractsByDriver ({ commit, dispatch }, driverName) {
    try {
      // Reset data
      commit(SET_CONTRACTS_LIST, [])
      commit(SET_PAGINATION, [0, 9])

      commit(SET_SEARCH_VALUE, driverName)

      dispatch('fetchContractsPart')
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async createContracts ({ commit }, data) {
    try {
      return await createContracts(data)
    } catch (err) {
      await errorResponseMessage(err.response.data)
      return Promise.reject(err)
    }
  }
}

const getters = {
  groupedContracts: (state) => {
    return state.contracts.reduce((accumulator, currentContract) => {
      const { driver, car, ...contract } = currentContract
      // Create driver if there is no one
      if (!accumulator[driver]) {
        accumulator[driver] = {
          contracts: [],
          cars: {}
        }
      }

      if (car) {
        if (accumulator[driver].cars[car]) {
          accumulator[driver].cars[car].push(contract) // Add contract to driver car
        } else {
          accumulator[driver].cars[car] = [contract] // Create driver car with contract
        }
      } else {
        accumulator[driver].contracts.push(contract) // Add contract to driver
      }

      return accumulator
    }, {})
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
