import request from '../utils/request'

export const fetchSuppliers = () => request({
  method: 'get',
  url: '/maintenance/suppliers/'
})

export const fetchSupplierById = (id) => request({
  method: 'get',
  url: '/maintenance/suppliers/' + id
})

export const createSupplier = (data) => request({
  method: 'post',
  url: '/maintenance/suppliers/',
  data
})

export const fetchScrappedHistory = (params) => request({
  method: 'get',
  url: '/maintenance/warehouse/scrapped_history',
  params // start_date end_date
})

export const fetchMovementHistory = (params) => request({
  method: 'get',
  url: '/maintenance/ware_house/movement_history',
  params // start_date end_date
})
export const fetchMovementHistorySparePartById = (params, id) => request({
  method: 'get',
  url: `/maintenance/warehouse/movement_history/spare_part/${id}`,
  params // start_date end_date
})
export const fetchScrappedHistoryPartById = (params, id) => request({
  method: 'get',
  url: `/maintenance/warehouse/scrapped_history/spare_part/${id}`,
  params // start_date end_date
})
export const updateSupplier = (supplierDto) => {
  const { id, ...data } = supplierDto

  return request({
    method: 'put',
    url: '/maintenance/suppliers/' + id,
    data
  })
}

export const deleteSupplier = (supplierId) => request({
  method: 'delete',
  url: '/maintenance/suppliers/' + supplierId
})

export const fetchPartsOrders = () => request({
  method: 'get',
  url: '/maintenance/orders/'
})

export const createPartsOrder = (data) => request({
  method: 'post',
  url: '/maintenance/orders/',
  data
})

export const fetchPartsOrderById = (partsOrderId) => request({
  method: 'get',
  url: `/maintenance/orders/${partsOrderId}/`
})

export const updatePartsOrder = (data) => request({
  method: 'put',
  url: `/maintenance/orders/${data.id}/`,
  data
})

export const deletePartsOrder = (orderId) => request({
  method: 'delete',
  url: `/maintenance/orders/${orderId}/`
})

export const acceptPartsOrder = (partsOrderId) => request({
  method: 'post',
  url: `/maintenance/orders/${partsOrderId}/accept_order`
})

export const fetchWarehouse = () => request({
  method: 'get',
  url: '/maintenance/ware_house/'
})

export const addToWarehouse = (data) => request({
  method: 'post',
  url: '/maintenance/ware_house/',
  data
})

export const updateWarehouse = (data) => request({
  method: 'put',
  url: `/maintenance/ware_house/${data.id}/`,
  data
})

export const deleteWarehouse = (id) => request({
  method: 'delete',
  url: `/maintenance/ware_house/${id}/`
})

export const fetchDecommissionParts = () => request({
  method: 'get',
  url: 'maintenance/decommission_parts'
})

export const decommissionPart = (data) => request({
  method: 'post',
  url: 'maintenance/decommission_parts/',
  data
})

export const returnDecommissionPart = (uuid) => request({
  method: 'delete',
  url: `maintenance/decommission_parts/${uuid}`
})

export const fetchPartCategories = () => request({
  method: 'get',
  url: '/maintenance/spare_part_categories'
})

export const createPartCategory = (data) => request({
  method: 'post',
  url: '/maintenance/spare_part_categories/',
  data
})

export const deletePartCategory = (partCategoryId) => request({
  method: 'delete',
  url: '/maintenance/spare_part_categories/' + partCategoryId
})
export const updatePartCategory = (partCategoryId, data) => request({
  method: 'put',
  url: '/maintenance/spare_part_categories/' + partCategoryId,
  data
})

export const fetchUnitMeasure = () => request({
  method: 'get',
  url: '/maintenance/unit_measure/'
})

export const fetchParts = () => request({
  method: 'get',
  url: '/maintenance/spare_parts/'
})

export const createPart = (data) => request({
  method: 'post',
  url: '/maintenance/spare_parts/',
  data
})

export const updatePart = (data) => request({
  method: 'put',
  url: '/maintenance/spare_parts/' + data.spare_part_id,
  data
})

export const deletePart = (partId) => request({
  method: 'delete',
  url: '/maintenance/spare_parts/' + partId
})

// export const createPart = () => request({
//   method: 'post',
//   url: '/maintenance/spare_parts/',
//   data: {
//     supplier_id: 'fc698138-177a-47d3-a12c-f0ea61ba9a23',
//     spare_part_category_id: 'c8625bae-0ac7-4ce0-809a-fd216860d6f9',
//     name: 'Запчасть 1',
//     vendor_code: '',
//     price: 123
//   }
// })
