import { getFinesByType, getFineById, deleteFine, changeDriverBinding, changeStatusFine, changeStatusFineCompany, acceptFind } from '@/requests/fines'
import moment from 'moment'

import { showDefaultErrorMessage, showErrorMessage } from '@/utils/createMessage'
import { Message } from 'element-ui'

export const SET_FINE_TYPE_NOT_FIND = 'SET_FINE_TYPE_NOT_FIND'
export const SET_FINE_TYPE_FIND = 'SET_FINE_TYPE_FIND'
export const SET_FINE_TYPE_NOT_HOLD = 'SET_FINE_TYPE_NOT_HOLD'
export const SET_FINE_TYPE_COMPLETE = 'SET_FINE_TYPE_COMPLETE'
export const NOT_FIND_MOVE_TO_FIND_BY_ID = 'NOT_FIND_MOVE_TO_FIND_BY_ID'

export const TOOGLE_DESCRIPTION_DIALOG = 'TOOGLE_DESCRIPTION_DIALOG'
export const SET_DESCRIPTION_DIALOG = 'SET_DESCRIPTION_DIALOG'

export const ADD_INFORMATION_DRIVER_TO_FINE = 'ADD_INFORMATION_DRIVER_TO_FINE'
export const ADD_INFORMATION_DRIVER_TO_FINE_FIND = 'ADD_INFORMATION_DRIVER_TO_FINE_FIND'

export const FIND_MOVE_TO_NOT_HOLD = 'FIND_MOVE_TO_NOT_HOLD'
export const NOT_HOLD_MOVE_TO_COMPLETE = 'NOT_HOLD_MOVE_TO_COMPLETE'

export const TOOGLE_LOADING_NOT_FIND = 'TOOGLE_LOADING_NOT_FIND'
export const TOOGLE_LOADING_FIND = 'TOOGLE_LOADING_FIND'
export const TOOGLE_LOADING_NOT_HOLD = 'TOOGLE_LOADING_NOT_HOLD'
export const TOOGLE_LOADING_COMPLETE = 'TOOGLE_LOADING_COMPLETE'

export const TOOGLE_LOADING = 'TOOGLE_LOADING'

export const SET_FILTERS = 'SET_FILTERS'

export const TOOGLE_SELECT_DRIVER_DIALOG = 'TOOGLE_SELECT_DRIVER_DIALOG'
export const SET_SELECT_DRIVER_ID_DIALOG_TEMP = 'SET_SELECT_DRIVER_ID_DIALOG_TEMP'
export const TOOGLE_IS_COMPANY_PROFIT_NOT_HOLD = 'TOOGLE_IS_COMPANY_PROFIT_NOT_HOLD'

function downloadURI (href) {
  const name = href.substring(href.lastIndexOf('/') + 1)
  fetch(href)
    .then(resp => resp.status === 200 ? resp.blob() : false)
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      // the filename you want
      a.download = name
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
}
const state = {
  fineTypeNotFind: [],
  fineTypeFind: [],
  fineTypeNotHold: [],
  fineTypeComplete: [],
  filters: {
    car_number: null,
    driverID: null,
    dateTime: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  },
  isLoading: true,

  isLoadingNotFind: true,
  isLoadingFind: true,
  isLoadingNotHold: true,
  isLoadingComplete: true,

  descriptionDialogData: [],
  descriptionDialogVisible: false,

  changeDriverDialog: false,
  changeDriverDialogTempId: {
    id: null,
    type: 'NotFind'
  }
}
const utils = {
  getValidRequestDate (date) {
    // const normalizedFormattedDate = [
    //   moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
    //   moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
    // ]
    return {
      start_date: date[0],
      end_date: date[1]
    }
  },
  filtredFines (filter, fine) {
    const { driverID, car_number } = filter
    // if (!driverID && !car_number) return false
    let returnFilters = fine
    if (driverID) {
      returnFilters = returnFilters.filter(elem => elem.driver_id === driverID)
    }
    if (car_number) {
      returnFilters = returnFilters.filter(elem => elem.car_number === car_number)
    }
    return returnFilters
  }
}
const mutations = {
  [SET_FINE_TYPE_NOT_FIND]: (state, data) => {
    state.fineTypeNotFind = data
  },
  [SET_FINE_TYPE_FIND]: (state, data) => {
    state.fineTypeFind = data
  },
  [SET_FINE_TYPE_NOT_HOLD]: (state, data) => {
    state.fineTypeNotHold = data
  },
  [SET_FINE_TYPE_COMPLETE]: (state, data) => {
    state.fineTypeComplete = data
  },

  [TOOGLE_LOADING] (state, value) {
    state.isLoading = value
  },

  [TOOGLE_LOADING_NOT_FIND] (state, value) {
    state.isLoadingNotFind = value
  },
  [TOOGLE_LOADING_FIND] (state, value) {
    state.isLoadingFind = value
  },
  [TOOGLE_LOADING_NOT_HOLD] (state, value) {
    state.isLoadingNotHold = value
  },
  [TOOGLE_LOADING_COMPLETE] (state, value) {
    state.isLoadingComplete = value
  },

  [SET_FILTERS] (state, value) {
    state.filters = value
  },

  [SET_DESCRIPTION_DIALOG] (state, value) {
    state.descriptionDialogData = value
  },
  [TOOGLE_DESCRIPTION_DIALOG] (state, value) {
    state.descriptionDialogVisible = value
  },
  [TOOGLE_SELECT_DRIVER_DIALOG] (state, value) {
    state.changeDriverDialog = value
  },
  [SET_SELECT_DRIVER_ID_DIALOG_TEMP] (state, { id, type }) {
    state.changeDriverDialogTempId = {
      id, type
    }
  },

  [NOT_FIND_MOVE_TO_FIND_BY_ID] (state, id) {
    const getIndexNotFindById = state.fineTypeNotFind.findIndex(item => item.id === id)
    state.fineTypeFind.unshift(state.fineTypeNotFind[getIndexNotFindById])
    state.fineTypeNotFind.splice(getIndexNotFindById, 1)
  },
  [FIND_MOVE_TO_NOT_HOLD] (state, { id, file }) {
    const getIndexNotFindById = state.fineTypeFind.findIndex(item => item.id === id)
    state.fineTypeNotHold.unshift({ ...state.fineTypeFind[getIndexNotFindById], file })
    state.fineTypeFind.splice(getIndexNotFindById, 1)
  },
  [TOOGLE_IS_COMPANY_PROFIT_NOT_HOLD] (state, id) {
    const getIndexById = state.fineTypeNotHold.findIndex(item => item.id === id)
    state.fineTypeNotHold[getIndexById].is_company_payment = !state.fineTypeNotHold[getIndexById].is_company_payment
    if (state.fineTypeNotHold[getIndexById].is_company_payment) {
      state.fineTypeNotHold = [...state.fineTypeNotHold.filter((x, inx) => inx !== getIndexById)]
    }
  },
  [NOT_HOLD_MOVE_TO_COMPLETE] (state, id) {
    const getIndexNotFindById = state.fineTypeNotHold.findIndex(item => item.id === id)
    state.fineTypeComplete.unshift(state.fineTypeNotHold[getIndexNotFindById])
    state.fineTypeNotHold.splice(getIndexNotFindById, 1)
  },
  [ADD_INFORMATION_DRIVER_TO_FINE] (state, { id, driver_id }) {
    const getIndexNotFindById = state.fineTypeNotFind.findIndex(item => item.id === id)
    state.fineTypeNotFind[getIndexNotFindById].driver_id = driver_id
    state.fineTypeNotFind[getIndexNotFindById].custom_id = true
  },
  [ADD_INFORMATION_DRIVER_TO_FINE_FIND] (state, { id, driver_id }) {
    const getIndexFindById = state.fineTypeFind.findIndex(item => item.id === id)
    state.fineTypeFind[getIndexFindById].driver_id = driver_id
    state.fineTypeFind[getIndexFindById].custom_id = true
  }
}

const getters = {
  getAllDrivers: (state) => {
    const mergeAllTypesFines = [...state.fineTypeNotFind, ...state.fineTypeFind, ...state.fineTypeNotHold, ...state.fineTypeComplete]
    const mergedTypesFiltred = mergeAllTypesFines.filter((x) => !!x.driver_id)
    const mergedTypesMaped = mergedTypesFiltred.map(val => { return { driver_id: val.driver_id, driver_name: val.driver_name } })
    return [...new Set(mergedTypesMaped)]
  },
  getAllCars: (state) => {
    const mergeAllTypesFines = [...state.fineTypeNotFind, ...state.fineTypeFind, ...state.fineTypeNotHold, ...state.fineTypeComplete]
    const mergedTypesFiltred = mergeAllTypesFines.filter((x) => !!x.car_number)
    const mergedTypesMaped = mergedTypesFiltred.map(val => val.car_number)
    return [...new Set(mergedTypesMaped)]
  },

  sortedDataByFilterNotFind (state) {
    return utils.filtredFines(state.filters, state.fineTypeNotFind)
  },
  sortedDataByFilterFind (state) {
    return utils.filtredFines(state.filters, state.fineTypeFind)
  },
  sortedDataByFilterNotHold (state) {
    return utils.filtredFines(state.filters, state.fineTypeNotHold)
  },
  sortedDataByFilterComplete (state) {
    return utils.filtredFines(state.filters, state.fineTypeComplete)
  },

  getCurrentDriversOnThePark: (state, getters, rootState) => {
    if (!rootState?.schedule?.currentRegionParkID) return getters.sortedDataByFilter
    return getters.sortedDataByFilter.filter(driver => driver.region_park_id === rootState?.schedule?.currentRegionParkID)
  }
}
const actions = {
  async getFinesByTypeNotFind ({ commit, state }) {
    try {
      commit('TOOGLE_LOADING_NOT_FIND', true)
      const data = await getFinesByType({
        type: 'not_find',
        ...utils.getValidRequestDate(state.filters.dateTime)
      })
      commit('TOOGLE_LOADING_NOT_FIND', false)

      commit('SET_FINE_TYPE_NOT_FIND', data)
    } catch (e) {
      showDefaultErrorMessage()
      return Promise.reject(e)
    }
  },
  async deleteFine ({ commit, state, dispatch }, { id, dta }) {
    try {
      await deleteFine(id)
      const { newStatus, unpaidStatus, unrecognizedStatus } = dta
      if (newStatus) {
        await dispatch('getFinesByTypeFind')
      }
      if (unpaidStatus) {
        await dispatch('getFinesByTypeNotHold')
      }
      if (unrecognizedStatus) {
        await dispatch('getFinesByTypeNotFind')
      }
      Message({
        type: 'success',
        message: 'Успешно!'
      })
    } catch (e) {
      showDefaultErrorMessage()
      return Promise.reject(e)
    }
  },
  async getFinesByTypeFind ({ commit, state }) {
    try {
      commit('TOOGLE_LOADING_FIND', true)
      const data = await getFinesByType({
        type: 'find',
        ...utils.getValidRequestDate(state.filters.dateTime)
      })
      commit('TOOGLE_LOADING_FIND', false)

      commit('SET_FINE_TYPE_FIND', data)
    } catch (e) {
      showDefaultErrorMessage()
      return Promise.reject(e)
    }
  },
  async getFinesByTypeNotHold ({ commit, state }) {
    try {
      commit('TOOGLE_LOADING_NOT_HOLD', true)
      const data = await getFinesByType({
        type: 'not_hold',
        ...utils.getValidRequestDate(state.filters.dateTime)
      })
      commit('TOOGLE_LOADING_NOT_HOLD', false)

      commit('SET_FINE_TYPE_NOT_HOLD', data)
    } catch (e) {
      showDefaultErrorMessage()
      return Promise.reject(e)
    }
  },
  async getFinesByTypeComplete ({ commit, state }) {
    try {
      commit('TOOGLE_LOADING_COMPLETE', true)
      const data = await getFinesByType({
        type: 'complete',
        ...utils.getValidRequestDate(state.filters.dateTime)
      })
      commit('TOOGLE_LOADING_COMPLETE', false)

      commit('SET_FINE_TYPE_COMPLETE', data)
    } catch (e) {
      showDefaultErrorMessage()
      return Promise.reject(e)
    }
  },

  async acceptFineById ({ commit, state }, { id, driver_id }) {
    try {
      console.log(id, driver_id)
      commit('NOT_FIND_MOVE_TO_FIND_BY_ID', id)
      await acceptFind({
        fine_id: id
      })
      Message({
        type: 'success',
        message: 'Штраф успешно принят'
      })
    } catch (e) {
      showErrorMessage('Не удалось принять водителя')
      return Promise.reject(e)
    }
  },
  async driverBinding ({ commit, state }, { id, driver_id }) {
    try {
      console.log(id, driver_id)
      // commit('NOT_FIND_MOVE_TO_FIND_BY_ID', id)
      await changeDriverBinding({ fine_id: id, driver_id })
      commit('ADD_INFORMATION_DRIVER_TO_FINE', { id, driver_id })
      Message({
        type: 'success',
        message: 'Водитель успешно изменен'
      })
    } catch (e) {
      showErrorMessage('Не удалось поставить водителя')
      return Promise.reject(e)
    }
  },
  async driverBindingFind ({ commit, state }, { id, driver_id }) {
    try {
      console.log(id, driver_id)
      // commit('NOT_FIND_MOVE_TO_FIND_BY_ID', id)
      await changeDriverBinding({ fine_id: id, driver_id })
      commit('ADD_INFORMATION_DRIVER_TO_FINE_FIND', { id, driver_id })
      Message({
        type: 'success',
        message: 'Водитель успешно изменен'
      })
    } catch (e) {
      showErrorMessage('Не удалось поставить водителя')
      return Promise.reject(e)
    }
  },
  async changeStatusFineFind ({ commit, state }, id) {
    try {
      const { file } = await changeStatusFine({ fine_id: id })
      commit('FIND_MOVE_TO_NOT_HOLD', { id, file })
      if (!file) return
      // window.open(file)
      // window.open(file, '_blank')?.focus()
      downloadURI(file)
    } catch (e) {
      const msg = e?.response?.data?.message
      Message({
        type: 'error',
        message: msg
      })
      return Promise.reject(e)
    }
  },
  async changeStatusFineNotHold ({ commit, state }, data) {
    try {
      if (!data?.file) {
        return Message({
          type: 'error',
          message: 'Файл не найден!'
        })
      }
      downloadURI(data?.file)
      // window.open(data?.file)
      // await changeStatusFine({ fine_id: id })
      // commit('NOT_HOLD_MOVE_TO_COMPLETE', id)
      // Message({
      //   type: 'success',
      //   message: 'В разработке'
      // })
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async setFilters ({ commit }, val) {
    try {
      commit('SET_FILTERS', val)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async toogleDescriptionDialog ({ commit }, val) {
    try {
      commit('TOOGLE_DESCRIPTION_DIALOG', val)
      if (!val) commit('SET_DESCRIPTION_DIALOG', [])
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async toogleCheckboxCompanyPayed ({ commit }, { fine_id, is_payed }) {
    try {
      await changeStatusFineCompany({ fine_id, is_payed })
      commit('TOOGLE_IS_COMPANY_PROFIT_NOT_HOLD', fine_id)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async toogleSelectDriverDialog ({ commit }, val) {
    try {
      commit('TOOGLE_SELECT_DRIVER_DIALOG', val)
      if (!val) return commit('SET_SELECT_DRIVER_ID_DIALOG_TEMP', { id: null, type: null })
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async setTempIdDialogSelectDriver ({ commit }, { id, type }) {
    commit('SET_SELECT_DRIVER_ID_DIALOG_TEMP', { id, type })
  },
  async getFineByIdAndToogleDialog ({ commit, state }, id) {
    try {
      const data = await getFineById(id)
      commit('SET_DESCRIPTION_DIALOG', data)
      commit('TOOGLE_DESCRIPTION_DIALOG', true)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
