import { tenantsIncomeData } from '@/mocks/tenants-income.json'

const state = {
  tenantsIncomeData
}

export default {
  namespaced: true,
  state
}
