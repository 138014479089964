import { showDefaultErrorMessage } from '@/utils/createMessage'

import {
  SET_INVESTORS,
  ADD_INVESTOR,
  REMOVE_INVESTOR,
  SET_CURRENT_INVESTOR_ID,
  SET_CURRENT_INVESTOR,
  UPDATE_INVESTOR
} from '@/store/mutationsTypes'

import {
  getInvestors,
  getInvestorById,
  addInvestor,
  removeInvestor,
  updateInvestor
} from '@/requests/investors'

const state = {
  investors: [],
  currentInvestor: {},
  currentInvestorId: null,

  investorTypes: [
    {
      label: 'Физ. лицо',
      value: 'fiz'
    },
    {
      label: 'ИП',
      value: 'ip'
    },
    {
      label: 'ООО',
      value: 'ooo'
    },
    {
      label: 'Самозанятость',
      value: 'slf'
    }
  ],

  dialogsVisible: {
    add: false,
    change: false,
    more: false
  }
}

const mutations = {
  SET_INVESTORS: (state, investors) => {
    state.investors = investors
  },

  ADD_INVESTOR: (state, investor) => {
    state.investors.push(investor)
  },

  REMOVE_INVESTOR: (state, id) => {
    const index = state.investors.findIndex(item => item.id === id)

    state.investors.splice(index, 1)
  },

  SET_CURRENT_INVESTOR_ID: (state, id) => {
    state.currentInvestorId = id
  },

  SET_CURRENT_INVESTOR: (state, investor) => {
    state.currentInvestor = investor
  },

  UPDATE_INVESTOR: (state, data) => {
    const index = state.investors.findIndex(item => item.id === data.id)

    state.investors.splice(index, 1, data)
  }

}

const actions = {
  async fetchInvestors ({ commit }) {
    try {
      const investors = await getInvestors()
      commit(SET_INVESTORS, investors)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async fetchInvestorById ({ commit }, id) {
    try {
      const investor = await getInvestorById(id)
      commit(SET_CURRENT_INVESTOR, investor)
      return investor
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async addInvestor ({ commit }, data) {
    try {
      await addInvestor(data)
      commit(ADD_INVESTOR, data)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async removeInvestor ({ commit }, id) {
    try {
      await removeInvestor(id)
      commit(REMOVE_INVESTOR, id)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async updateInvestor ({ commit }, data) {
    try {
      await updateInvestor(data)
      commit(UPDATE_INVESTOR, data)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  setCurrentInvestorId ({ commit }, id) {
    commit(SET_CURRENT_INVESTOR_ID, id)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
