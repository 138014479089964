import request from '../utils/request'

const URL = '/taxi/cars'

export const getTaxiCars = (params) => request({
  method: 'get',
  url: URL,
  params
})
export const getTaxiCarsCommon = (params) => request({
  method: 'get',
  url: '/taxi/cars_common',
  params
})
export const putTaxiCar = (data, id) => request({
  method: 'put',
  url: `${URL}/${id}`,
  data
})
export const saveTaxiCarsFromYandex = () => request({
  method: 'post',
  url: `${URL}/save`
})

export const getCarById = (id) => request({
  method: 'get',
  url: `${URL}/${id}`
})
export const getTaxiCarsShort = (params) => request({
  method: 'get',
  url: '/taxi/car_short_info/',
  params
})
