import request from '../utils/request'
const url = '/taxi/statistics'

export const getActualDriversIncomeData = () => request({
  method: 'get',
  url: `${url}/personal_drivers_income`
})

export const getDriversIncomeHistoryData = (data) => request({
  method: 'get',
  url: 'v1/tenants_income/tenants_income_history',
  params: data
})

export const getActualCurrentDriverIncomeData = (id) => request({
  method: 'get',
  url: `v1/tenants_income/personal_driver_income/${id}`
})

export const deleteDriverIncomeData = (id) => request({
  method: 'delete',
  url: `${url}/personal_drivers_income/${id}`
})

export const createTransactionData = (data) => {
  return request({
    method: 'post',
    url: 'v1/tenants_income/transactions/',
    data: data
  })
}
export const createTransactionDriver = (data) => {
  return request({
    method: 'post',
    url: 'v1/tenants_income/transactions_from_driver/',
    data: data
  })
}
export const calculateDriverData = (id, data, params) => {
  return request({
    method: 'post',
    url: `v1/tenants_income/personal_drivers_income/${id}/approve_calculation`,
    data: data,
    params
  })
}

export const changeTransactionData = (id, data) => {
  return request({
    method: 'patch',
    url: `${url}/defray_transaction/${id}`,
    data: data
  })
}

export const getNotCalckedDays = (startDate, endDate) => {
  return request({
    method: 'get',
    url: `${url}/get_schedules_not_calculated`,
    params: {
      start_date: startDate,
      end_date: endDate
    }
  })
}

export const getExcelContent = (dto) => {
  return request({
    method: 'post',
    url: `${url}/drivers_income_excel`,
    timeout: 300000,
    data: {
      start_date: dto.fromDate,
      end_date: dto.toDate,
      start_change: dto.fromChange || 'morning',
      end_change: dto.toChange || 'night',
      separator: dto.separator,
      calculation_type: dto.calculation_type
    }
  })
}

export const sendSurcharge = (data) => request({
  method: 'post',
  url: `${url}/set_transaction_surcharge`,
  data
})

export const deleteTransaction = (transactionId) => request({
  method: 'delete',
  url: `${url}/transaction/${transactionId}`
})

export const getDriverOpenTransactions = (driverId) => request({
  method: 'get',
  url: `v1/tenants_income/driver_open_transactions/${driverId}`
})
export const getDriverOpenTransactionsInfo = (transactionId) => request({
  method: 'get',
  url: `${url}/driver_open_transaction_info/${transactionId || null}`
})
export const changeDriversPercent = (data) => request({
  method: 'post',
  url: '/taxi/statistics/change_driver_percent/',
  data
})
export const getHistorySettingsSchanges = () => request({
  method: 'get',
  url: '/taxi/statistics/history_settings_changes'
})

export const changeTransactionsChange = (schedule_id) => request({
  method: 'post',
  url: `v1/tenants_income/personal_drivers_income/${schedule_id}/transfer_transactions`
})

export const updateCashSchedules = (schedule_id, data) => request({
  method: 'post',
  url: `/taxi/schedules/change_cash/${schedule_id}`,
  data
})
export const getDriverDepositDebts = (params) => request({
  method: 'get',
  url: 'v1/tenants_income/driver_deposit_debts/',
  params
})
