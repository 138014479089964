let config = {}
const hostnameSections = ['crm', 'ukrik', 'com']
const hostname = window && window.location && window.location.hostname
const hostnameIsValid = (hostname) => {
  return hostname.startsWith('crm') && hostname.endsWith('ukrik.com')
}
const getHostnameName = (hostname) => {
  if (hostname === 'localhost') return 'localhost'
  if (hostnameIsValid(hostname)) {
    return hostname.split('.').filter(x => !hostnameSections.some(y => y === x))[0] || null
  }
}
switch (getHostnameName(hostname)) {
  case null:
    config = {
      available: true,
      api_url: 'https://api.ukrik.com',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: false,
      devtools: true
    }
    break
  case 'test':
    config = {
      available: true,
      api_url: 'https://api.test.ukrik.com',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: false,
      devtools: true
    }
    break
  case 'dev':
    config = {
      available: true,
      api_url: 'https://api.dev.ukrik.com',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: false,
      devtools: true
    }
    break
  case 'vrz':
    config = {
      available: true,
      api_url: 'https://api.vrz.ukrik.com',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'vestapark':
    config = {
      available: true,
      api_url: 'https://api.vestapark.ukrik.com',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'betmobil':
    config = {
      available: true,
      api_url: 'https://api.betmobil.ukrik.com',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'legionavto':
    config = {
      available: true,
      api_url: 'https://api.legionavto.ukrik.com',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'statuspark':
    config = {
      available: true,
      api_url: 'https://api.statuspark.ukrik.com',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'premium35':
    config = {
      available: true,
      api_url: 'https://api.premium35.ukrik.com/',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'ttnovosibirsk':
    config = {
      available: true,
      api_url: 'https://api.ttnovosibirsk.ukrik.com/',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'rusinvestdalshebolshe':
    config = {
      available: true,
      api_url: 'https://api.rusinvestdalshebolshe.ukrik.com/',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'volga76':
    config = {
      available: true,
      api_url: 'https://api.volga76.ukrik.com/',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'tovarishchi':
    config = {
      available: true,
      api_url: 'https://api.tovarishchi.ukrik.com/',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'avtogrupp':
    config = {
      available: true,
      api_url: 'https://api.avtogrupp.ukrik.com/',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'sibirkrasnoyarsk':
    config = {
      available: true,
      api_url: 'https://api.sibirkrasnoyarsk.ukrik.com/',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
  case 'localhost':
    config = {
      available: true,
      api_url: 'https://api.dev.ukrik.com',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: false,
      devtools: true
    }
    break
  default:
    config = {
      available: false,
      api_url: '',
      document_url: 'https://document-editor.ukrik.com/',
      productionTip: true,
      devtools: false
    }
    break
}
export const ROOT_CONFIG = config
