// IncomeHistory
export const INIT_HIST_TABLE = 'INIT_HIST_TABLE'
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const INIT_NOT_CALKED_DAYS = 'INIT_NOT_CALKED_DAYS'
export const COMMIT_SURCHARGE = 'COMMIT_SURCHARGE'
export const CANCEL_SURCHARGE = 'CANCEL_SURCHARGE'
export const ADD_RETENTION = 'ADD_RETENTION'
export const REMOVE_TABLE_ITEM = 'REMOVE_TABLE_ITEM'
export const REMOVE_RETENTION = 'REMOVE_RETENTION'
export const CANCEL_RETENTION = 'CANCEL_RETENTION'
export const UPDATE_RETENTION = 'UPDATE_RETENTION'
export const COMMIT_RETENTION = 'COMMIT_RETENTION'
export const SET_COMPENSATION = 'SET_COMPENSATION'
export const ADD_SURCHARGE = 'ADD_SURCHARGE'
export const SET_HISTORY_SETTINGS_CHANGES = 'SET_HISTORY_SETTINGS_CHANGES'
export const CHANGE_PERSCENT_BY_ID = 'CHANGE_PERSCENT_BY_ID'
// parkStatistic
export const SET_CONSOLIDATE_CARS = 'SET_CONSOLIDATE_CARS'
export const TOOGLE_LOADING = 'TOOGLE_LOADING'

// report
export const SET_REPORT = 'SET_REPORT'

// contracts
export const SET_CONTRACTS_LIST = 'SET_CONTRACTS_LIST'
export const ADD_CONTRACTS = 'ADD_CONTRACTS'
export const INCREASE_PAGINATION = 'INCREASE_PAGINATION'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
export const CHANGE_LOADING = 'CHANGE_LOADING'

// investors
export const SET_INVESTORS = 'SET_INVESTORS'
export const ADD_INVESTOR = 'ADD_INVESTOR'
export const REMOVE_INVESTOR = 'REMOVE_INVESTOR'
export const SET_CURRENT_INVESTOR_ID = 'SET_CURRENT_INVESTOR_ID'
export const SET_CURRENT_INVESTOR = 'SET_CURRENT_INVESTOR'
export const UPDATE_INVESTOR = 'UPDATE_INVESTOR'
export const SET_CARS = 'SET_CARS'

export const SET_DRIVER_ID = 'SET_DRIVER_ID'

export const SET_FILTERS = 'SET_FILTERS'
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'

export const UPDATE_TENANS_INFO_BY_MAX_RETENTIONS = 'UPDATE_TENANS_INFO_BY_MAX_RETENTIONS'
export const SET_CURRENT_REGION_PARK_ID = 'SET_CURRENT_REGION_PARK_ID'
