import { getActualCurrentDriverIncomeData } from '@/requests/driversIncome'

const INIT_TENANT_INCOME_DETAILS = 'INIT_TENANT_INCOME_DETAILS'
const START_LOADING = 'START_LOADING'
const STOP_LOADING = 'STOP_LOADING'
const CHANGE_TENANT_DETAILS_PERCENT = 'CHANGE_TENANT_DETAILS'

const state = () => ({
  isLoading: true,
  tenantIncomeDetails: {}
})

const getters = {}

const mutations = {

  [INIT_TENANT_INCOME_DETAILS]: (state, data) => {
    state.tenantIncomeDetails = data
  },

  [START_LOADING]: state => {
    state.isLoading = true
  },

  [STOP_LOADING]: state => {
    state.isLoading = false
  },
  [CHANGE_TENANT_DETAILS_PERCENT]: (state, newPercent) => {
    state.tenantIncomeDetails.percent = newPercent
  }

}

const actions = {

  async fetchTenantIncomeDetails ({ commit }, id) {
    try {
      commit(START_LOADING)
      const data = await getActualCurrentDriverIncomeData(id)
      commit(INIT_TENANT_INCOME_DETAILS, data)
      commit(STOP_LOADING)
      return Promise.resolve(data)
    } catch (e) {
      commit(STOP_LOADING)
      return Promise.reject(e)
    }
  },
  changeStatePercent ({ commit }, percent) {
    try {
      commit(CHANGE_TENANT_DETAILS_PERCENT, percent)
    } catch (e) {
      commit(STOP_LOADING)
      return Promise.reject(e)
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
