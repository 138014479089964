import { getConsolidatedCars, getParkReportExel } from '@/requests/parkStatistic'
import { SET_CONSOLIDATE_CARS, TOOGLE_LOADING } from '@/store/mutationsTypes'

const state = {
  consolidatedCars: [],
  isLoading: true
}

const mutations = {
  [SET_CONSOLIDATE_CARS]: (state, cars) => {
    state.consolidatedCars = cars
  },

  [TOOGLE_LOADING] (state, value) {
    state.isLoading = value
  }

}

const actions = {
  async fetchConsolidatedCars ({ commit }, { startDate, endDate }) {
    try {
      commit('TOOGLE_LOADING', true)
      const consolidatedCars = await getConsolidatedCars(startDate, endDate)
      commit('TOOGLE_LOADING', false)

      commit('SET_CONSOLIDATE_CARS', consolidatedCars)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async fetchParkReportExel (_, dto) {
    try {
      return await getParkReportExel(dto)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
