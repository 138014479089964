import {
  calculateDriverData, changeDriversPercent, changeTransactionData, changeTransactionsChange, createTransactionData,
  createTransactionDriver,
  deleteDriverIncomeData, deleteTransaction,
  getDriverOpenTransactions, getDriverOpenTransactionsInfo, getDriversIncomeHistoryData,
  getExcelContent, getHistorySettingsSchanges, getNotCalckedDays, sendSurcharge, updateCashSchedules
} from '@/requests/driversIncome'
import { callUpdateScheduleFiveMinutely, callUpdateScheduleDriver } from '@/requests/tasker'
import {
  ADD_RETENTION,
  ADD_SURCHARGE,
  CANCEL_RETENTION,
  CANCEL_SURCHARGE, CHANGE_PERSCENT_BY_ID, COMMIT_RETENTION,
  COMMIT_SURCHARGE,
  INIT_HIST_TABLE,
  INIT_NOT_CALKED_DAYS,
  REMOVE_RETENTION,
  REMOVE_TABLE_ITEM,
  SET_COMPENSATION, SET_CURRENT_REGION_PARK_ID, SET_DRIVER_ID, SET_FILTERS, SET_HISTORY_SETTINGS_CHANGES, START_LOADING, STOP_LOADING, UPDATE_RETENTION, UPDATE_SCHEDULE, UPDATE_TENANS_INFO_BY_MAX_RETENTIONS
} from '@/store/mutationsTypes'
// import { formulaCheck } from '@/requests/сonfigurator'
import { showDefaultErrorMessage, showErrorMessage } from '@/utils/createMessage'
import { Message } from 'element-ui'

const moment = require('moment')

const state = () => ({
  isLoading: true,
  table: [],
  history: [],
  filterDriverId: [],
  notCalkedDays: [],
  filters: {},
  currentRegionParkID: null
})
const helpers = {
  GET_TENANS_INFO_BY_MAX_RETENTIONS ({ state_table, scheduleId, maxRetentions, retentionTypes }) {
    if (!retentionTypes?.length || !maxRetentions?.length) return state_table
    const getTenantsIndexByScheduleId = state_table.findIndex(d => d.schedule.id === scheduleId)
    if (getTenantsIndexByScheduleId === -1) return false
    const { retentions_to_exec } = state_table[getTenantsIndexByScheduleId] // retentions
    return retentions_to_exec.reduce((acc, retention_value) => {
      const getereteRetentionToExec = (value, current_amount, amount) => {
        const calc_current_by_max = amount - value
        const current_amount_g = retention_value?.newSum ? retention_value.newSum : (amount - current_amount) < value ? current_amount : calc_current_by_max
        return {
          fixed_sum: false,
          // amount: amount - value,
          current_amount: current_amount_g,
          diableCalc: true
        }
      }
      const { type, amount } = retention_value //  amount, current_amount
      const getNameRetentionById = retentionTypes.filter(x => x.id === type)[0]?.name
      const checkRetentionOnListMaxRetentions = maxRetentions.filter(x => x.name === getNameRetentionById)

      if (checkRetentionOnListMaxRetentions?.length) {
        const { value } = checkRetentionOnListMaxRetentions[0]
        if (amount > value && !retention_value?.event_at) {
          acc.retentions_to_exec = [...acc.retentions_to_exec, { ...retention_value, ...getereteRetentionToExec(value, retention_value.current_amount, amount) }]
          return acc
        } else {
          acc.retentions_to_exec = [...acc.retentions_to_exec, { ...retention_value }]
          return acc
        }
      }
      acc.retentions_to_exec = [...acc.retentions_to_exec, { ...retention_value }]
      return acc
    }, {
      retentions_to_exec: [],
      retentions: []
    })
  }
}
const getters = {
  notCommitedDrivers: (state, getters) => {
    return getters.getAllDriversByFilter.filter(d => !d.schedule.is_calculated)
  },
  commitedDrivers: (state, getters) => getters.getAllDriversByFilter.filter(d => d.schedule.is_calculated && d.schedule.calculation_type === 'registry'),
  commitedBalanceDrivers: (state, getters) => getters.getAllDriversByFilter.filter(d => d.schedule.is_calculated && d.schedule.calculation_type === 'change_balance'),
  getAllDriversForFilter: (state) => {
    const mappedTable = state.table.map((d, idx) => {
      return {
        driver_name: d.schedule.driver_name,
        id: d.schedule.id
      }
    })
    const mappedOnlyNames = state.table.map(d => d.schedule.driver_name)
    const newSetNames = [...new Set(mappedOnlyNames)]
    const redusedNamesDrivers = newSetNames.reduce((acc, driver_name) => {
      const checkDublicate = mappedTable.filter(value_filter => value_filter.driver_name === driver_name).map((v) => v.id)
      acc.push({
        driver_name: driver_name,
        ids: [...checkDublicate]
      })
      return acc
    }, [])
    return redusedNamesDrivers
  },
  getSurchargesByDriverId: (state) => (driverID) => state.table.filter(d => !d.schedule.driver === driverID),
  getByDriverId: (state) => (driverID) => state.table.filter(d => d.schedule.driver === driverID),
  getDataByScheduleID: (state) => (id) => state.table.filter(d => d.schedule.id === id),
  getTableElementByScheduleId: (state) => (sheduleId) => state.table.filter(d => d.schedule.id === sheduleId)[0],
  getAllDriversByFilter: (state, getters, rootState, rootGetters) => {
    const getMaxRetentionsTypes = rootState.settings.retentionsMinMax?.max
    const { maxRetentions, retentionTypes } = { maxRetentions: getMaxRetentionsTypes, retentionTypes: rootGetters['settings/getTransactionTypesToDropdown'] }
    if (!state.filterDriverId.length) {
      const tets = state.table.reduce((accDriver, valueDriver) => {
        //
        const { id: scheduleId, driver: driverId } = valueDriver.schedule
        const xuita = helpers.GET_TENANS_INFO_BY_MAX_RETENTIONS({ state_table: state.table, scheduleId, maxRetentions, retentionTypes })
        const return_v = xuita?.retentions_to_exec ? { ...valueDriver, retentions_to_exec: xuita.retentions_to_exec } : valueDriver
        const driverIsDublicat = state.table.filter((x) => x?.schedule?.driver === driverId).length > 1
        return [...accDriver, { ...return_v, isDublicate: driverIsDublicat }]
        //
      }, [])
      console.log('tets', tets)
      return tets.sort((a, b) => a.schedule?.driver - b.schedule?.driver)
    }
    const getOnluIds = state?.filterDriverId.reduce((acc, value) => {
      return [...acc, ...value.ids]
    }, [])
    const filtredDrivers = state.table.filter(val => getOnluIds.some(v => val.schedule.id === v))

    return filtredDrivers.reduce((accDriver, valueDriver) => {
      //
      const { id: scheduleId } = valueDriver.schedule
      const xuita = helpers.GET_TENANS_INFO_BY_MAX_RETENTIONS({ state_table: state.table, scheduleId, maxRetentions, retentionTypes })
      const return_v = xuita?.retentions_to_exec ? { ...valueDriver, retentions_to_exec: xuita.retentions_to_exec } : valueDriver
      return [...accDriver, { ...return_v }]
      //
    }, [])
  },
  getHistoryPercentByScheduleId: (state) => (prop_id, change) => {
    return state.history.filter(a => a.name === 'fixed_percent' && a.schedule_id === prop_id && a.change === change) || []
  },
  getRetentionsByScheduleId: (state) => (sheduleId) => {
    console.log('getRetentionsByScheduleId')
    const { retentions_to_exec, retentions, schedule } = state.table.filter(item => item.schedule.id === sheduleId)[0] || []
    return { retentions_to_exec, retentions, schedule }
  }
}

const mutations = {
  [INIT_HIST_TABLE]: (state, history) => {
    const redusedHistory = history.reduce((acc, value) => {
      const check = value.schedule.is_calculated
      const base_state_retentions = check ? [...value.retentions_history] : [...value.retentions_to_exec]
      acc = [...acc, { ...value, base_state_retentions }]
      return acc
    }, [])
    state.table = redusedHistory
  },
  [SET_HISTORY_SETTINGS_CHANGES]: (state, history) => {
    state.history = history
  },
  [SET_CURRENT_REGION_PARK_ID]: (state, value) => {
    state.currentRegionParkID = value
  },
  [SET_DRIVER_ID]: (state, ids) => {
    state.filterDriverId = ids
  },
  [START_LOADING]: state => {
    state.isLoading = true
  },

  [STOP_LOADING]: state => {
    state.isLoading = false
  },
  [SET_FILTERS]: (state, data) => {
    state.filters = data
  },
  [INIT_NOT_CALKED_DAYS]: (state, arr) => {
    state.notCalkedDays = arr
  },

  [REMOVE_TABLE_ITEM]: (state, scheduleId) => {
    const index = state.table.findIndex(item => item.schedule.id === scheduleId)

    state.table.splice(index, 1)
  },

  [REMOVE_RETENTION]: (state, { transactionId, driverId, lastSheduleId }) => {
    const driverIndex = state.table.findIndex(d => d.schedule.id === lastSheduleId)
    // Удаление из retentions
    const retentionIndex = state.table[driverIndex].retentions.findIndex(r => r.id === transactionId)

    const getParentId = state.table[driverIndex].retentions.find(x => x.parent_id === state.table[driverIndex].retentions[retentionIndex].id)
    if (getParentId) {
      const getIndexByParentIdFind = state.table[driverIndex].retentions.findIndex(r => r.id === getParentId.id)
      if (getIndexByParentIdFind !== -1) state.table[driverIndex].retentions.splice(getIndexByParentIdFind, 1)
    }
    // state.table[driverIndex].retentions.splice(retentionIndex, 1)

    if (retentionIndex !== -1) state.table[driverIndex].retentions.splice(retentionIndex, 1)

    // Удаление из retentions_to_exec
    const retentionToExecIndex = state.table[driverIndex].retentions_to_exec.findIndex(r => r.id === transactionId)
    if (retentionToExecIndex !== -1) state.table[driverIndex].retentions_to_exec.splice(retentionToExecIndex, 1)
  },

  [ADD_RETENTION]: (state, { scheduleId, retention }) => {
    const index = state.table.findIndex(d => d.schedule.id === scheduleId)

    state.table[index].retentions.push({
      ...retention,
      newSum: null
    })
  },

  [UPDATE_RETENTION]: (state, { driverId, retention }) => {
    const index = state.table.findIndex(d => d.schedule.driver === driverId)

    state.table[index].retentions = [
      ...state.table[index].retentions.filter(r => r.id !== retention.id),
      {
        ...retention,
        newSum: null
      }
    ]
  },
  [UPDATE_SCHEDULE]: (state, { scheduleId, formulaResponse }) => {
    const index = state.table.findIndex(d => d.schedule.id === scheduleId)
    if (index === -1) return false
    state.table[index].schedule = { ...state.table[index].schedule, ...formulaResponse }
    console.log(state.table[index].schedule)
    // state.table[index] = state.table[index].slice()
  },

  [COMMIT_RETENTION]: (state, { driverId, retention, sheduleId }) => {
    console.log(sheduleId)
    const index = state.table.findIndex(d => d.schedule.id === sheduleId)
    const retentionIndex = state.table[index].retentions_to_exec.findIndex(e => e.transaction_id === retention.transaction_id)
    console.log('COMMIT_RETENTION', retentionIndex)
    if (retentionIndex === -1) {
      state.table[index].retentions_to_exec.push(retention)
      // state.table[index].retentions_to_exec = [...state.table[index].retentions_to_exec, { ...retention }]
    } else {
      state.table[index].retentions_to_exec.splice(index, 1, retention)
    }
    state.table = state.table.slice()
    // retentionIndex === -1
    //   ? state.table[index].retentions_to_exec.push(retention)
    //   : state.table[index].retentions_to_exec.splice(index, 1, retention)
  },

  [CANCEL_RETENTION]: (state, { driverId, retentionId, sheduleId }) => {
    console.log(driverId, retentionId, sheduleId)
    const index = state.table.findIndex(d => d.schedule.id === sheduleId)
    const getIndexRetentionsToExec = state.table[index].retentions_to_exec.findIndex(r => r.transaction_id === retentionId)
    // Удалить из retentions_to_exec
    console.log('CANCEL_RETENTION', getIndexRetentionsToExec)
    state.table[index].retentions_to_exec.splice(getIndexRetentionsToExec, 1)
    // state.table[index].retentions_to_exec = state.table[index].retentions_to_exec.splice(getIndexRetentionsToExec, 1)
  },
  [CHANGE_PERSCENT_BY_ID]: (state, { id, newPercent }) => {
    const index = state.table.findIndex(d => d.schedule.id === id)

    state.table[index].schedule.driver_percent = newPercent
  },

  [SET_COMPENSATION]: (state, { driverId, compensation }) => {
    const index = state.table.findIndex(d => d.schedule.driver === driverId)
    console.log('index', index)
    console.log('compensation', compensation)
    state.table[index].schedule.driver_compensation = compensation
    console.log('compensation set', state.table[index].schedule.driver_compensation = compensation)
  },

  [ADD_SURCHARGE]: (state, { scheduleId, surcharge }) => {
    const index = state.table.findIndex(d => d.schedule.id === scheduleId)

    if (index !== -1) state.table[index].surcharges.push(surcharge)
  },
  [UPDATE_TENANS_INFO_BY_MAX_RETENTIONS]: (state, { scheduleId, maxRetentions, retentionTypes }) => {
    const getTenantsIndexByScheduleId = state.table.findIndex(d => d.schedule.id === scheduleId)
    if (getTenantsIndexByScheduleId === -1) return false
    const { retentions_to_exec } = state.table[getTenantsIndexByScheduleId] // retentions
    const test = retentions_to_exec.reduce((acc, retention_value) => {
      const getereteRetentionToExec = (value, current_amount, amount) => {
        const calc_current_by_max = amount - value
        const current_amount_g = retention_value?.newSum ? retention_value.newSum : (amount - current_amount) < value ? current_amount : calc_current_by_max
        return {
          fixed_sum: false,
          // amount: amount - value,
          current_amount: current_amount_g,
          diableCalc: true
        }
      }
      const { type, amount } = retention_value //  amount, current_amount
      const getNameRetentionById = retentionTypes.filter(x => x.id === type)[0]?.name
      const checkRetentionOnListMaxRetentions = maxRetentions.filter(x => x.name === getNameRetentionById)

      if (checkRetentionOnListMaxRetentions?.length) {
        const { value } = checkRetentionOnListMaxRetentions[0]
        if (amount > value && !retention_value?.event_at) {
          acc.retentions_to_exec = [...acc.retentions_to_exec, { ...retention_value, ...getereteRetentionToExec(value, retention_value.current_amount, amount) }]
          return acc
        } else {
          acc.retentions_to_exec = [...acc.retentions_to_exec, { ...retention_value }]
          return acc
        }
      }
      acc.retentions_to_exec = [...acc.retentions_to_exec, { ...retention_value }]
      return acc
    }, {
      retentions_to_exec: [],
      retentions: []
    })
    state.table[getTenantsIndexByScheduleId].retentions_to_exec = test.retentions_to_exec
  }
}

const actions = {
  setCurrentRegionParkID ({ commit }, region_park_id) {
    commit('SET_CURRENT_REGION_PARK_ID', region_park_id)
  },
  async updateCashSchedulesByScheduleId ({ commit, dispatch }, { scheduleId, data, schedule_date }) {
    try {
      await updateCashSchedules(scheduleId, data)
      commit(UPDATE_SCHEDULE, { scheduleId, formulaResponse: data })
      await callUpdateScheduleFiveMinutely({ date: schedule_date, schedule_id: scheduleId })
      await await dispatch('updateRetentionByFormula', { scheduleId })
    } catch (error) {
      Message({
        message: 'Произошла ошибка!',
        type: 'error',
        duration: 5 * 1000
      })
    }
  },
  async updateRetentionByMax ({ commit, rootState, rootGetters }, { scheduleId }) {
    const getMaxRetentionsTypes = rootState.settings.retentionsMinMax?.max
    if (!getMaxRetentionsTypes) return
    commit(UPDATE_TENANS_INFO_BY_MAX_RETENTIONS, { scheduleId, maxRetentions: getMaxRetentionsTypes, retentionTypes: rootGetters['settings/getTransactionTypesToDropdown'] })
  },
  async updateRetentionByFormula ({ commit, dispatch, rootState, rootGetters, rootActions, state }, { scheduleId, data }) {
    try {
      const getDataByScheduleID = state.table.findIndex(d => d.schedule.id === scheduleId)
      if (getDataByScheduleID === -1) return console.log('nema zapisi s takim id')
      const { retention_balance, details_cash, details_card, details_tip, service_commission, park_commission, driver_percent, fuel, cash, fixed_sum, pay_extra_amount, region_park_id } = state.table[getDataByScheduleID]?.schedule

      const getRetentionsToExecRealCurrentAmount = (row) => {
        const { fixed_sum, current_amount, exec_amount, newSum } = row
        if (newSum) {
          return newSum
        }
        if (fixed_sum) {
          return current_amount
        }
        return exec_amount
      }
      const getRetentionsTotalSum = (retentionList) => {
        return (retentionList.reduce((acc, retention) => acc + getRetentionsToExecRealCurrentAmount(retention), 0) - retention_balance).toFixed(2) || 0
      }
      console.log('getRetentionsTotalSum', getRetentionsTotalSum)
      const company_percent = Number((1 - driver_percent).toFixed(2))
      // const surcharge_sum = 1
      const retention_sum = Number(getRetentionsTotalSum(state.table[getDataByScheduleID]?.retentions_to_exec))
      console.log('retention_sum', getRetentionsTotalSum)
      console.log('getRetentionsTotalSum retention_sum', getRetentionsTotalSum(state.table[getDataByScheduleID]?.retentions_to_exec))
      const surcharge_sum = pay_extra_amount

      // commit(START_LOADING)
      const formulaResponse = await dispatch('сonfigurator/formulaCheck', {
        data: { company_percent, retention_sum, details_cash, details_card, details_tip, service_commission, park_commission, driver_percent, fuel_sum: fuel, surcharge_sum, cash, fixed_sum },
        region_park_id: region_park_id || state.currentRegionParkID
      }, { root: true })
      console.log('formulaResponse', formulaResponse)
      // const formulaResponse = await formulaCheck({
      //   data: { company_percent, retention_sum, details_cash, details_card, details_tip, service_commission, park_commission, driver_percent, fuel_sum: fuel, surcharge_sum },
      //   region_park_id: state.currentRegionParkID
      // })
      // console.log(dataByFormula, 'red')
      commit(UPDATE_SCHEDULE, { scheduleId, formulaResponse })
    } catch (e) {
      return Promise.reject(e)
    } finally {
      // await formulaCheck({ ...data })
      commit(STOP_LOADING)
    }
    // const getMaxRetentionsTypes = rootState.settings.retentionsMinMax?.max
    // if (!getMaxRetentionsTypes) return
    // commit(UPDATE_TENANS_INFO_BY_MAX_RETENTIONS, { scheduleId, maxRetentions: getMaxRetentionsTypes, retentionTypes: rootGetters['settings/getTransactionTypesToDropdown'] })
  },
  async getDriverPerscentsOnSettingsByPark ({ commit, dispatch, rootState, rootGetters, rootActions, state }, scheduleId) {
    const getDriverByScheduleId = state.table.filter(x => x.schedule.id === scheduleId)
    const getGlobalSettingsPerscents = () => {
      const getDriversPercentsInSettings = rootState.settings.drivers_percents
      return {
        percents: getDriversPercentsInSettings,
        park: {}
      }
    }
    if (getDriverByScheduleId?.length === 0) return getGlobalSettingsPerscents()
    const { region_park_id } = getDriverByScheduleId[0].schedule
    if (!region_park_id) {
      return getGlobalSettingsPerscents()
    }
    const getSettingsRegionParkById = await rootGetters['regionPark/getRegionalCarParkByID'](region_park_id)
    const { drivers_percents } = getSettingsRegionParkById?.settings
    if (!drivers_percents || typeof drivers_percents !== 'string') return getGlobalSettingsPerscents()
    const parseJsonPerscents = (drivers_percents) => {
      try {
        return JSON.parse(drivers_percents)
      } catch (e) {
        return JSON.parse(JSON.parse(drivers_percents))
      }
    }
    return {
      percents: parseJsonPerscents(drivers_percents),
      park: {
        id: getSettingsRegionParkById?.id,
        name: getSettingsRegionParkById?.name
      }
    }
  },
  async fetchDriversIncomeHistory ({ commit, state }, data) {
    try {
      if (data?.change === 'all') delete data.change
      commit(START_LOADING)
      const history = await getDriversIncomeHistoryData({ ...data, region_park_id: state.currentRegionParkID || data?.region_park_id })
      commit(INIT_HIST_TABLE, history)
    } catch (e) {
      return Promise.reject(e)
    } finally {
      commit(STOP_LOADING)
    }
  },
  async setParamsFilters ({ commit }, data) {
    try {
      commit(SET_FILTERS, data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async fetchNotCalkedDays ({ commit }) {
    try {
      const start = moment().subtract(30, 'days').format('YYYY-MM-DD')
      const end = moment().format('YYYY-MM-DD')
      const arr = await getNotCalckedDays(start, end)
      commit(INIT_NOT_CALKED_DAYS, arr)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async deleteDriverIncomeData ({ commit }, scheduleId) {
    try {
      await deleteDriverIncomeData(scheduleId)

      commit(REMOVE_TABLE_ITEM, scheduleId)
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async downloadExcel (_, dto) {
    try {
      return await getExcelContent(dto)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  commitSurcharge ({ commit }, commitSurchargeDto) {
    commit(COMMIT_SURCHARGE, commitSurchargeDto)
  },

  cancelSurcharge ({ commit }, cancelSurchargeDto) {
    commit(CANCEL_SURCHARGE, cancelSurchargeDto)
  },

  setCompensation ({ commit }, params) {
    commit(SET_COMPENSATION, params)
  },
  setFilterDriverId ({ commit }, id) {
    commit(SET_DRIVER_ID, id)
  },
  async commitTenantIncome ({ dispatch, state }, { id, driver, commitTenantIncomeDto, query }) {
    try {
      const { success, detail } = await calculateDriverData(id, commitTenantIncomeDto, { region_park_id: state.currentRegionParkID })
      if (!success) {
        Message({
          message: detail || 'Не удалось рассчитать водителя',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(success)
      }
      await callUpdateScheduleDriver({ driver_id: driver })
      await dispatch('fetchDriversIncomeHistory', query)
    } catch (e) {
      const { data } = e.response
      Message({
        message: data.detail || 'Не удалось рассчитать водителя',
        type: 'error',
        duration: 5 * 1000
      })
      return data
    }
  },

  async createRetentionTransaction ({ commit, rootGetters, state }, { driverId, scheduleId, createRetentionDto }) {
    try {
      const park = rootGetters['settings/getDefaultParkPk']
      const retention = await createTransactionData({
        ...createRetentionDto,
        park
      })
      // commit(ADD_RETENTION, { retention, driverId, scheduleId })
      Message({
        type: 'success',
        message: 'Удержание назначенно'
      })
      // await callUpdateScheduleFiveMinutely({ date: retention.schedule_date })
      // commit(START_LOADING)
      // const history = await getDriversIncomeHistoryData({ ...state.filters })
      // commit(INIT_HIST_TABLE, history)
      // commit(STOP_LOADING)
      return retention
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async callUpdateScheduleFiveMinutely ({ commit, state }, params) {
    commit(START_LOADING)
    await callUpdateScheduleFiveMinutely(params)
    const history = await getDriversIncomeHistoryData({ ...state.filters, change: state.filters?.change === 'all' ? null : state.filters?.change })
    commit(INIT_HIST_TABLE, history)
    commit(STOP_LOADING)
  },
  async createTransactionDriver ({ commit, rootGetters, state }, { createRetentionDto }) {
    try {
      const park = rootGetters['settings/getDefaultParkPk']
      // const retention = await createTransactionDriver({
      //   ...createRetentionDto,
      //   park
      // })

      const retention = await createTransactionDriver({
        ...createRetentionDto,
        park
      })
        .then((response) => {
          callUpdateScheduleDriver({ driver_id: createRetentionDto.driver_id })
          Message({
            type: 'success',
            message: 'Удержание назначенно водителю'
          })
          return response
        })
        .catch(function (error) {
          if (error.response) {
            const { data } = error.response
            if (!data?.success) {
              Message({
                type: 'error',
                message: data.details || 'Не удалось добавить удержание'
              })
            }
          }
        })
      // commit(START_LOADING)
      // const history = await getDriversIncomeHistoryData({ ...state.filters })
      // commit(INIT_HIST_TABLE, history)
      // commit(STOP_LOADING)
      // commit(ADD_RETENTION, { retention, driverId, scheduleId })

      return retention
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async createSurcharge ({ commit, state }, surchargeDto) {
    try {
      const { schedule_date } = await createTransactionData(surchargeDto)
      try {
        await callUpdateScheduleFiveMinutely({ date: schedule_date, schedule_id: surchargeDto.schedule }).catch(x => {
          console.log(x)
        })
      } catch (error) {
        console.log('createSurcharge callUpdateScheduleFiveMinutely ERROR', error)
      }
      commit(START_LOADING)
      const history = await getDriversIncomeHistoryData({ ...state.filters, change: state.filters?.change === 'all' ? null : state.filters?.change })
      commit(INIT_HIST_TABLE, history)
      commit(STOP_LOADING)

      // commit(ADD_SURCHARGE, {
      //   scheduleId: surchargeDto.schedule,
      //   surcharge
      // })
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async updateIncomeHistoryByStateFilters ({ commit, state }) {
    console.log('updateIncomeHistoryByStateFilters')
    try {
      commit(START_LOADING)
      const history = await getDriversIncomeHistoryData({ ...state.filters })
      commit(INIT_HIST_TABLE, history)
      commit(STOP_LOADING)
    } catch (err) {
      commit(STOP_LOADING)
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async patchRetention ({ commit }, { driverId, transactionId, patchRetentionDto }) {
    try {
      const retention = await changeTransactionData(transactionId, patchRetentionDto)

      commit(UPDATE_RETENTION, {
        driverId,
        retention
      })
    } catch (e) {
      return Promise.reject(e)
    }
  },

  commitRetention ({ commit }, commitRetentionDto) {
    commit(COMMIT_RETENTION, commitRetentionDto)
  },

  cancelRetention ({ commit }, { driverId, sheduleId, retentionId }) {
    commit(CANCEL_RETENTION, { driverId, sheduleId, retentionId })
  },

  async sendSurcharge ({ commit, state }, surchargeDto) {
    try {
      console.log('surchargeDto', surchargeDto)
      await sendSurcharge(surchargeDto) //    const surcharge =
      await callUpdateScheduleFiveMinutely({ date: surchargeDto?.date, schedule_id: surchargeDto.surcharge_schedule })
      Message({
        type: 'success',
        message: 'Транзакция с доплатой создана'
      })
      commit(START_LOADING)
      const history = await getDriversIncomeHistoryData({ ...state.filters, change: state.filters?.change === 'all' ? null : state.filters?.change })
      commit(INIT_HIST_TABLE, history)
      commit(STOP_LOADING)
      // commit(ADD_SURCHARGE, {
      //   scheduleId: surchargeDto.surcharge_schedule,
      //   surcharge
      // })
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async deleteTransaction ({ commit, state }, params) {
    try {
      await deleteTransaction(params.transactionId)

      Message({
        type: 'success',
        message: 'Транзакция удалена'
      })
      await callUpdateScheduleFiveMinutely({ date: params.schedule_date, schedule_id: params.lastSheduleId })
      commit(START_LOADING)
      const history = await getDriversIncomeHistoryData({ ...state.filters, change: state.filters?.change === 'all' ? null : state.filters?.change })
      commit(INIT_HIST_TABLE, history)
      commit(STOP_LOADING)
      // commit(REMOVE_RETENTION, params)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async changeDriversPercent ({ state, commit, dispatch }, body) {
    try {
      await changeDriversPercent(body)
      commit(CHANGE_PERSCENT_BY_ID, { id: body.schedule_id, newPercent: body.new_driver_percent })
      Message({
        type: 'success',
        message: 'Процент успешно изменен'
      })
      dispatch('updateRetentionByFormula', { scheduleId: body.schedule_id })

      const { date } = state.table.find(d => d.schedule.id === body.schedule_id)?.schedule
      if (!date) return
      await callUpdateScheduleFiveMinutely({ date, schedule_id: body.schedule_id })
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async getHistorySettingsSchanges ({ commit }) {
    try {
      const data = await getHistorySettingsSchanges()
      commit(SET_HISTORY_SETTINGS_CHANGES, data)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async changeTransactionsChange ({ commit }, schedule_id) {
    try {
      const data = await changeTransactionsChange(schedule_id)
        .then((response) => {
          Message({
            type: 'success',
            message: 'Сменна успешно изменена'
          })
        })
        .catch(function (error) {
          if (error.response) {
            const { data } = error.response
            if (!data.success) {
              Message({
                type: 'error',
                message: data.message || 'Произошла ошибка при переносе транзакий'
              })
            }
          }
        })
      // commit(SET_HISTORY_SETTINGS_CHANGES, data)
      console.log(data)
    } catch (err) {
      console.dir(err.stack)
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async getDriverOpenTransactions ({ commit }, driverId) {
    try {
      const response = await getDriverOpenTransactions(driverId)

      return response
    } catch (err) {
      showErrorMessage('Произошла ошибка')
    }
  },

  async getDriverOpenTransactionsInfo ({ commit }, transactionId) {
    try {
      const response = await getDriverOpenTransactionsInfo(transactionId)

      return response
    } catch (err) {
      showErrorMessage('Произошла ошибка')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
