import request from '../utils/request'
const url = '/taxi/contracts'
export const createTemplateType = (data) => request({
  method: 'post',
  url: `${url}/types/`,
  data
})
export const getTemplateTypes = () => request({
  method: 'get',
  url: `${url}/types/`
})
export const getTemplateTypeById = (id) => request({
  method: 'get',
  url: `${url}/types/${id}/`
})
export const deleteTemplateTypeById = (id) => request({
  method: 'delete',
  url: `${url}/types/${id}/`
})
export const updateTemplateTypeById = (id, data) => request({
  method: 'put',
  url: `${url}/types/${id}/`,
  data
})

// Tamplates Some
export const createTemplate = (data) => request({
  method: 'post',
  url: `${url}/templates/test/`,
  data,
  headers: { 'Content-Type': 'multipart/form-data' },
  params: {
    filename: 'Jotaro_Kujo_Filename_Top?'
  }
})
export const getTemplates = (params) => request({
  method: 'get',
  url: `${url}/templates/test/`,
  params
})
export const getTemplateById = (id) => request({
  method: 'get',
  url: `${url}/templates/${id}`
})
export const updateTemplateById = (id, data) => request({
  method: 'put',
  url: `${url}/template/${id}/`,
  headers: { 'Content-Type': 'multipart/form-data' },
  data
})
export const deleteTemplateById = (id) => request({
  method: 'delete',
  url: `${url}/template/${id}/`
})
// Variables
export const getTemplateVariables = () => request({
  method: 'get',
  url: `${url}/variables/`
})
