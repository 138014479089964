import { PartsOrderStatusEnum } from '@/enums/PartsOrderStatusEnum'
import {
  acceptPartsOrder,
  addToWarehouse,
  createPart,
  createPartCategory,
  createPartsOrder,
  createSupplier,
  decommissionPart,
  deletePart,
  deletePartCategory,
  updatePartCategory,
  deletePartsOrder, deleteSupplier, deleteWarehouse, fetchDecommissionParts,
  fetchPartCategories,
  fetchParts,
  fetchPartsOrderById,
  fetchPartsOrders,
  fetchSupplierById,
  fetchSuppliers,
  fetchUnitMeasure,
  fetchWarehouse, updatePart,
  updatePartsOrder,
  updateSupplier,
  updateWarehouse,
  returnDecommissionPart,
  fetchScrappedHistory,
  fetchScrappedHistoryPartById,
  fetchMovementHistory,
  fetchMovementHistorySparePartById
} from '@/requests/parts'
import {
  showErrorMessage,
  showSuccessMessage
} from '@/utils/createMessage'
import moment from 'moment'
const TOGGLE_LOADING = 'TOGGLE_LOADING'
const SET_SUPPLIERS = 'SET_SUPPLIERS'
const ADD_SUPPLIER = 'ADD_SUPPLIER'
const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER'
const DELETE_SUPPLIER = 'DELETE_SUPPLIER'
const SET_PARTS_ORDERS = 'SET_PARTS_ORDERS'
const ADD_PARTS_ORDER = 'ADD_PARTS_ORDER'
const DELETE_PARTS_ORDER = 'DELETE_PARTS_ORDER'
const UPDATE_PARTS_ORDER = 'UPDATE_PARTS_ORDER'
const TOGGLE_PARTS_ORDER_STATUS = 'TOGGLE_PARTS_ORDER_STATUS'
const SET_WAREHOUSE = 'SET_WAREHOUSE'
const ADD_TO_WAREHOUSE = 'ADD_TO_WAREHOUSE'
const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE'
const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE'
const SET_PART_CATEGORIES = 'SET_PART_CATEGORIES'
const ADD_PART_CATEGORY = 'ADD_PART_CATEGORY'
const UPDATE_PART_CATEGORY = 'UPDATE_PART_CATEGORY'
const DELETE_PART_CATEGORY = 'DELETE_PART_CATEGORY'
const SET_UNIT_MEASURE = 'SET_UNIT_MEASURE'
const SET_PARTS = 'SET_PARTS'
const ADD_PART = 'ADD_PART'
const UPDATE_PART = 'UPDATE_PART'
const DELETE_PART = 'DELETE_PART'
const SET_DECOMMISSION_PARTS = 'SET_DECOMMISSION_PARTS'
const DELETE_DECOMMISSION_PARTS = 'DELETE_DECOMMISSION_PARTS'
const SET_FILTERS = 'SET_FILTERS'
const SET_SCRAPPED_HISTORY = 'SET_SCRAPPED_HISTORY'

const state = {
  isLoading: true,
  suppliers: [],
  partsOrders: [],
  warehouse: [],
  parts: [],
  decommissionParts: [],
  partCategories: [],
  scrappedHistory: [],
  filters: {
    supplier: '',
    category: '',
    vendorCode: '',
    partPart: '',
    part: '',
    dateTime: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  },
  unitMeasure: []
}

const mutations = {
  [TOGGLE_LOADING] (state, bool) {
    state.isLoading = bool
  },

  [SET_SUPPLIERS] (state, suppliers) {
    state.suppliers = suppliers
  },
  [SET_SCRAPPED_HISTORY] (state, scrappedHistory) {
    state.scrappedHistory = scrappedHistory
  },
  [ADD_SUPPLIER] (state, supplier) {
    state.suppliers.push(supplier)
  },

  [UPDATE_SUPPLIER] (state, supplier) {
    const index = state.suppliers.findIndex(e => e.id === supplier.id)

    state.suppliers.splice(index, 1, supplier)
  },

  [DELETE_SUPPLIER] (state, supplierId) {
    const index = state.suppliers.findIndex(e => e.id === supplierId)

    state.suppliers.splice(index, 1)
  },

  [SET_PARTS_ORDERS] (state, partsOrders) {
    state.partsOrders = partsOrders
  },

  [ADD_PARTS_ORDER] (state, partsOrder) {
    state.partsOrders.push(partsOrder)
  },

  [UPDATE_PARTS_ORDER] (state, partsOrder) {
    const index = state.partsOrders.findIndex(e => e.id === partsOrder.id)

    state.partsOrders.splice(index, 1, partsOrder)
  },

  [DELETE_PARTS_ORDER] (state, orderId) {
    state.partsOrders = state.partsOrders.filter(e => e.id !== orderId)
  },

  [TOGGLE_PARTS_ORDER_STATUS] (state, { id, status }) {
    const index = state.partsOrders.findIndex(e => e.id === id)

    state.partsOrders.splice(index, 1, status)
  },

  [SET_WAREHOUSE] (state, warehouse) {
    state.warehouse = warehouse
  },

  [ADD_TO_WAREHOUSE] (state, part) {
    state.warehouse.unshift(part)
  },

  [UPDATE_WAREHOUSE] (state, part) {
    const index = state.warehouse.findIndex(e => e.id === part.id)

    state.warehouse.splice(index, 1, { ...state.warehouse[index], ...part })
  },

  [DELETE_WAREHOUSE] (state, partId) {
    state.warehouse = state.warehouse.filter(e => e.id !== partId)
  },

  [SET_UNIT_MEASURE] (state, unitMeasure) {
    state.unitMeasure = unitMeasure
  },

  [SET_PARTS] (state, parts) {
    state.parts = parts
  },

  [ADD_PART] (state, part) {
    state.parts.push(part)
  },

  [UPDATE_PART] (state, part) {
    const index = state.parts.findIndex(e => e.spare_part_id === part.spare_part_id)

    state.parts.splice(index, 1, part)
  },

  [DELETE_PART] (state, partId) {
    state.parts = state.parts.filter(e => e.spare_part_id !== partId)
  },

  [SET_PART_CATEGORIES] (state, categories) {
    state.partCategories = categories
  },

  [ADD_PART_CATEGORY] (state, category) {
    state.partCategories.push(category)
  },

  [DELETE_PART_CATEGORY] (state, categoryId) {
    state.partCategories = state.partCategories.filter(e => e.id !== categoryId)
  },
  [UPDATE_PART_CATEGORY] (state, { id, dto }) {
    const index = state.partCategories.findIndex(e => e.id === id)
    state.partCategories.splice(index, 1, { ...state.partCategories[index], ...dto })
  },
  [SET_DECOMMISSION_PARTS] (state, parts) {
    state.decommissionParts = parts
  },
  [DELETE_DECOMMISSION_PARTS] (state, id) {
    state.decommissionParts = state.decommissionParts.filter(e => e.id !== id)
  },
  [SET_FILTERS] (state, filters) {
    state.filters = filters
  }
}

const actions = {
  async fetchSuppliers ({ commit }) {
    try {
      commit(TOGGLE_LOADING, true)
      const suppliers = await fetchSuppliers()

      commit(SET_SUPPLIERS, suppliers)
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },
  async fetchScrappedHistory ({ commit }, { start_date, end_date }) {
    try {
      commit(TOGGLE_LOADING, true)
      const scrappedHistory = await fetchScrappedHistory({ start_date, end_date })

      commit(SET_SCRAPPED_HISTORY, scrappedHistory)
      commit(TOGGLE_LOADING, false)
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },
  async fetchScrappedHistoryPartById ({ commit }, { start_date, end_date, id }) {
    try {
      return await fetchScrappedHistoryPartById({ start_date, end_date }, id)
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    }
  },
  async fetchMovementHistorySparePartById ({ commit }, { start_date, end_date, id }) {
    try {
      return await fetchMovementHistorySparePartById({ start_date, end_date }, id)
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    }
  },
  async fetchMovementHistory ({ commit }, { start_date, end_date }) {
    try {
      return await fetchMovementHistory({ start_date, end_date })
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    }
  },
  async fetchSupplierById ({ commit }, id) {
    try {
      const supplier = await fetchSupplierById(id)

      return supplier
    } catch (err) {
      showErrorMessage('Не удалось загрузить данные поставщика')
      return Promise.reject(err)
    }
  },

  async createSupplier ({ commit }, supplierDto) {
    try {
      const { id } = await createSupplier(supplierDto)

      supplierDto.id = id

      commit(ADD_SUPPLIER, supplierDto)

      showSuccessMessage('Поставщик создан')
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    }
  },
  async returnDecommissionPart ({ commit }, id) {
    try {
      const data = await returnDecommissionPart(id)
      commit(DELETE_DECOMMISSION_PARTS, id)
      showSuccessMessage('Запчасть(-и) возвращены на склад')
      return data
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    }
  },
  async updateSupplier ({ commit }, supplierDto) {
    try {
      await updateSupplier(supplierDto)

      commit(UPDATE_SUPPLIER, supplierDto)

      showSuccessMessage('Поставщик обновлен')
    } catch (err) {
      showErrorMessage('Не удалось обновить поставщика')
      return Promise.reject(err)
    }
  },

  async deleteSupplier ({ commit }, supplierId) {
    try {
      await deleteSupplier(supplierId)

      commit(DELETE_SUPPLIER, supplierId)

      showSuccessMessage('Поставщик удален')
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    }
  },

  async fetchPartsOrders ({ commit }) {
    try {
      commit(TOGGLE_LOADING, true)
      const partsOrders = await fetchPartsOrders()

      commit(SET_PARTS_ORDERS, partsOrders)
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },

  async createPartsOrder ({ commit }, dto) {
    try {
      const order = await createPartsOrder(dto)

      commit(ADD_PARTS_ORDER, order)

      showSuccessMessage('Заказ создан')
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    }
  },

  async updatePartsOrder ({ commit }, partsOrder) {
    try {
      const order = await updatePartsOrder(partsOrder)

      commit(UPDATE_PARTS_ORDER, order)
      showSuccessMessage('Заказ обновлен')
    } catch (err) {
      showErrorMessage('Заказ не удалось обновить')
      return Promise.reject(err)
    }
  },

  async deletePartsOrder ({ commit }, orderId) {
    try {
      await deletePartsOrder(orderId)

      commit(DELETE_PARTS_ORDER, orderId)
      showSuccessMessage('Заказ удален')
    } catch (err) {
      showErrorMessage('Не удалось удалить заказ')
      return Promise.reject(err)
    }
  },

  async fetchPartsOrderById ({ commit }, partsOrderId) {
    try {
      return await fetchPartsOrderById(partsOrderId)
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    }
  },

  async acceptPartsOrder ({ commit }, partsOrderId) {
    try {
      const { success } = await acceptPartsOrder(partsOrderId)

      if (success) {
        commit(TOGGLE_PARTS_ORDER_STATUS, {
          status: PartsOrderStatusEnum.arrived,
          id: partsOrderId
        })
      }

      showSuccessMessage('Заказ принят')
    } catch (err) {
      showErrorMessage()
      return Promise.reject(err)
    }
  },

  async fetchWarehouse ({ commit }) {
    try {
      const warehouse = await fetchWarehouse()

      commit(SET_WAREHOUSE, warehouse)
    } catch (err) {
      showErrorMessage('Не удалось загрузить склад')
      return Promise.reject(err)
    }
  },

  async addToWarehouse ({ commit }, partDto) {
    try {
      const part = await addToWarehouse(partDto)

      commit(ADD_TO_WAREHOUSE, part)

      showSuccessMessage('Позиция создана')
    } catch (err) {
      showErrorMessage('Не удалось создать позицию склада')
      return Promise.reject(err)
    }
  },

  async updateWarehouse ({ commit }, { partDto, addPart = true }) {
    try {
      const part = await updateWarehouse(partDto)

      if (addPart) commit(ADD_TO_WAREHOUSE, part)
      if (!addPart) commit(UPDATE_WAREHOUSE, part)
      showSuccessMessage('Позиция обновлена')
    } catch (err) {
      showErrorMessage('Не удалось обновить позицию склада')
      return Promise.reject(err)
    }
  },

  async deleteWarehouse ({ commit }, partId) {
    try {
      await deleteWarehouse(partId)

      commit(DELETE_WAREHOUSE, partId)
      showSuccessMessage('Позицию склада удалена')
    } catch (err) {
      showErrorMessage('Не удалось удалить позицию склада')
      return Promise.reject(err)
    }
  },

  async fetchPartCategories ({ commit }) {
    try {
      const categories = await fetchPartCategories()

      commit(SET_PART_CATEGORIES, categories)
    } catch (err) {
      showErrorMessage('Не удалось загрузить категории')
      return Promise.reject(err)
    }
  },

  async createPartCategory ({ commit }, categoryName) {
    try {
      const { id } = await createPartCategory({ name: categoryName })

      const category = {
        id,
        name: categoryName
      }

      commit(ADD_PART_CATEGORY, category)

      showSuccessMessage('Категория создана')
    } catch (err) {
      showErrorMessage('Не удалось создать категорию')
      return Promise.reject(err)
    }
  },

  async deletePartCategory ({ commit }, categoryId) {
    try {
      await deletePartCategory(categoryId)

      commit(DELETE_PART_CATEGORY, categoryId)
      showSuccessMessage('Категория удалена')
    } catch (err) {
      showErrorMessage('Не удалось удалить категорию')
      return Promise.reject(err)
    }
  },
  async updatePartCategory ({ commit }, { id, dto }) {
    try {
      await updatePartCategory(id, { ...dto })
      commit(UPDATE_PART_CATEGORY, { id, dto })
      showSuccessMessage('Категория измененна')
    } catch (err) {
      showErrorMessage('Не удалось изменить категорию')
      return Promise.reject(err)
    }
  },
  async fetchUnitMeasure ({ commit }) {
    try {
      const unitMeasure = await fetchUnitMeasure()

      commit(SET_UNIT_MEASURE, unitMeasure)
    } catch (err) {
      showErrorMessage('Не удалось загрузить единицы измерения')
      return Promise.reject(err)
    }
  },

  async fetchParts ({ commit }) {
    try {
      commit(TOGGLE_LOADING, true)
      const parts = await fetchParts()

      commit(SET_PARTS, parts)
    } catch (err) {
      showErrorMessage('Не удалось загрузить запчасти')
      return Promise.reject(err)
    } finally {
      commit(TOGGLE_LOADING, false)
    }
  },

  async createPart ({ commit, getters }, partDto) {
    try {
      const part = await createPart(partDto)

      commit(ADD_PART, part)

      showSuccessMessage('Запчасть создана')
    } catch (err) {
      showErrorMessage('Не удалось создать запчасть')
      return Promise.reject(err)
    }
  },

  async updatePart ({ commit, getters }, partDto) {
    try {
      const part = await updatePart(partDto)

      commit(UPDATE_PART, part)

      showSuccessMessage('Запчасть обновлена')
    } catch (err) {
      showErrorMessage('Не удалось обновить запчасть')
      return Promise.reject(err)
    }
  },

  async deletePart ({ commit }, partId) {
    try {
      await deletePart(partId)

      commit(DELETE_PART, partId)

      showSuccessMessage('Запчасть удалена')
    } catch (err) {
      showErrorMessage('Не удалось удалить запчасть')
      return Promise.reject(err)
    }
  },

  async fetchDecommissionParts ({ commit }) {
    try {
      const parts = await fetchDecommissionParts()

      commit(SET_DECOMMISSION_PARTS, parts)
    } catch (err) {
      showErrorMessage('Не удалось загрузить списанные запчасти')
      return Promise.reject(err)
    }
  },

  async decommissionPart ({ commit }, partDto) {
    try {
      const response = await decommissionPart(partDto)
      showSuccessMessage('Запчасть списана')
      return response
    } catch (err) {
      showErrorMessage('Не удалось списать запчасть')
      return Promise.reject(err)
    }
  },

  setFilters ({ commit }, filters) {
    commit(SET_FILTERS, filters)
  }
}

const getters = {
  filtredPartsOrders (state) {
    const supplier = state.filters.supplier
    if (!supplier) return state.partsOrders
    return state.partsOrders.filter(e => e.supplier_id.includes(supplier))
  },

  filtredSuppliers (state) {
    const supplier = state.filters.supplier
    return state.suppliers.filter(e => e.id.includes(supplier))
  },
  filtredWarehouse (state) {
    const vendorCode = state.filters.vendorCode.toLowerCase()
    const part = state.filters.part
    return state.warehouse.filter(e => e.spare_part_vendor_code
      .toLowerCase()
      .includes(vendorCode)).filter(e => e.id.includes(part))
  },

  filtredDecommissionParts (state) {
    const vendorCode = state.filters.vendorCode.toLowerCase()
    const part = state.filters.part
    const warehouse = state.warehouse
    const getSparePartIdInPartId = (partID) => {
      if (!partID) return ''
      return warehouse.filter(el => el.id === partID)[0].spare_part_id || null
    }
    return state.decommissionParts.filter(e => e.vendor_code.toLowerCase().includes(vendorCode))
      .filter(e => e.spare_part_id.includes(getSparePartIdInPartId(part)))
  },

  filtredPartCategories (state) {
    const category = state.filters.category

    return state.partCategories.filter(e => e.id.includes(category))
  },

  filtredParts (state) {
    const vendorCode = state.filters.vendorCode.toLowerCase()
    const category = state.filters.category.toLowerCase()
    const part = state.filters.part
    const partPart = state.filters.partPart
    const warehouse = state.warehouse

    if (partPart) {
      return state.parts.filter(el => el.spare_part_id === partPart) || []
    }
    const getSparePartIdInPartId = (partID) => {
      if (!partID) return ''
      return warehouse.filter(el => el.id === partID)[0].spare_part_id || null
    }
    const getCategoriesAsString = (categories) => {
      return categories.map(e => e.id).join()
    }
    return state.parts
      .filter(e => e.vendor_code.toLowerCase().includes(vendorCode))
      .filter(e => getCategoriesAsString(e.categories).includes(category))
      .filter(e => e.spare_part_id.includes(getSparePartIdInPartId(part)))
  },

  getPartOrdersByStatus (state, getters) {
    return (orderStatus) => {
      return getters.filtredPartsOrders.filter(e => e.status === orderStatus)
    }
  },

  getPartsByCategory (state) {
    return (categoryId) => {
      return state.parts.filter(part => {
        return part.categories
          .map(category => category.id)
          .includes(categoryId)
      }) || []
    }
  },

  partCategoriesTranslate (state) {
    return state.partCategories.reduce((acc, e) => {
      acc[e.id] = e.name
      return acc
    }, {})
  },

  unitMeasureTranslate (state) {
    return state.unitMeasure.reduce((acc, e) => {
      acc[e.id] = e.name
      return acc
    }, {})
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
