import {
  getData,
  getDataV1,
  addData,
  changeData,
  removeData,
  saveTenantsFromYandex,
  getDriverById,
  getDriversShort,
  getDriverBalance,
  putDriverBalance,
  putDriverDeposit,
  getDriverDeposit,
  creteZeroBalance
} from '@/requests/tenantsInfo'
import { getTotalDepSumm, getDriverExel } from '@/requests/report'

import { Message } from 'element-ui'

const state = {
  tenantsInfoData: [],
  isLoading: true,
  filter: {
    driverIds: [],
    offset: 0,
    limit: 100,
    is_working: null,
    search: null
  },
  drivers_count: 0,
  currentRegionParkID: null,
  tenantNations: {
    blr: 'Белоруссия',
    kaz: 'Казахстан',
    rus: 'Россия',
    ukr: 'Украина'
  }
}

const getters = {
  getCandidates: (state) => {
    return state.tenantsInfoData.filter(cur => cur.category === 'candidate')
  },
  getTrial: (state) => {
    return state.tenantsInfoData.filter(cur => cur.category === 'trial')
  },
  getDrivers: (state) => {
    return state.tenantsInfoData.filter(cur => cur.category === 'driver')
  },
  getDriversByFilter: (state, getters) => {
    if (!state.filter.driverIds.length) return state.tenantsInfoData
    console.log(state.filter.driverIds)
    return state.tenantsInfoData.filter(val => state.filter.driverIds.some(v => val.id === v))
  },
  getSheduleDrivers: (state) => {
    return state.tenantsInfoData.filter(cur => cur.category === 'driver' || cur.category === 'trial')
  },
  GET_BY_ID: state => id => state.tenantsInfoData.find(cur => cur._id === id),
  getById: state => prop_id => state.tenantsInfoData.filter(item => item.id === prop_id),
  GREAT_NAME_DRIVER_BY_ID: (state) => prop_id => {
    if (!prop_id) return 'Водитель не выбран'
    return state.tenantsInfoData.filter(item => item.id === prop_id).map(driver => `${driver.first_name} ${driver.second_name || ''} ${driver.last_name}`)[0]
  }
}

const mutations = {
  SET_TENANT: (state, tenantsInfoData) => {
    state.tenantsInfoData = tenantsInfoData
  },
  SET_DRIVERS_COUNT: (state, count) => {
    state.drivers_count = count
  },
  REMOVE_TENANT: (state, id) => {
    state.tenantsInfoData = state.tenantsInfoData.filter(cur => cur.id !== id)
  },
  ADD_TENANT: (state, tenantInfoData) => {
    state.tenantsInfoData.push(tenantInfoData)
  },
  SET_DRIVER_IDS: (state, ids) => {
    state.filter.driverIds = ids
  },

  UPDATE_FILTERS: (state, data) => {
    state.filter = { ...state.filter, ...data }
  },
  TOOGLE_LOADING (state, value) {
    state.isLoading = value
  },
  UPDATE_DRIVER_BALANCE_BY_ID (state, value) {
    const index = state.tenantsInfoData.findIndex(x => x.id === value.params.driver_id)
    if (index === -1) return console.log('4to to ne tak => UPDATE_DRIVER_BALANCE_BY_ID', value)
    // state.tenantsInfoData[index] = { ...state.tenantsInfoData[index], driver_balance: String(value.changed_balance) }
    state.tenantsInfoData.splice(index, 1, { ...state.tenantsInfoData[index], driver_balance: value.data.at(-1)?.total_balance ?? 0 })
  },
  UPDATE_DRIVER_BALANCE_DEPOSIT_BY_ID (state, value) {
    const index = state.tenantsInfoData.findIndex(x => x.id === value.params.driver_id)
    if (index === -1) return console.log('4to to ne tak => UPDATE_DRIVER_BALANCE_BY_ID', value)
    // state.tenantsInfoData[index] = { ...state.tenantsInfoData[index], driver_balance: String(value.changed_balance) }
    state.tenantsInfoData.splice(index, 1, { ...state.tenantsInfoData[index], driver_deposit: value.data.at(-1)?.total_balance ?? 0 })
  },
  UPDATE_DRIVER_BY_ID (state, item) {
    state.tenantsInfoData.filter(driver => driver.id === item.id)
      .map(mapElement => {
        mapElement.detached_park = item.detached_park
        mapElement.count_detached_changes_left = item.count_detached_changes_left
        mapElement.count_detached_changes = item.count_detached_changes
        return {
          ...mapElement
        }
      })
    // const index = state.tenantsInfoData.findIndex(e => e.id === item.id)
    // state.tenantsInfoData.splice(index, 1, item)
  },
  SET_CURRENT_REGION_PARK_ID: (state, value) => {
    state.currentRegionParkID = value
  }
  // CHANGE_TENANT: (state, tenantInfoData) => {
  //   state.tenantsInfoData.forEach(cur => {
  //     if (cur.id === tenantInfoData.id) {
  //       cur.category = tenantInfoData.category
  //       cur.first_name = tenantInfoData.first_name
  //       cur.last_name = tenantInfoData.last_name
  //       cur.middle_name = tenantInfoData.middle_name
  //       cur.schedule = tenantInfoData.schedule
  //     }
  //   })
  // }
  // CHANGE_TENANT: (state, driverDto) => {
  //   state.tenantsInfoData = [
  //     state.tenantsInfoData.filter(t => t.id !== driverDto.id),
  //   ]
  // }
}

const actions = {
  setCurrentRegionParkID ({ commit }, region_park_id) {
    commit('SET_CURRENT_REGION_PARK_ID', region_park_id)
  },
  fetchTenantsInfo ({ commit, state }) {
    commit('TOOGLE_LOADING', true)

    return getData({ region_park: state.currentRegionParkID })
      .then(tenantsInfoData => {
        commit('SET_TENANT', tenantsInfoData)

        commit('TOOGLE_LOADING', false)
        return tenantsInfoData
      })
      .catch((error) => {
        return error
      })
  },
  fetchTenantsInfoV1 ({ commit, state }) {
    commit('TOOGLE_LOADING', true)

    return getDataV1({ region_park_id: state.currentRegionParkID, ...state.filter })
      .then(tenantsInfoData => {
        commit('SET_TENANT', tenantsInfoData.drivers)
        commit('SET_DRIVERS_COUNT', tenantsInfoData?.drivers_count ?? 0)
        commit('TOOGLE_LOADING', false)
        return tenantsInfoData
      })
      .catch((error) => {
        return error
      })
  },
  fetchTenantsShortInfo ({ commit, state }) {
    commit('TOOGLE_LOADING', true)
    return getDriversShort({ region_park: state.currentRegionParkID })
      .then(tenantsInfoData => {
        commit('SET_TENANT', tenantsInfoData)

        commit('TOOGLE_LOADING', false)
        return tenantsInfoData
      })
      .catch((error) => {
        return error
      })
  },
  getDriverBalance ({ commit, state }, params) {
    return getDriverBalance(params)
      .then(tenantsInfoData => {
        if (tenantsInfoData?.length >= 1) {
          commit('UPDATE_DRIVER_BALANCE_BY_ID', { data: tenantsInfoData, params })
        }
        return tenantsInfoData.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
      })
      .catch((error) => {
        return error
      })
  },
  putDriverBalance ({ commit, state }, data) {
    return putDriverBalance(data)
      .then(tenantsInfoData => {
        return tenantsInfoData
      })
      .catch((error) => {
        return error
      })
  },
  putDriverDeposit ({ commit, state }, data) {
    return putDriverDeposit(data)
      .then(tenantsInfoData => {
        return tenantsInfoData
      })
      .catch((error) => {
        return error
      })
  },
  getDriverDeposit ({ commit, state }, params) {
    return getDriverDeposit(params)
      .then(tenantsInfoData => {
        if (tenantsInfoData?.length >= 1) {
          commit('UPDATE_DRIVER_BALANCE_DEPOSIT_BY_ID', { data: tenantsInfoData, params })
        }
        return tenantsInfoData.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
      })
      .catch((error) => {
        return error
      })
  },
  addTenant ({ commit }, data) {
    return new Promise((resolve, reject) => {
      addData(data).then(res => {
        commit('ADD_TENANT', res)
        Message({
          message: 'Водитель был добавлен',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  async editDriver ({
    commit,
    dispatch
  }, editDriverDto) {
    try {
      const driver = await changeData(editDriverDto.id, editDriverDto)
      console.log(driver)
      await dispatch('fetchTenantsInfoV1')
      Message({
        message: 'Данные о водителе были изменены',
        type: 'success',
        duration: 5 * 1000
      })
    } catch (e) {
      Message({
        message: 'Что-то пошло не так',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(e)
    }
  },
  setFilterDriverIds ({ commit }, ids) {
    commit('SET_DRIVER_IDS', ids)
  },
  removeTenant ({ commit }, id) {
    return new Promise((resolve, reject) => {
      removeData(id).then(res => {
        commit('REMOVE_TENANT', id)
        Message({
          message: 'Кандидат был удален',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(res)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  async generateTenantsFromYandexData () {
    try {
      return await saveTenantsFromYandex()
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getDriverById ({ commit }, id) {
    try {
      return await getDriverById(id)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async getDepSumm ({ state }) {
    try {
      return await getTotalDepSumm({ region_park_id: state.currentRegionParkID })
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async getDriverExel ({ state }, dta) {
    try {
      return await getDriverExel(dta)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async creteZeroBalance ({ dispatch }) {
    try {
      const abc = await creteZeroBalance()
      await dispatch('fetchTenantsInfoV1')
      Message({
        message: 'Успешно!',
        type: 'success',
        duration: 5 * 1000
      })
      return abc
    } catch (err) {
      return Promise.reject(err)
    }
  },
  setFilters ({ commit }, filters) {
    commit('SET_FILTERS', filters)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
