import request from '../utils/request'

const URL = 'taxi/report/'

export const getReportInRange = (data) => request({
  method: 'post',
  url: URL,
  data
})
export const getReportConsolidatedInRange = (data) => request({
  method: 'post',
  url: 'report/park_monthly_report/',
  data
})

export const getTotalDepSumm = (data) => request({
  method: 'post',
  url: 'report/deposit_total/',
  data
})
export const getDriverExel = (data) => request({
  method: 'post',
  url: 'report/driver_report_exel/',
  data
})
