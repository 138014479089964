import request from '../utils/request'
const min_max_retentions = '/settings/retentions/min_max_sum/'
export const getData = () => request({
  method: 'get',
  url: '/settings'
})

export const addData = (data) => {
  return request({
    method: 'post',
    url: '/settings/',
    data: data
  })
}

export const getParksData = () => request({
  method: 'get',
  url: '/taxi/parks'
})

export const removeParkData = (id) => request({
  method: 'delete',
  url: `/taxi/parks/${id}`
})

export const selectParkData = (id) => request({
  method: 'post',
  url: `/taxi/parks/${id}/select`
})

export const addParkData = (data) => request({
  method: 'post',
  url: '/taxi/parks',
  data: data
})
// MinMax
export const getRetentionsMinMax = (id) => request({
  method: 'get',
  url: `/settings/park_settings/min_max_sum/${id}/`
})
export const putRetentionsMinMax = (id, data) => request({
  method: 'put',
  url: `/settings/park_settings/min_max_sum/${id}/`,
  data
})
export const deleteRetentionsMinMax = (data) => request({
  method: 'delete',
  url: min_max_retentions,
  data
})

export const getRetentionsMinMaxGlobal = () => request({
  method: 'get',
  url: min_max_retentions
})
export const putRetentionsMinMaxGlobal = (data) => request({
  method: 'put',
  url: min_max_retentions,
  data
})
//
// Settings
export const fetchParkSettingsFormulas = (id) => request({
  method: 'get',
  url: `/settings/park_settings/formulas/${id}/`
})
export const updateParkSettingsFormulas = (id, data) => request({
  method: 'put',
  url: `/settings/park_settings/formulas/${id}/`,
  data
})
export const updateParkSettingsMinMaxSum = (id, data) => request({
  method: 'put',
  url: `/settings/park_settings/min_max_sum/${id}/`,
  data
})
export const fetchParkSettingsMinMaxSum = (id) => request({
  method: 'get',
  url: `/settings/park_settings/min_max_sum/${id}/`
})
export const createParkSettings = (data) => request({
  method: 'post',
  url: '/settings/park_settings/',
  data
})
export const fetchParkSettings = (params) => request({
  method: 'get',
  url: '/settings/park_settings/',
  params
})
export const updateParkSettings = (id, data) => request({
  method: 'put',
  url: `/settings/park_settings/${id}/`,
  data
})
export const deleteParkSettings = (id) => request({
  method: 'delete',
  url: `/settings/park_settings/${id}/`
})
export const setMainPark = (params) => request({
  method: 'post',
  url: 'taxi/parks/2/set_settings',
  params
})

export const getDriverWorkRules = (params) => request({
  method: 'get',
  url: 'taxi/driver_work_rules/',
  params
})
export const updateDriverWorkRulesById = (id, data) => request({
  method: 'patch',
  url: `taxi/driver_work_rules/${id}/`,
  data
})
