import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from '@/store/modules/user'
import dashboard from '@/store/modules/dashboard'
import tenantsIncome from '@/store/modules/tenantsIncome'
import schedule from '@/store/modules/schedule/index'
import driversStatistic from '@/store/modules/driversStatistic'
import parkStatistic from '@/store/modules/parkStatistic'
import tenantsInfo from '@/store/modules/tenantsInfo'
import carPark from '@/store/modules/carPark'
import report from '@/store/modules/report'
import techInspection from '@/store/modules/techInspection'
import stock from '@/store/modules/stock'
import admins from '@/store/modules/admins'
import roles from '@/store/modules/roles'
import carsLoad from '@/store/modules/carsLoad'
import carsConditions from '@/store/modules/carsConditions'
import settings from '@/store/modules/settings'
import maintenances from '@/store/modules/maintenances'
import contracts from '@/store/modules/contracts'
import investors from '@/store/modules/investors'
// import driversIncome from '@/store/modules/driversIncome'
import dialogs from '@/store/modules/dialogs'
// import actualDriversIncome from '@/store/modules/DriversIncome/ActualIncome'
import SingleTenantDetails from '@/store/modules/DriversIncome/SingleTenantDetails'
import OpenTransactions from '@/store/modules/OpenTransactions'
import IncomeHistory from '@/store/modules/DriversIncome/IncomeHistory'
import regionPark from '@/store/modules/regionPark'
import parts from '@/store/modules/parts'
import fines from '@/store/modules/fines'
import consolidatedReport from '@/store/modules/consolidatedReport'
import сonfigurator from '@/store/modules/сonfigurator'
import templates from '@/store/modules/templates'
import debtorsByBalances from '@/store/modules/debtorsByBalances'
Vue.use(Vuex)

const modules = {
  user,
  dashboard,
  tenantsIncome,
  schedule,
  driversStatistic,
  parkStatistic,
  tenantsInfo,
  carPark,
  report,
  techInspection,
  stock,
  admins,
  roles,
  carsLoad,
  carsConditions,
  settings,
  maintenances,
  OpenTransactions,
  consolidatedReport,
  // driversIncome,
  dialogs,
  regionPark,
  contracts,
  investors,
  // actualDriversIncome,
  SingleTenantDetails,
  IncomeHistory,
  parts,
  fines,
  сonfigurator,
  templates,
  debtorsByBalances
}

const userEmailDataState = createPersistedState({
  paths: ['user.userEmailData', 'consolidatedReport.tableView', 'consolidatedReport.isShowHideColumns', 'schedule.ignoringDriverWorking']
})

export default new Vuex.Store({
  modules,
  plugins: [userEmailDataState]
})
