import request from '../utils/request'

const URL = 'taxi/contracts/'

export const getContracts = () => request({
  method: 'get',
  url: URL
})

export const getContractsPart = (params) => request({
  method: 'get',
  url: URL,
  params
})

export const createContracts = (data) => request({
  method: 'post',
  url: 'taxi/contract_create/',
  data
})
