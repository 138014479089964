import { Message } from 'element-ui'
import { getData, addData, changeData, removeData, getLogs } from '@/requests/admins'

const state = {
  data: [],
  logs: [],
  logsLoading: true
}

const mutations = {
  SET: (state, data) => {
    state.data = data
  },
  REMOVE: (state, id) => {
    state.data = state.data.filter(cur => cur.id !== id)
  },
  ADD: (state, data) => {
    state.data.push(data)
  },
  CHANGE: (state, data) => {
    state.data.forEach(cur => {
      if (cur.id === data.id) {
        cur.name = data.name
        cur.email = data.email
        cur.phone = data.phone
        cur.role = data.role
      }
    })
  },
  SET_LOGS: (state, logs) => {
    state.logs = logs
  },
  START_LOGS_LOADING: (state) => {
    state.logsLoading = true
  },
  STOP_LOGS_LOADING: (state) => {
    state.logsLoading = false
  }
}

const actions = {

  async fetchLogs ({ commit }, queryDto) {
    try {
      commit('START_LOGS_LOADING')
      const logs = await getLogs(queryDto)
      commit('SET_LOGS', logs)
      commit('STOP_LOGS_LOADING')
    } catch (e) {
      commit('STOP_LOGS_LOADING')
      return Promise.reject(e)
    }
  },

  fetch ({ commit }) {
    return getData()
      .then(data => {
        commit('SET', data)
        return data
      })
      .catch((error) => {
        return error
      })
  },

  remove ({ commit }, id) {
    return new Promise((resolve, reject) => {
      removeData(id).then(() => {
        commit('REMOVE', id)
        Message({
          message: 'Администратор был удален',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(id)
        return id
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },

  add ({ commit }, data) {
    return new Promise((resolve, reject) => {
      addData(data).then(res => {
        commit('ADD', res)
        Message({
          message: 'Администратор был добавлен',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  change ({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      changeData(id, data).then(res => {
        commit('CHANGE', res)
        Message({
          message: 'Информация об администраторе была изменена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
