import request from '../utils/request'

export const getData = () => request({
  method: 'get',
  url: '/users/'
})

export const removeData = (id) => {
  return request({
    method: 'delete',
    url: `/users/${id}`
  })
}

export const addData = (data) => {
  return request({
    method: 'post',
    url: '/users/',
    data: data
  })
}

export const changeData = (id, data) => {
  return request({
    method: 'patch',
    url: `/users/${id}`,
    data: data
  })
}

export const getLogs = (queryDto) => request({
  method: 'get',
  url: `/users/${queryDto.id}/logs`,
  params: queryDto
})
