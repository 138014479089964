import { getOrdersData, addOrderData, changeOrderData } from '@/requests/orders'
import { getStockData, addStockData, changeStockData } from '@/requests/stock'
import {
  getSuppliersData,
  addSupplierData,
  changeSupplierData,
  removeSupplierData
} from '@/requests/suppliers'
import { Message } from 'element-ui'

const state = {
  ordersData: [],
  stockData: [],
  suppliersData: [],
  filter: {
    categoryName: '',
    supplierName: '',
    vendorCode: ''
  }
}

const mutations = {
  SET_ORDERS: (state, data) => {
    state.ordersData = data
  },
  ADD_ORDER: (state, data) => {
    state.ordersData.push(data)
  },
  CHANGE_ORDER: (state, ordersData) => {
    state.ordersData.forEach(cur => {
      if (cur.id === ordersData.id) {
        cur.price = ordersData.price
        cur.quantity = ordersData.quantity
        cur.status = ordersData.status
        cur.date = ordersData.date
        cur.arrival_date = ordersData.arrival_date
        cur.part = ordersData.part
      }
    })
  },
  SET_STOCK: (state, data) => {
    state.stockData = data
  },
  ADD_STOCK: (state, data) => {
    state.stockData.push(data)
  },
  CHANGE_STOCK: (state, data) => {
    state.stockData.forEach(cur => {
      if (cur.id === data.id) {
        cur.vendor_code = data.vendor_code
        cur.category = data.category
        cur.price = data.price
        cur.quantity = data.quantity
        cur.supplier = data.supplier
      }
    })
  },
  SET_SUPPLIERS: (state, data) => {
    state.suppliersData = data
  },
  REMOVE_SUPPLIER: (state, id) => {
    state.suppliersData = state.suppliersData.filter(cur => cur.id !== id)
  },
  ADD_SUPPLIER: (state, data) => {
    state.suppliersData.push(data)
  },
  CHANGE_SUPPLIER: (state, data) => {
    state.suppliersData.forEach(cur => {
      if (cur.id === data.id) {
        cur.name = data.name
        cur.city = data.city
        cur.inn = data.inn
        cur.contact = data.contact
        cur.comment = data.comment
      }
    })
  },
  FILTER: (state, data) => {
    state.filter = {
      categoryName: data.categoryName,
      supplierName: data.supplierName,
      vendorCode: data.vendorCode
    }
  }
}

const helpers = {
  searchOrder (supplierName, categoryName, vendorCode) {
    return (currentObject) => {
      return currentObject.part.supplier.name.includes(supplierName) &&
        currentObject.part.category.includes(categoryName) &&
        currentObject.part.vendor_code.includes(vendorCode)
    }
  },
  searchStock (supplierName, categoryName, vendorCode) {
    return (currentObject) => {
      return currentObject.supplier.name.includes(supplierName) && currentObject.category.includes(categoryName) && currentObject.vendor_code.includes(vendorCode)
    }
  },
  searchSupplier (supplierName) {
    return (currentObject) => {
      return currentObject.name.includes(supplierName)
    }
  }
}

const getters = {
  filteredOrders: (state) => {
    return state.ordersData.filter(helpers.searchOrder(state.filter.supplierName, state.filter.categoryName, state.filter.vendorCode))
  },
  filteredSuppliers: (state) => {
    return state.suppliersData.filter(helpers.searchSupplier(state.filter.supplierName, state.filter.categoryName, state.filter.vendorCode))
  },
  filteredStock: (state) => {
    return state.stockData.filter(helpers.searchStock(state.filter.supplierName, state.filter.categoryName, state.filter.vendorCode))
  }
}

const actions = {

  fetch ({ commit }) {
    getOrdersData()
      .then(data => {
        commit('SET_ORDERS', data)
        return data
      })
      .catch((error) => {
        return error
      })

    getStockData()
      .then(data => {
        commit('SET_STOCK', data)
        return data
      })
      .catch((error) => {
        return error
      })

    getSuppliersData()
      .then(data => {
        commit('SET_SUPPLIERS', data)
        return data
      })
      .catch((error) => {
        return error
      })
  },
  addOrder ({ commit }, data) {
    return new Promise((resolve, reject) => {
      addOrderData(data).then(res => {
        commit('ADD_ORDER', res)
        Message({
          message: 'Заказ был добавлен',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  changeOrder ({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      changeOrderData(id, data).then(res => {
        commit('CHANGE_ORDER', res)
        Message({
          message: 'Информация о заказе была изменена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  addStock ({ commit }, data) {
    return new Promise((resolve, reject) => {
      addStockData(data).then(res => {
        commit('ADD_STOCK', res)
        Message({
          message: 'Запчасть была добавлена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(res)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  changeStock ({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      changeStockData(id, data).then(res => {
        commit('CHANGE_STOCK', res)
        Message({
          message: 'Информация о запчастях была изменена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  removeSupplier ({ commit }, id) {
    return new Promise((resolve, reject) => {
      removeSupplierData(id).then(() => {
        commit('REMOVE_SUPPLIER', id)
        Message({
          message: 'Поставщик был удален',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(id)
        return id
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  addSupplier ({ commit }, data) {
    return new Promise((resolve, reject) => {
      addSupplierData(data).then(res => {
        commit('ADD_SUPPLIER', res)
        Message({
          message: 'Поставщик был добавлен',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  changeSupplier ({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      changeSupplierData(id, data).then(res => {
        commit('CHANGE_SUPPLIER', res)
        Message({
          message: 'Информация о поставщике была изменена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  filter ({ commit }, data) {
    commit('FILTER', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
