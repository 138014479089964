import request from '../utils/request'

export const getSuppliersData = () => request({
  method: 'get',
  url: '/stock/suppliers'
})

export const removeSupplierData = (id) => {
  return request({
    method: 'delete',
    url: `/stock/suppliers/${id}`
  })
}

export const addSupplierData = (data) => {
  return request({
    method: 'post',
    url: '/stock/suppliers',
    data: data
  })
}

export const changeSupplierData = (id, data) => {
  return request({
    method: 'patch',
    url: `/stock/suppliers/${id}`,
    data: data
  })
}
