const TOGGLE_CHOOSE_DRIVER = 'TOGGLE_CHOOSE_DRIVER'
const TOGGLE_ADD_TENANT = 'TOGGLE_ADD_TENANT'
const TOGGLE_ADD_CANDIDATE = 'TOGGLE_ADD_CANDIDATE'
const TOGGLE_CAR_CONDITIONS = 'TOGGLE_CAR_CONDITIONS'
const TOGGLE_ADD_MAINTENANCE = 'TOGGLE_ADD_MAINTENANCE'
const TOGGLE_ADD_CAR_CONDITION = 'TOGGLE_ADD_CAR_CONDITION'
const TOGGLE_ACCEPT_MAINTENANCE = 'TOGGLE_ACCEPT_MAINTENANCE'
const TOGGLE_CHANGE_MAINTENANCE = 'TOGGLE_CHANGE_MAINTENANCE'
const TOGGLE_ADD_ADMIN = 'TOGGLE_ADD_ADMIN'
const TOGGLE_CHANGE_ADMIN = 'TOGGLE_CHANGE_ADMIN'
const TOGGLE_CRM_PARAMS = 'TOGGLE_CRM_PARAMS'
const TOGGLE_ADD_ROLE = 'TOGGLE_ADD_ROLE'
const TOGGLE_CHANGE_ROLE = 'TOGGLE_CHANGE_ROLE'
const TOGGLE_ADD_ORDER = 'TOGGLE_ADD_ORDER'
const TOGGLE_CHANGE_ORDER = 'TOGGLE_CHANGE_ORDER'
const TOGGLE_ACCEPT_ORDER = 'TOGGLE_ACCEPT_ORDER'
const TOGGLE_ADD_SUPPLIER = 'TOGGLE_ADD_SUPPLIER'
const TOGGLE_CHANGE_SUPPLIER = 'TOGGLE_CHANGE_SUPPLIER'
const TOGGLE_ADD_PARK = 'TOGGLE_ADD_PARK'
const TOGGLE_INFO_PARK = 'TOGGLE_INFO_PARK'
const TOGGLE_CHANGE_RATING = 'TOGGLE_CHANGE_RATING'
const TOGGLE_ADD_PERCENT = 'TOGGLE_ADD_PERCENT'
const TOGGLE_CHANGE_TRANSACTION = 'TOGGLE_CHANGE_TRANSACTION'

const state = {
  chooseDriverDialog: false,
  isAddTenantDialog: false,
  addCandidateDialog: false,
  carConditionsDialog: false,
  addCarConditionDialog: false,
  addMaintenanceDialog: false,
  acceptMaintenanceDialog: false,
  changeMaintenanceDialog: false,
  addAdminDialog: false,
  changeAdminDialog: false,
  crmParamsDialog: false,
  addRoleDialog: false,
  changeRoleDialog: false,
  addOrderDialog: false,
  changeOrderDialog: false,
  acceptOrderDialog: false,
  addSupplierDialog: false,
  changeSupplierDialog: false,
  addParkDialog: false,
  parkInfoDialog: false,
  changeRatingDialog: false,
  addPercentDialog: false,
  changeTransactionDialog: false
}

const mutations = {
  [TOGGLE_CHOOSE_DRIVER]: (state) => {
    state.chooseDriverDialog = !state.chooseDriverDialog
  },
  [TOGGLE_ADD_TENANT]: (state) => {
    state.isAddTenantDialog = !state.isAddTenantDialog
  },
  [TOGGLE_ADD_CANDIDATE]: (state) => {
    state.addCandidateDialog = !state.addCandidateDialog
  },
  [TOGGLE_CAR_CONDITIONS]: (state) => {
    state.carConditionsDialog = !state.carConditionsDialog
  },
  [TOGGLE_ADD_CAR_CONDITION]: (state) => {
    state.addCarConditionDialog = !state.addCarConditionDialog
  },
  [TOGGLE_ADD_MAINTENANCE]: (state) => {
    state.addMaintenanceDialog = !state.addMaintenanceDialog
  },
  [TOGGLE_ACCEPT_MAINTENANCE]: (state) => {
    state.acceptMaintenanceDialog = !state.acceptMaintenanceDialog
  },
  [TOGGLE_CHANGE_MAINTENANCE]: (state) => {
    state.changeMaintenanceDialog = !state.changeMaintenanceDialog
  },
  [TOGGLE_ADD_ADMIN]: (state) => {
    state.addAdminDialog = !state.addAdminDialog
  },
  [TOGGLE_CHANGE_ADMIN]: (state) => {
    state.changeAdminDialog = !state.changeAdminDialog
  },
  [TOGGLE_CRM_PARAMS]: (state) => {
    state.crmParamsDialog = !state.crmParamsDialog
  },
  [TOGGLE_ADD_ROLE]: (state) => {
    state.addRoleDialog = !state.addRoleDialog
  },
  [TOGGLE_CHANGE_ROLE]: (state) => {
    state.changeRoleDialog = !state.changeRoleDialog
  },
  [TOGGLE_ADD_ORDER]: (state) => {
    state.addOrderDialog = !state.addOrderDialog
  },
  [TOGGLE_CHANGE_ORDER]: (state) => {
    state.changeOrderDialog = !state.changeOrderDialog
  },
  [TOGGLE_ACCEPT_ORDER]: (state) => {
    state.acceptOrderDialog = !state.acceptOrderDialog
  },
  [TOGGLE_ADD_SUPPLIER]: (state) => {
    state.addSupplierDialog = !state.addSupplierDialog
  },
  [TOGGLE_CHANGE_SUPPLIER]: (state) => {
    state.changeSupplierDialog = !state.changeSupplierDialog
  },
  [TOGGLE_ADD_PARK]: (state) => {
    state.addParkDialog = !state.addParkDialog
  },
  [TOGGLE_INFO_PARK]: (state) => {
    state.parkInfoDialog = !state.parkInfoDialog
  },
  [TOGGLE_CHANGE_RATING]: (state) => {
    state.changeRatingDialog = !state.changeRatingDialog
  },
  [TOGGLE_ADD_PERCENT]: (state) => {
    state.addPercentDialog = !state.addPercentDialog
  },
  [TOGGLE_CHANGE_TRANSACTION]: (state) => {
    state.changeTransactionDialog = !state.changeTransactionDialog
  }
}

const actions = {
  toggleChooseDriver ({ commit }) {
    commit(TOGGLE_CHOOSE_DRIVER)
  },
  toggleAddTenant ({ commit }) {
    commit(TOGGLE_ADD_TENANT)
  },
  toggleAddCandidate ({ commit }) {
    commit(TOGGLE_ADD_CANDIDATE)
  },
  toggleCarConditions ({ commit }) {
    commit(TOGGLE_CAR_CONDITIONS)
  },
  toggleAddCarCondition ({ commit }) {
    commit(TOGGLE_ADD_CAR_CONDITION)
  },
  toggleAddMaintenance ({ commit }) {
    commit(TOGGLE_ADD_MAINTENANCE)
  },
  toggleAcceptMaintenance ({ commit }) {
    commit(TOGGLE_ACCEPT_MAINTENANCE)
  },
  toggleChangeMaintenance ({ commit }) {
    commit(TOGGLE_CHANGE_MAINTENANCE)
  },
  toggleAddAdmin ({ commit }) {
    commit(TOGGLE_ADD_ADMIN)
  },
  toggleChangeAdmin ({ commit }) {
    commit(TOGGLE_CHANGE_ADMIN)
  },
  toggleCrmParams ({ commit }) {
    commit(TOGGLE_CRM_PARAMS)
  },
  toggleAddRole ({ commit }) {
    commit(TOGGLE_ADD_ROLE)
  },
  toggleChangeRole ({ commit }) {
    commit(TOGGLE_CHANGE_ROLE)
  },
  toggleAddOrder ({ commit }) {
    commit(TOGGLE_ADD_ORDER)
  },
  toggleChangeOrder ({ commit }) {
    commit(TOGGLE_CHANGE_ORDER)
  },
  toggleAcceptOrder ({ commit }) {
    commit(TOGGLE_ACCEPT_ORDER)
  },
  toggleAddSupplier ({ commit }) {
    commit(TOGGLE_ADD_SUPPLIER)
  },
  toggleChangeSupplier ({ commit }) {
    commit(TOGGLE_CHANGE_SUPPLIER)
  },
  toggleAddPark ({ commit }) {
    commit(TOGGLE_ADD_PARK)
  },
  toggleParkInfo ({ commit }) {
    commit(TOGGLE_INFO_PARK)
  },
  toggleChangeRating ({ commit }) {
    commit(TOGGLE_CHANGE_RATING)
  },
  toggleAddPercent ({ commit }) {
    commit(TOGGLE_ADD_PERCENT)
  },
  toggleChangeTransaction ({ commit }) {
    commit(TOGGLE_CHANGE_TRANSACTION)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
