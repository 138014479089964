import request from '../utils/request'

export const getConsolidatedCars = (startDate, endDate) => request({
  method: 'get',
  url: `/taxi/statistics/cars_result?start_date=${startDate}&end_date=${endDate}`
})

export const getParkReportExel = (dto) => {
  return request({
    method: 'post',
    url: '/taxi/statistics/park_report_exel',
    timeout: 300000,
    data: {
      start_date: dto.fromDate,
      end_date: dto.toDate,
      separator: dto.separator
    }
  })
}
