import request from '../utils/request'
const BASE_URL = 'report/dashboard'
export const fetchDashboardFinance = (params) => request({
  method: 'get',
  url: `${BASE_URL}/finance`,
  params
})
export const fetchDashboardCars = (params) => request({
  method: 'get',
  url: `${BASE_URL}/car_load`,
  params
})

export const fetchDashboardTenantsIncome = (params) => request({
  method: 'get',
  url: `${BASE_URL}/tenants_income`,
  params
})
export const fetchDashboardFines = (params) => request({
  method: 'get',
  url: `${BASE_URL}/fines`,
  params
})
export const fetchDashboardDebts = (params) => request({
  method: 'get',
  url: `${BASE_URL}/debts`,
  params
})
export const fetchDashboardMaintenance = (params) => request({
  method: 'get',
  url: `${BASE_URL}/maintenance`,
  params
})
export const fetchDashboardMaintenanceExpense = (params) => request({
  method: 'get',
  url: `${BASE_URL}/maintenance_expense`,
  params
})
