import request from '../utils/request'

export const getData = () => request({
  method: 'get',
  url: '/users/roles'
})

export const removeData = (id) => {
  return request({
    method: 'delete',
    url: `/users/roles/${id}`
  })
}

export const addData = (data) => {
  return request({
    method: 'post',
    url: '/users/roles',
    data: data
  })
}

export const changeData = (id, data) => {
  return request({
    method: 'patch',
    url: `/users/roles/${id}`,
    data: data
  })
}
