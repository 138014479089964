
import { getData, addData, changeData, removeData } from '@/requests/carsConditions'
import { Message } from 'element-ui'

const state = {
  data: []
}

const mutations = {
  SET: (state, data) => {
    state.data = data
  },
  REMOVE: (state, id) => {
    state.data = state.data.filter(cur => cur.id !== id)
  },
  ADD: (state, data) => {
    state.data.push(data)
  },
  CHANGE: (state, data) => {
    state.data.forEach(cur => {
      if (cur.id === data.id) {
        cur.id = data.id
        cur.car = data.car
        cur.date = data.date
        cur.description = data.description
        cur.mileage = data.mileage
      }
    })
  }
}

const actions = {

  fetch ({ commit }, id) {
    return getData(id)
      .then(data => {
        commit('SET', data)
        return data
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  add ({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      addData(id, data).then(res => {
        commit('ADD', res)
        Message({
          message: 'История состояний была дополнена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  changeCarCondition ({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      changeData(id, data).then(res => {
        commit('CHANGE', res)
        Message({
          message: 'История состояний была изменена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(res)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  removeCarCondition ({ commit }, id) {
    return new Promise((resolve, reject) => {
      removeData(id).then(res => {
        commit('REMOVE', id)
        Message({
          message: 'Состояние было стёрто из истории',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(res)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
