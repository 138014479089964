import { signIn } from '@/requests/user'
import { removeToken, setToken, getToken } from '@/utils/auth'

import router from '@/router'

const state = {
  token: getToken(),
  refreshToken: '',
  userEmailData: ''
}

const mutations = {
  SET_TOKEN: (state, { loginData, userData }) => {
    state.token = `Bearer ${loginData.access}`
    state.refreshToken = `Bearer ${loginData.refresh}`
    state.userEmailData = userData.email
  },
  SET_EMPTY_TOKEN: (state) => {
    state.token = ''
    state.refreshToken = ''
  }
}

const getters = {
  GET_TOKEN: (state) => {
    return state.token
  },
  GET_REFRESH_TOKEN: (state) => {
    return state.token
  }
}

const actions = {
  login ({ commit }, userData) {
    return new Promise((resolve, reject) => {
      signIn(userData).then(loginData => {
        console.log(loginData)
        commit('SET_TOKEN', { loginData, userData })
        setToken(`Bearer ${loginData.access}`)
        resolve(userData)
        router.push({ path: '/' })
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  logout ({ commit }) {
    return new Promise(resolve => {
      commit('SET_EMPTY_TOKEN')
      removeToken()
      resolve()
      router.push({ path: '/login' })
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
