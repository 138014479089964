import request from '../utils/request'
const baseUrl = '/settings/tenants_income/formula/'

export const getFormuls = () => request({
  method: 'get',
  url: baseUrl
})

export const updateFormula = (data) => {
  return request({
    method: 'put',
    url: baseUrl,
    data
  })
}
export const formulaCheck = (data, params) => {
  return request({
    method: 'post',
    url: 'v1/tenants_income/configurator/check_formula/',
    data,
    params
  })
}
