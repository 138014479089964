import Picker from '../picker'
import DatePanel from '../panel/date'

export default {
  mixins: [Picker],

  name: 'ElDatePickerCustom',

  props: {
    type: {
      type: String,
      default: 'date'
    },
    timeArrowControl: Boolean,
    markedDates: {
      type: Array,
      default: () => []
    }
  },

  created () {
    this.panel = DatePanel
  }
}
