
import { getMaintenancesData, addMaintenanceData, acceptMaintenanceData, changeMaintenanceData } from '@/requests/maintenances'
import { Message } from 'element-ui'
const lodash = require('lodash')

const state = {
  maintenancesData: [],
  filter: {
    number: '',
    callsign: '',
    type: ''
  }
}

const mutations = {
  SET_MAINTENANCES: (state, data) => {
    state.maintenancesData = data
  },
  ADD_MAINTENANCE: (state, data) => {
    state.maintenancesData.push(data)
  },
  FILTER: (state, data) => {
    state.filter.number = data.number
    state.filter.callsign = data.callsign
    state.filter.type = data.type
  },
  REMOVE_MAINTENANCE: (state, id) => {
    state.maintenancesData = state.maintenancesData.filter(cur => cur.id !== id)
  }
}

const getters = {
  filteredMaintenances: (state) => {
    let preSortmaintenancesData = state.maintenancesData

    preSortmaintenancesData = lodash.sortBy(preSortmaintenancesData, object => object.is_archive)

    preSortmaintenancesData = lodash.uniqBy(preSortmaintenancesData, (object) => object.car.id)

    if (state.filter.type !== '') preSortmaintenancesData = preSortmaintenancesData.filter(cur => cur.type === state.filter.type)

    preSortmaintenancesData = preSortmaintenancesData.filter(cur => {
      return cur.car.callsign.includes(state.filter.callsign) && cur.car.number.includes(state.filter.number)
    })

    return preSortmaintenancesData
  }
}

// const helpers = {
// searchMaintenance(supplierName,categoryName,vendorCode) {
//   return (currentObject) => {
//     return currentObject.part.supplier.name.includes(supplierName) && currentObject.part.category.includes(categoryName) && currentObject.part.vendor_code.includes(vendorCode);
//   }
// }
// }

const actions = {

  fetchMaintenances ({ commit }) {
    getMaintenancesData()
      .then(data => {
        commit('SET_MAINTENANCES', data)
        return data
      })
      .catch((error) => {
        return error
      })
  },
  addMaintenance ({ commit }, data) {
    return new Promise((resolve, reject) => {
      addMaintenanceData(data).then(res => {
        commit('ADD_MAINTENANCE', res)
        Message({
          message: 'Автомобиль был добавлен на Т.О',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  acceptMaintenance ({ commit }, id) {
    return new Promise((resolve, reject) => {
      acceptMaintenanceData(id).then(() => {
        commit('REMOVE_MAINTENANCE', id)
        Message({
          message: 'Автомобиль был снят с Т.О',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(id)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  changeMaintenance ({ commit, dispatch }, { id, data }) {
    return new Promise((resolve, reject) => {
      changeMaintenanceData(id, data).then(() => {
        Message({
          message: 'Информация о Т.О была изменена',
          type: 'success',
          duration: 5 * 1000
        })

        dispatch('fetchMaintenances')

        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  filter ({ commit }, data) {
    commit('FILTER', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
