import { fetchDashboardFinance, fetchDashboardCars, fetchDashboardTenantsIncome, fetchDashboardFines, fetchDashboardDebts, fetchDashboardMaintenance, fetchDashboardMaintenanceExpense } from '@/requests/dashboard'
export const SET_FINANCE_DASHBOARD = 'SET_FINANCE_DASHBOARD'
export const SET_CARS_DASHBOARD = 'SET_CARS_DASHBOARD'

export const SET_INCOME = 'SET_INCOME'
export const SET_FINES = 'SET_FINES'
export const SET_DEBTS = 'SET_DEBTS'
export const SET_MAINTENANCE = 'SET_MAINTENANCE'
export const SET_MAINTENANCE_EXPENSE = 'SET_MAINTENANCE_EXPENSE'

export const TOOGLE_LOADING_FINANCE = 'TOOGLE_LOADING_FINANCE'
export const TOOGLE_LOADING_CARS = 'TOOGLE_LOADING_CARS'
export const TOOGLE_LOADING_INCOME = 'TOOGLE_LOADING_INCOME'
export const TOOGLE_LOADING_FINES = 'TOOGLE_LOADING_FINES'
export const TOOGLE_LOADING_DEBTS = 'TOOGLE_LOADING_DEBTS'
export const TOOGLE_LOADING_MAINTENANCE = 'TOOGLE_LOADING_MAINTENANCE'
export const TOOGLE_LOADING_MAINTENANCE_EXPENSE = 'TOOGLE_LOADING_MAINTENANCE_EXPENSE'

export const SET_CURRENT_REGION_PARK_ID = 'SET_CURRENT_REGION_PARK_ID'
const state = {
  finance: [],
  cars: [],

  tenantsIncome: {},
  fines: {},
  debts: {},
  maintenance: {},
  maintenanceExpense: {},

  currentRegionParkID: null,

  isLoadingFinance: true,
  isLoadingCars: true,
  isTenantsIncome: true,
  isFines: true,
  isDebts: true,
  isMaintenance: true,
  isMaintenanceExpense: true
}

const mutations = {
  [SET_FINANCE_DASHBOARD]: (state, data) => {
    state.finance = data
  },
  [SET_CARS_DASHBOARD] (state, value) {
    state.cars = value
  },

  [SET_INCOME] (state, value) {
    state.tenantsIncome = value
  },
  [SET_FINES] (state, value) {
    state.fines = value
  },
  [SET_DEBTS] (state, value) {
    state.debts = value
  },
  [SET_MAINTENANCE] (state, value) {
    state.maintenance = value
  },
  [SET_MAINTENANCE_EXPENSE] (state, value) {
    state.maintenanceExpense = value
  },

  [TOOGLE_LOADING_FINANCE] (state, value) {
    state.isLoadingFinance = value
  },
  [TOOGLE_LOADING_CARS] (state, value) {
    state.isLoadingCars = value
  },
  [TOOGLE_LOADING_INCOME] (state, value) {
    state.isTenantsIncome = value
  },
  [TOOGLE_LOADING_FINES] (state, value) {
    state.isFines = value
  },
  [TOOGLE_LOADING_DEBTS] (state, value) {
    state.isDebts = value
  },
  [TOOGLE_LOADING_MAINTENANCE] (state, value) {
    state.isMaintenance = value
  },
  [TOOGLE_LOADING_MAINTENANCE_EXPENSE] (state, value) {
    state.isMaintenanceExpense = value
  },
  [SET_CURRENT_REGION_PARK_ID]: (state, value) => {
    state.currentRegionParkID = value
  }
}

const getters = {}
const actions = {
  setCurrentRegionParkID ({ commit }, region_park_id) {
    commit('SET_CURRENT_REGION_PARK_ID', region_park_id)
  },
  async fetchDashboardFinance ({ commit, state }, { start_date, end_date }) {
    try {
      commit('TOOGLE_LOADING_FINANCE', true)
      const data = await fetchDashboardFinance({ start_date, end_date, region_park: state.currentRegionParkID })
      commit('TOOGLE_LOADING_FINANCE', false)

      commit('SET_FINANCE_DASHBOARD', data)
    } catch (e) {
      commit('TOOGLE_LOADING_FINANCE', false)
      return Promise.reject(e)
    }
  },
  async fetchDashboardCars ({ commit, state }, { start_date, end_date }) {
    try {
      commit('TOOGLE_LOADING_CARS', true)
      const data = await fetchDashboardCars({ start_date, end_date, region_park: state.currentRegionParkID })
      commit('TOOGLE_LOADING_CARS', false)

      commit('SET_CARS_DASHBOARD', data)
    } catch (e) {
      commit('TOOGLE_LOADING_CARS', false)
      return Promise.reject(e)
    }
  },
  async fetchDashboardTenantsIncome ({ commit, state }, { start_date, end_date }) {
    try {
      commit('TOOGLE_LOADING_INCOME', true)
      const data = await fetchDashboardTenantsIncome({ start_date, end_date, region_park: state.currentRegionParkID })
      commit('TOOGLE_LOADING_INCOME', false)

      commit('SET_INCOME', data)
    } catch (e) {
      commit('TOOGLE_LOADING_INCOME', false)
      return Promise.reject(e)
    }
  },
  async fetchDashboardFines ({ commit, state }, { start_date, end_date }) {
    try {
      commit('TOOGLE_LOADING_FINES', true)
      const data = await fetchDashboardFines({ start_date, end_date, region_park: state.currentRegionParkID })
      commit('TOOGLE_LOADING_FINES', false)

      commit('SET_FINES', data)
    } catch (e) {
      commit('TOOGLE_LOADING_FINES', false)
      return Promise.reject(e)
    }
  },
  async fetchDashboardDebts ({ commit, state }, { start_date, end_date }) {
    try {
      commit('TOOGLE_LOADING_DEBTS', true)
      const data = await fetchDashboardDebts({ start_date, end_date, region_park: state.currentRegionParkID })
      commit('TOOGLE_LOADING_DEBTS', false)

      commit('SET_DEBTS', data)
    } catch (e) {
      commit('TOOGLE_LOADING_DEBTS', false)
      return Promise.reject(e)
    }
  },
  async fetchDashboardMaintenance ({ commit, state }, { start_date, end_date }) {
    try {
      commit('TOOGLE_LOADING_MAINTENANCE', true)
      const data = await fetchDashboardMaintenance({ start_date, end_date, region_park: state.currentRegionParkID })
      commit('TOOGLE_LOADING_MAINTENANCE', false)

      commit('SET_MAINTENANCE', data)
    } catch (e) {
      commit('TOOGLE_LOADING_MAINTENANCE', false)
      return Promise.reject(e)
    }
  },
  async fetchDashboardMaintenanceExpense ({ commit, state }, { start_date, end_date }) {
    try {
      commit('TOOGLE_LOADING_MAINTENANCE_EXPENSE', true)
      const data = await fetchDashboardMaintenanceExpense({ start_date, end_date, region_park: state.currentRegionParkID })
      commit('TOOGLE_LOADING_MAINTENANCE_EXPENSE', false)

      commit('SET_MAINTENANCE_EXPENSE', data)
    } catch (e) {
      commit('TOOGLE_LOADING_MAINTENANCE_EXPENSE', false)
      return Promise.reject(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
