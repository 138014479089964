import { getCarsLoadData, getDriversIncomeData, getFines, getGeneralFinance, getDriversDebts } from '@/requests/carsLoad'

const state = {
  startDate: '2012-12-01',
  endDate: '2021-12-01',
  carsLoadData: [
    {
      id: 1,
      state: 'Аренда',
      color: 'blue',
      value: 0
    },
    {
      id: 2,
      state: 'Т.О',
      color: 'red',
      value: 0
    }, {
      id: 3,
      state: 'Ремонт',
      color: 'yellow',
      value: 0
    },
    {
      id: 4,
      state: 'Простой',
      color: 'green',
      value: 0
    }
  ],
  carsLoadChartData: {
    datasets: [{
      data: [],
      backgroundColor: []
    }],
    labels: []
  },
  driversIncomeData: null,
  finesData: null,
  generalFinanceData: [
    {
      id: 1,
      state: 'Доход',
      color: 'blue',
      value: 888888
    },
    {
      id: 2,
      state: 'Расход Т.О',
      color: 'red',
      value: 100
    },
    {
      id: 3,
      state: 'Расход Рем',
      color: 'yellow',
      value: 100
    },
    {
      id: 4,
      state: 'Простой',
      color: 'green',
      value: 100
    },
    {
      id: 5,
      state: 'Итого',
      color: 'green',
      value: 100
    }
  ],
  generalFinanceChartData: null,
  driversDebtsData: null
}

const mutations = {
  SET_CARS_LOAD: (state, data) => {
    const newData = [
      {
        id: 1,
        state: 'Аренда',
        color: 'blue',
        value: data.rent_percent.toFixed(3)
      },
      {
        id: 2,
        state: 'Т.О',
        color: 'red',
        value: data.planned_maintenance_percent.toFixed(3)
      }, {
        id: 3,
        state: 'Ремонт',
        color: 'yellow',
        value: data.repair_maintenance_percent.toFixed(3)
      },
      {
        id: 4,
        state: 'Простой',
        color: 'green',
        value: data.no_rent_percent.toFixed(3)
      }
    ]

    state.carsLoadData = newData

    state.carsLoadChartData = {
      datasets: [{
        data: newData.map(cur => cur.value),
        backgroundColor: newData.map(cur => cur.color)
      }],
      labels: newData.map(cur => cur.state)
    }
  },
  SET_DATE_TIME: (state, { startDate, endDate }) => {
    state.startDate = startDate
    state.endDate = endDate
  },
  SET_DRIVERS_INCOME: (state, data) => {
    state.driversIncomeData = data
  },
  SET_FINES: (state, data) => {
    state.finesData = data
  },
  SET_GENERAL_FINANCE: (state, data) => {
    let summaryDowntime = 0
    let summaryProfit = 0
    let summaryRepair = 0
    let summaryPlanned = 0
    let summaryIncome = 0

    data.forEach(cur => { summaryDowntime += cur.downtime })
    data.forEach(cur => { summaryProfit += cur.profit })
    data.forEach(cur => { summaryRepair += cur.repair_maintenance })
    data.forEach(cur => { summaryPlanned += cur.planned_maintenance })
    data.forEach(cur => { summaryIncome += cur.income })

    state.generalFinanceData = [
      {
        id: 1,
        state: 'Доход',
        color: 'blue',
        value: summaryIncome
      },
      {
        id: 2,
        state: 'Расход Т.О',
        color: 'red',
        value: summaryPlanned
      },
      {
        id: 3,
        state: 'Расход Рем',
        color: 'yellow',
        value: summaryRepair
      },
      {
        id: 4,
        state: 'Простой',
        color: 'green',
        value: summaryDowntime
      },
      {
        id: 5,
        state: 'Итого',
        color: 'green',
        value: summaryProfit
      }
    ]
  },
  SET_FINANCE_CHART: (state, data) => {
    const beginDate = new Date(state.startDate)

    const endDate = new Date(state.endDate)

    const daysLag = Math.ceil(Math.abs(endDate.getTime() - beginDate.getTime()) / (1000 * 3600 * 24))

    const profit = []
    const planned = []
    const repair = []
    const downtime = []
    const labels = []

    if (daysLag > 1) {
      data.forEach(cur => {
        planned.push(cur.planned_maintenance)
        repair.push(cur.repair_maintenance)
        profit.push(cur.profit)
        downtime.push(cur.downtime)
        labels.push(cur.date)
      })

      state.generalFinanceChartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: 'rgba(64, 89, 255,0.1)',
            borderColor: '#3751FF',
            label: 'Прибыль',
            data: profit
          },
          {
            backgroundColor: 'rgba(64, 89, 255,0.1)',
            borderColor: '#3751FF',
            label: 'Простой',
            data: downtime
          },
          {
            backgroundColor: 'rgba(64, 89, 255,0.1)',
            borderColor: '#3751FF',
            label: 'Расход на ремонт',
            data: repair
          },
          {
            backgroundColor: 'rgba(64, 89, 255,0.1)',
            borderColor: '#3751FF',
            label: 'Расход на Т.О',
            data: planned
          }]
      }
    } else {
      data.forEach(cur => {
        planned.push(cur.planned_maintenance)
        repair.push(cur.repair_maintenance)
        profit.push(cur.profit)
        downtime.push(cur.downtime)
        labels.push(cur.hour)
      })

      state.generalFinanceChartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: 'rgba(64, 89, 255,0.1)',
            borderColor: '#3751FF',
            label: 'Прибыль',
            data: profit
          },
          {
            backgroundColor: 'rgba(64, 89, 255,0.1)',
            borderColor: '#3751FF',
            label: 'Простой',
            data: downtime
          },
          {
            backgroundColor: 'rgba(64, 89, 255,0.1)',
            borderColor: '#3751FF',
            label: 'Расход на ремонт',
            data: repair
          },
          {
            backgroundColor: 'rgba(64, 89, 255,0.1)',
            borderColor: '#3751FF',
            label: 'Расход на Т.О',
            data: planned
          }]
      }
    }
  },
  SET_DRIVERS_DEBTS: (state, data) => {
    state.driversDebtsData = data
  }
}

const getters = {
  getStartDate (state) {
    return state.startDate
  },
  getEndDate (state) {
    return state.endDate
  }
}

const actions = {

  fetchDashboard ({ commit, getters }, { startDate, endDate }) {
    commit('SET_DATE_TIME', { startDate, endDate })

    getCarsLoadData(getters.getStartDate, getters.getEndDate)
      .then(result => {
        commit('SET_CARS_LOAD', result)
        return result
      })
      .catch((error) => {
        return Promise.reject(error)
      })

    getDriversIncomeData(getters.getStartDate, getters.getEndDate)
      .then(result => {
        commit('SET_DRIVERS_INCOME', result)
        return result
      })
      .catch((error) => {
        return Promise.reject(error)
      })

    getFines(getters.getStartDate, getters.getEndDate)
      .then(result => {
        commit('SET_FINES', result)
        return result
      })
      .catch((error) => {
        return Promise.reject(error)
      })

    getGeneralFinance(getters.getStartDate, getters.getEndDate)
      .then(result => {
        commit('SET_GENERAL_FINANCE', result)
        commit('SET_FINANCE_CHART', result)
        return result
      })
      .catch((error) => {
        return Promise.reject(error)
      })

    getDriversDebts(getters.getStartDate, getters.getEndDate).then(result => {
      commit('SET_DRIVERS_DEBTS', result)
      return result
    })
      .catch((error) => {
        return error
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
