import request from '../utils/request'

const URL = 'taxi/drivers'
export const getData = (params) => request({
  method: 'get',
  url: URL,
  params
})
export const getDataV1 = (params) => request({
  method: 'get',
  url: `v1/${URL}`,
  params
})
export const removeData = (id) => {
  return request({
    url: `${URL}/${id}`,
    method: 'delete'
  })
}

export const getDriverById = (id) => request({
  method: 'get',
  url: `${URL}/${id}`
})

export const addData = (tenantData) => {
  return request({
    method: 'post',
    url: URL,
    data: tenantData
  })
}

export const changeData = (id, tenantData) => {
  return request({
    method: 'patch',
    url: `${URL}/${id}`,
    data: tenantData
  })
}
export const saveTenantsFromYandex = () => {
  return request({
    method: 'post',
    url: `${URL}/save`
  })
}
export const getDriversShort = (params) => request({
  method: 'get',
  url: '/taxi/driver_short_info/',
  params
})
// new
export const putDriverBalance = (data) => request({
  method: 'post',
  url: '/v1/tenants_income/change_driver_balance/ ',
  data
})
export const getDriverBalance = (params) => request({
  method: 'get',
  url: '/v1/tenants_income/driver_balance_history/',
  params
})
export const putDriverDeposit = (data) => request({
  method: 'post',
  url: '/v1/tenants_income/change_driver_deposit/ ',
  data
})
export const getDriverDeposit = (params) => request({
  method: 'get',
  url: '/v1/tenants_income/driver_deposit_history/',
  params
})
export const getDriverBalanceDebts = (params) => request({
  method: 'get',
  url: '/v1/tenants_income/driver_balance_debts/',
  params
})
//
// export const changeData = (id,tenantData) => {
//   return request({
//     method : 'put',
//     url : `taxi/drivers/${id}`,
//     data : tenantData
//   })
// }
//
//
export const creteZeroBalance = () => request({
  method: 'post',
  url: '/v1/tenants_income/zero_balance/'
})
