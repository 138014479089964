import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'dashboard',
    // redirect: { name: 'Shedule' },
    component: () => import(/* webpackChunkName: "dashboard"  */ '@/views/dashboard/index.vue'),
    meta: {
      id: '1',
      title: 'Дашборд | Сводка',
      visible: true,
      clickable: true,
      // icon: 'el-icon-menu',
      icon: ['fas', 'chart-area'],
      layout: 'main-layout'
    },
    children: []
  },
  {
    path: '/tenantsIncome',
    name: 'tenantsIncome',
    component: () => import(/* webpackChunkName: "tenantsIncome"  */ '@/views/tenantsIncome/index'),
    meta: {
      id: '2',
      title: 'Выплаты|Расчет',
      custom_icon: 'coins.svg',
      layout: 'main-layout'
    },
    children: []
  },
  {
    path: '/shedule',
    name: 'Shedule',
    component: () => import(/* webpackChunkName: "shedule"  */ '@/views/shedule/index'),
    meta: {
      id: '3',
      title: 'Смены',
      icon: ['fas', 'calendar-alt'],
      layout: 'main-layout'
    },
    children: []
  },
  {
    // path: '',
    path: '/report/consolidated',
    component: () => import(/* webpackChunkName: "consolidatedReport"  */ '@/views/consolidatedReport/index'),
    name: 'Statistic',
    meta: {
      id: '4',
      title: 'Ежемесячный отчет',
      icon: ['fas', 'chart-pie'],
      layout: 'main-layout'
    },
    children: [
      // {
      //   path: '/driversStatistic',
      //   name: 'DriversStatistic',
      //   component: () => import(/* webpackChunkName: "driversStatistic"  */ '@/views/driversStatistic/index'),
      //   meta: {
      //     id: '41',
      //     title: 'Сводный по водителям',
      //     icon: ['fas', 'user-friends'],
      //     layout: 'main-layout'
      //   }
      // },
      // {
      //   name: 'ParkStatistic',
      //   path: '/parkStatistic',
      //   component: () => import(/* webpackChunkName: "parkStatistic"  */ '@/views/parkStatistic/index'),
      //   meta: {
      //     id: '42',
      //     title: 'Сводный по парку',
      //     icon: ['fas', 'taxi'],
      //     layout: 'main-layout'
      //   }
      // },
      // {
      //   name: 'consolidatedReport',
      //   path: '/report/consolidated',
      //   component: () => import(/* webpackChunkName: "consolidatedReport"  */ '@/views/consolidatedReport/index'),
      //   meta: {
      //     id: '44',
      //     title: 'Ежемесячный отчет',
      //     icon: ['fas', 'clipboard-check'],
      //     layout: 'main-layout'
      //   }
      // },
      // {
      //   name: 'Report',
      //   path: '/report',
      //   component: () => import(/* webpackChunkName: "parkStatistic"  */ '@/views/report'),
      //   meta: {
      //     id: '43',
      //     title: 'Отчет',
      //     icon: ['fas', 'scroll'],
      //     layout: 'main-layout'
      //   }
      // }
    ]
  },
  {
    path: '',
    component: () => import(/* webpackChunkName: "emptyLayout"  */ '@/layouts/emptyLayout'),
    name: 'Tenants',
    meta: {
      id: '5',
      title: 'Арендаторы',
      custom_icon: 'users.svg',
      layout: 'main-layout'
    },
    children: [
      {
        path: '/tenantsInfo',
        name: 'TenantsInfo',
        component: () => import(/* webpackChunkName: "tenantsInfo"  */ '@/views/tenantsInfo/index'),
        meta: {
          id: '51',
          title: 'Водители',
          icon: ['fas', 'user-check'],
          layout: 'main-layout'
        }
      },
      {
        path: '/debtors_by_balances',
        name: 'TenantsInfo.debtors_by_balances',
        component: () => import(/* webpackChunkName: "tenantsInfo"  */ '@/views/tenantsInfo/debtorsByBalances'),
        meta: {
          id: '234',
          title: 'Реестр должников по балансам',
          icon: ['fas', 'user-check'],
          layout: 'main-layout'
        }
      },
      {
        path: '/driver_deposit_debts',
        name: 'TenantsInfo.driver_deposit_debts',
        component: () => import(/* webpackChunkName: "tenantsInfo"  */ '@/views/tenantsInfo/driver_deposit_debts'),
        meta: {
          id: '22334',
          title: 'Реестр по депозитам',
          icon: ['fas', 'user-check'],
          layout: 'main-layout'
        }
      },
      {
        name: 'TenantsCandidatesInfo',
        path: '/tenantsCandidatesInfo',
        component: () => import(/* webpackChunkName: "tenantsCandidatesInfo"  */ '@/views/tenantsCandidatesInfo/index'),
        meta: {
          id: '52',
          title: 'Кандидаты',
          icon: ['fas', 'user-clock'],
          layout: 'main-layout'
        }
      },
      {
        name: 'OpenTransactions',
        path: '/OpenTransactions',
        component: () => import(/* webpackChunkName: "tenantsCandidatesInfo"  */ '@/views/OpenTransactions/index'),
        meta: {
          id: '70',
          title: 'Реестр должников',
          icon: ['fas', 'list'],
          layout: 'main-layout'
        }
      }
    ]
  },
  {
    path: '/carpark',
    name: 'CarPark',
    component: () => import(/* webpackChunkName: "carPark"  */ '@/views/carPark/index'),
    meta: {
      id: '6',
      title: 'Автопарк',
      icon: ['fas', 'taxi'],
      layout: 'main-layout'
    },
    children: []
  },
  {
    path: '/localCarPark',
    name: 'localCarPark',
    component: () => import(/* webpackChunkName: "carLocalParks"  */ '@/views/carLocalParks/index'),
    meta: {
      id: '6123',
      title: 'Парки',
      custom_icon: 'user_mic.svg',
      layout: 'main-layout'
    },
    children: []
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: () => import(/* webpackChunkName: "contracts"  */ '@/views/contracts'),
    meta: {
      id: '7',
      title: 'Договоры',
      custom_icon: 'document.svg',
      layout: 'main-layout'
    },
    children: []
  },
  {
    path: '/investors',
    name: 'Investors',
    component: () => import(/* webpackChunkName: "investors"  */ '@/views/investors'),
    meta: {
      id: '8',
      title: 'Собственники авто ',
      custom_icon: 'user.svg',
      layout: 'main-layout'
    },
    children: []
  },
  {
    path: '/techInspection',
    component: () => import(/* webpackChunkName: "techInspection"  */ '@/views/techInspection/index'),
    redirect: { name: 'techInspection.requests' },
    name: 'techInspection.redirect',
    meta: {
      id: '91',
      title: 'Т.О',
      custom_icon: 'car_repair.svg',
      layout: 'mainLayout'
    },
    children: [
      {
        path: '',
        name: 'techInspection.requests',
        meta: {
          id: '393',
          title: 'Т.О Заявки',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/views/techInspection/ServiceRequests')
      },
      {
        path: 'repairs',
        name: 'techInspection.repairs',
        meta: {
          id: '94',
          title: 'Т.О Ремонты',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "CurrentRepairs"  */ '@/views/techInspection/CurrentRepairs')
      },
      {
        path: 'history',
        name: 'techInspection.history',
        meta: {
          id: '95',
          title: 'Т.О История',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "History"  */ '@/views/techInspection/History')
      }
    ]
  },
  {
    path: '/parts',
    name: 'Parts',
    redirect: { name: 'parts.storage-table' },
    component: () => import(/* webpackChunkName: "parts"  */ '@/views/parts/index'),
    meta: {
      id: '92',
      title: 'Склад',
      custom_icon: 'warehouse.svg',
      layout: 'mainLayout',
      allowedRouts: ['parts.providers-table', 'parts.categories-table', 'parts.parts-table', 'parts.storage-table']
    },
    children: [
      {
        path: 'orders',
        name: 'parts.orders-table',
        meta: {
          id: '93',
          title: 'Склад | Заказы',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/views/parts/ordersTable')
      },
      {
        path: 'providers',
        name: 'parts.providers-table',
        meta: {
          id: '100',
          title: 'Склад | Поставщики',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/components/parts/PartProvidersTable.vue')
      },
      {
        path: 'storage',
        name: 'parts.storage-table',
        meta: {
          id: '101',
          title: 'Склад | Склад',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/views/parts/StorageTable')
      },
      {
        path: 'categories',
        name: 'parts.categories-table',
        meta: {
          id: '102',
          title: 'Склад | Категории',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/components/parts/PartsCategoriesTable')
      },
      {
        path: 'parts',
        name: 'parts.parts-table',
        meta: {
          id: '103',
          title: 'Склад | Запчасти',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/components/parts/PartsTable.vue')
      },
      {
        path: 'decommission',
        name: 'parts.decommission',
        meta: {
          title: 'Склад | Списания',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/views/parts/decommission')
      },
      {
        path: 'decommission/act/:id',
        name: 'parts.decommission.act',
        meta: {
          title: 'Склад | Акт списания',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/components/parts/decommission/actTable.vue')
      },
      {
        path: 'decommission/movement/history',
        name: 'parts.decommission.movement.history',
        meta: {
          title: 'Склад | Движение склада',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/components/parts/decommission/movementHistory.vue')
      },
      {
        path: 'decommission/movement/history/details/:id',
        name: 'parts.decommission.movement.history.details',
        meta: {
          title: 'Склад | Детализированное движение по складу',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/components/parts/decommission/movementHistoryDetails.vue')
      }

      // {
      //   path: 'decommission',
      //   meta: {
      //     id: '96',
      //     icon: ['fas', 'user-cog'],
      //     layout: 'mainLayout'
      //   },
      //   redirect: { name: 'parts.decommission' },
      //   component: () => import(/* webpackChunkName: "partsDecommission"  */ '@/views/parts/decommission.vue'),
      //   children: [
      //     {
      //       path: '/',
      //       name: 'parts.decommission',
      //       meta: {
      //         title: 'Запчасти | Списания',
      //         icon: ['fas', 'user-cog'],
      //         layout: 'mainLayout'
      //       },
      //       component: () => import(/* webpackChunkName: "partsDecommissionTable"  */ '@/components/parts/decommission/table.vue')
      //     },
      //     {
      //       path: 'act',
      //       name: 'parts.decommission.act',
      //       meta: {
      //         title: 'Запчасти | Акт списания',
      //         icon: ['fas', 'user-cog'],
      //         layout: 'mainLayout'
      //       },
      //       component: () => import(/* webpackChunkName: "actTable"  */ '@/components/parts/decommission/actTable.vue')
      //     }
      //   ]
      // }
    ]
  },
  {
    path: '/fines',
    name: 'fines.layout',
    // redirect: { name: 'parts.storage-table' },
    component: () => import(/* webpackChunkName: "parts"  */ '@/views/fines/layout'),
    meta: {
      id: '109',
      title: 'Штрафы',
      custom_icon: 'cvtv.svg',
      layout: 'mainLayout'
    },
    children: [
      {
        path: '',
        name: 'fines',
        meta: {
          id: '93',
          title: 'Штрафы',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/views/fines/index')
      },
      {
        path: '/history',
        name: 'fines.history',
        meta: {
          id: '199',
          title: 'Штрафы | История',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/views/fines/history.vue')
      }

    ]
  },
  {
    path: '/templates/',
    name: 'templates.layout',
    // redirect: { name: 'parts.storage-table' },
    component: () => import(/* webpackChunkName: "parts"  */ '@/views/templates/layout'),
    meta: {
      id: '122',
      title: 'ЭДО',
      visible: false,
      custom_icon: 'cvtv.svg',
      layout: 'mainLayout'
    },
    children: [
      {
        path: ':park_id',
        name: 'templates',
        meta: {
          id: '123',
          title: 'ЭДО | Шаблоны',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/views/templates/index')
      },
      {
        path: '/templates/:park_id/types',
        name: 'templates.types',
        meta: {
          id: '124',
          title: 'ЭДО | Типы',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/views/templates/types')
      },
      {
        path: '/templates/:park_id/editor',
        name: 'templates.editor',
        meta: {
          id: '129',
          title: 'ЭДО | Документ',
          icon: ['fas', 'user-cog'],
          layout: 'mainLayout'
        },
        component: () => import(/* webpackChunkName: "serviceRequests"  */ '@/views/templates/editor')
      }
    ]
  },
  {
    path: '/configurator/:settings_id',
    name: 'Configurator',
    component: () => import(/* webpackChunkName: "configurator"  */ '@/views/сonfigurator/index'),
    meta: {
      id: '345',
      visible: false,
      title: 'Конфигуратор',
      icon: ['fas', 'sliders-h'],
      layout: 'main-layout'
    }
  },
  {
    path: '',
    component: () => import(/* webpackChunkName: "emptyLayout"  */ '@/layouts/emptyLayout'),
    name: '',
    meta: {
      title: 'Параметры',
      icon: ['fas', 'sliders-h'],
      layout: 'main-layout',
      id: '10',
      marginTop: true
    },
    children: [
      {
        path: '/admins',
        name: 'Admins',
        component: () => import(/* webpackChunkName: "admins"  */ '@/views/admins/index'),
        meta: {
          id: '101',
          title: 'Администраторы',
          icon: ['fas', 'user-shield'],
          layout: 'main-layout'
        }
      },
      {
        path: '/roles',
        name: 'Roles',
        component: () => import(/* webpackChunkName: "roles"  */ '@/views/roles/index'),
        meta: {
          id: '102',
          title: 'Роли',
          icon: ['fas', 'user-tag'],
          layout: 'main-layout'
        }
      },
      {
        path: '/crm',
        name: 'CMR',
        component: () => import(/* webpackChunkName: "crm"  */ '@/views/crm/index'),
        meta: {
          id: '103',
          title: 'Настройки CRM',
          icon: ['fas', 'sliders-h'],
          layout: 'main-layout'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */'@/views/login'),
    meta: {
      layout: 'empty-layout',
      visible: false
    },
    children: []
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import(/* webpackChunkName: "registration" */'@/views/registration'),
    meta: {
      layout: 'empty-layout',
      visible: false
    },
    children: []
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
