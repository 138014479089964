<template>
  <transition name="loading-fade"  @after-leave="handleAfterLeave">
    <div class="el-loading-custom" v-show="visible">
      <div class="el-loading-custom__list">
        <svg class="el-loading-custom__item" width="522" height="912" viewBox="0 0 522 912" fill="#FAB43C" xmlns="http://www.w3.org/2000/svg">
          <path d="M431 1L1 464L354.886 911H444L91 465V464L520 1H431Z"/>
        </svg>

        <svg class="el-loading-custom__item" width="522" height="912" viewBox="0 0 522 912" fill="#FAB43C" xmlns="http://www.w3.org/2000/svg">
          <path d="M431 1L1 464L354.886 911H444L91 465V464L520 1H431Z" />
        </svg>

        <svg class="el-loading-custom__item"  width="522" height="912" viewBox="0 0 522 912" fill="#FAB43C" xmlns="http://www.w3.org/2000/svg">
          <path d="M431 1L1 464L354.886 911H444L91 465V464L520 1H431Z" />
        </svg>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      text: null,
      spinner: null,
      background: null,
      fullscreen: true,
      visible: false,
      customClass: ''
    }
  },

  methods: {
    handleAfterLeave () {
      this.$emit('after-leave')
    },
    setText (text) {
      this.text = text
    }
  }
}
</script>

<style lang="scss">
@keyframes scale {
  0% {
    transform: scale(0.45);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.45);
  }
}

.loading-fade-enter,
.loading-fade-leave-active {
  opacity: 0;
}

.el-loading-custom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  font-size: 15px;
  background-color: rgba(255, 255, 255, .9);
  margin: 0;
  transition: opacity 0.3s;
  z-index: 2000;

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 80vh;
    height: 100%;
  }

  &__item {
    transform: scale(0);
    animation: scale 1.2s infinite;
  }

  &__item {
    width: auto;
    height: 3em;
    margin: -0.1em;

    &:nth-child(2) {
      animation-delay: 0.16s
    }

    &:nth-child(3) {
      animation-delay: 0.32s
    }
  }
}
</style>
