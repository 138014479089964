import { getAllOpenTransaction } from '@/requests/OpenTransactions'
import moment from 'moment'
export const SET_OPEN_TRANSACTION = 'SET_OPEN_TRANSACTION'
export const TOOGLE_LOADING = 'TOOGLE_LOADING'
export const SET_FILTERS = 'SET_FILTERS'
const state = {
  openTransactions: [],
  filters: {
    category: '',
    driverName: '',
    dateTime: [],
    isHideRepaidDebts: false,
    change: 'all'
  },
  isLoading: true
}

const mutations = {
  [SET_OPEN_TRANSACTION]: (state, data) => {
    state.openTransactions = data
  },

  [TOOGLE_LOADING] (state, value) {
    state.isLoading = value
  },
  [SET_FILTERS] (state, value) {
    state.filters = value
  }
}

const getters = {
  getAllDrivers: (state) => {
    const drivers = state.openTransactions.map(val => `${val.driver_first_name || ''} ${val.driver_last_name || ''}`)
    return [...new Set(drivers)]
  },
  getAllCategories: (state) => {
    const categories = state.openTransactions.map(val => val.transaction_type_description)
    return [...new Set(categories)]
  },
  sortedDataByFilter (state) {
    const { category, driverName, dateTime, repaidDebts, change } = state.filters
    let returnFilters = state.openTransactions
    if (repaidDebts === 'onlyRepaidDebts') {
      returnFilters = returnFilters.filter(transaction => transaction.transaction_amount - transaction.transaction_current_amount === 0)
    }
    if (repaidDebts === 'onlyHideRepaidDebts') {
      returnFilters = returnFilters.filter(transaction => transaction.transaction_amount - transaction.transaction_current_amount !== 0)
    }
    if (change !== 'all') {
      returnFilters = returnFilters.filter(transaction => transaction.change === change)
    }
    if (driverName?.length) {
      returnFilters = returnFilters.filter(val => driverName.some(v => `${val.driver_first_name || ''} ${val.driver_last_name || ''}` === v))
    }
    if (category?.length) {
      returnFilters = returnFilters.filter(val => category.some(v => val.transaction_type_description === v))
    }
    if (dateTime?.length) {
      const startDate = moment(state.filters.dateTime[0]).format('YYYY-MM-DD')
      const endDate = moment(state.filters.dateTime[1]).format('YYYY-MM-DD')
      returnFilters = returnFilters.filter(a => {
        const currentDate = moment(a.transaction_event_at).format('YYYY-MM-DD')
        return moment(currentDate).isBetween(startDate, endDate, undefined, '[]')
      })
    }
    return returnFilters
  },
  getCurrentDriversOnThePark: (state, getters, rootState) => {
    if (!rootState?.schedule?.currentRegionParkID) return getters.sortedDataByFilter
    return getters.sortedDataByFilter.filter(driver => driver.region_park_id === rootState?.schedule?.currentRegionParkID)
  }
}
const actions = {
  async fetchAllTransactions ({ commit }, params) {
    try {
      commit('TOOGLE_LOADING', true)
      const data = await getAllOpenTransaction(params)
      commit('TOOGLE_LOADING', false)

      commit('SET_OPEN_TRANSACTION', data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async setFilters ({ commit }, val) {
    try {
      commit('SET_FILTERS', val)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
