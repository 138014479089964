<template>
  <el-container  >
    <el-aside style="width: 70px;height: 100vh;overflow:visible;position:fixed;z-index: 50;">
      <side-menu/>
    </el-aside>
    <el-container style="margin-left: 70px;">
      <el-header>
        <app-header :title="title"></app-header>
      </el-header>
      <el-main style="overflow: visible">
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideMenu from '@/components/SideMenu'
import appHeader from '@/components/appHeader'
export default {
  name: 'mainLayout',
  components: {
    SideMenu,
    appHeader
  },
  computed: {
    title () {
      return this.$route.meta.title
    }
  }
}
</script>

<style scoped>

</style>
