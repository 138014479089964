import request from '../utils/request'

export const getScheduleInRange = (params) => request({
  method: 'get',
  url: '/taxi/schedules',
  params
})

export const getCurrenScheduleData = (id) => request({
  method: 'get',
  url: `/taxi/schedules/${id}`
})

export const getSheduleDrivers = (change, date) => request({
  method: 'get',
  url: `/taxi/schedules/drivers?change=${change}&date=${date}`
})

export const changeDriverRatingData = (data) => request({
  method: 'post',
  url: '/taxi/drivers/rating',
  data: data
})

export const createDriverSchedule = (data) => {
  return request({
    method: 'post',
    url: '/taxi/schedules',
    data: data
  })
}

export const changeDriverSchedule = (id, data) => {
  return request({
    method: 'patch',
    url: `/taxi/schedules/${id}`,
    data: data
  })
}

export const removeSchedule = (id) => {
  return request({
    method: 'delete',
    url: `/taxi/schedules/${id}`
  })
}

export const getRatings = (id) => request({
  method: 'get',
  url: `/taxi/drivers/${id}/ratings`
})

export const getScheduleReportExel = (dto) => {
  return request({
    method: 'post',
    url: '/taxi/statistics/schedule_report_exel',
    data: {
      start_date: dto.fromDate,
      end_date: dto.toDate,
      separator: dto.separator,
      region_park_id: dto.region_park_id
    }
  })
}

export const getDriversInChange = (params) => request({
  method: 'get',
  url: '/taxi/schedules/drivers_in_change',
  params
})
export const getStatistic = (params) => request({
  method: 'get',
  url: '/statistic/schedule/',
  params
})
export const transplantDriver = (data) => request({
  method: 'post',
  url: '/taxi/schedules/transplant_driver',
  data
})
