import request from '../utils/request'

const baseURL = 'v1/tenants_income/fines/'

export const getFinesByType = (params) => request({
  method: 'get',
  url: baseURL,
  params
})

export const getFineById = (id) => request({
  method: 'get',
  url: `${baseURL}${id}`
})

export const changeStatusFine = (params) => {
  return request({
    method: 'post',
    url: `${baseURL}accept/`,
    params
  })
}

export const changeStatusFineCompany = (params) => request({
  method: 'post',
  url: `${baseURL}company_payment/`,
  params
})
export const changeDriverBinding = (params) => request({
  method: 'post',
  url: `${baseURL}driver_binding/`,
  params
})
export const acceptFind = (params) => request({
  method: 'post',
  url: `${baseURL}accept_find/`,
  params
})
export const deleteFine = (fine_id) => request({
  method: 'delete',
  url: `${baseURL}${fine_id}`
})
