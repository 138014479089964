export const state = {
  isLoading: true,
  schedule: [],
  availableDrivers: [],
  ratings: [],
  ignoringDriverWorking: [],
  filter: {
    carCallSign: '',
    carNumber: '',
    carModel: '',
    loadSort: false,
    modelSort: false,
    last_name: '',
    dateTime: [],
    indicators: { }
  },
  changesStatistics: {
    possible_changes_count: 0,
    fortunate_changes_count: 0
  },
  currentWeek: [],
  currentDriver: {},
  currentDriverInfo: {},
  currentSchedule: {},
  currentScheduleInfo: {},
  currentCar: {},
  currentRegionParkID: null,
  currentRegionParkSettingsId: null,
  driverStatusChangingDialog: false,
  chooseDriverDialog: false,
  chooseCarDialog: false,
  showMoreAboutScheduleDialog: false,
  showMoreAboutDriverDialog: false,
  aboutCarDialog: false,
  driversInChange: [],
  carDialogData: {}
}
