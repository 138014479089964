import request from '../utils/request'

export const signIn = (userData) => request({
  method: 'post',
  url: 'users/token',
  data: userData
})

//
// export const signUp = () => request({
//   method : 'post',
//   url:'',
// })
//
//
