import { getDriverBalanceDebts } from '@/requests/tenantsInfo'
import { getDriverDepositDebts } from '@/requests/driversIncome'
const state = {
  drivers: [],
  filters: {
    start_date: null,
    end_date: null
  },
  local_filters: {
    FIO: null
  },
  isLoading: true
}

const mutations = {
  SET_DRIVERS: (state, data) => {
    state.drivers = data
  },
  SET_FILTERS: (state, data) => {
    state.filters = {
      ...state.filters,
      ...data
    }
  },
  SET_LOCAL_FILTERS: (state, data) => {
    state.local_filters = {
      ...state.local_filters,
      ...data
    }
  },
  TOOGLE_LOADING (state, value) {
    state.isLoading = value
  }
}

const getters = {
  getDriversByLocalFilters: (state) => {
    const { FIO } = state.local_filters
    if (!FIO) return state.drivers
    return state.drivers.filter(driver => driver?.driver_full_name?.toLocaleUpperCase().includes(FIO.toLocaleUpperCase()))
  },
  getDriversByArgument: (state, getters) => {
    return (type = 'ASC') => {
      return getters.getDriversByLocalFilters.reduce((acc, value) => {
        if (value?.shift_count === 0 && type === 'ASC') {
          acc = [...acc]
          return acc
        }

        if (value?.shift_count >= 1 && type === 'DESC') {
          acc = [...acc]
          return acc
        }
        const getStartDate = value?.start_date_balance >= 0 ? 0 : value?.start_date_balance
        const diff = getStartDate - value?.end_date_balance
        acc = [...acc, { ...value, diff, start_date_balance: getStartDate }]
        return acc
      }, [])
    }
  }

}

const actions = {
  setFiltersDates ({ commit, state }, { start_date, end_date }) {
    try {
      commit('SET_FILTERS', { start_date, end_date })
    } catch (e) {
    }
  },
  setLocalFiltersDates ({ commit }, data) {
    try {
      commit('SET_LOCAL_FILTERS', data)
    } catch (e) {
    }
  },
  async getDriverBalanceDebts ({ commit, state }, { start_date, end_date }) {
    try {
      commit('TOOGLE_LOADING', true)
      const data = await getDriverBalanceDebts({ start_date, end_date, region_park: state.currentRegionParkID })
      commit('SET_DRIVERS', data)
      commit('TOOGLE_LOADING', false)
    } catch (e) {
      commit('TOOGLE_LOADING', false)
      return Promise.reject(e)
    }
  },
  async getDriverDepositDebts ({ commit, state }, { start_date, end_date }) {
    try {
      commit('TOOGLE_LOADING', true)
      const data = await getDriverDepositDebts({ start_date, end_date, region_park: state.currentRegionParkID })
      commit('SET_DRIVERS', data)
      commit('TOOGLE_LOADING', false)
    } catch (e) {
      commit('TOOGLE_LOADING', false)
      return Promise.reject(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
