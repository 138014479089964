
import { variablesTranslete } from '@/enums/tamplates'
import { createTemplate, createTemplateType, deleteTemplateById, deleteTemplateTypeById, getTemplateById, getTemplates, getTemplateTypes, getTemplateVariables, updateTemplateById, updateTemplateTypeById } from '@/requests/templates'
import { showDefaultErrorMessage } from '@/utils/createMessage'
import { Message } from 'element-ui'
const state = {
  types: [],
  tamplates: [],
  variables: [],
  loading_types: true,
  car_region_park: null,
  loading_tamplates: true
}

const mutations = {
  SET_TAMPLATES: (state, data) => {
    state.tamplates = data
  },
  ADD_TAMPLATE: (state, data) => {
    state.tamplates.push(data)
  },
  UPDATE_TAMPLATE: (state, data) => {
    state.tamplates = [...state.tamplates.filter(x => x.id !== data.id), { ...state.tamplates.filter(x => x.id === data.id), ...data }]
  },
  DELETE_TAMPLATE: (state, id) => {
    state.tamplates = [...state.tamplates.filter(x => x.id !== id)]
  },
  SET_REGION_PARK: (state, data) => {
    state.car_region_park = data
  },
  SET_VARIABLES: (state, data) => {
    state.variables = data
  },
  SET_TYPES: (state, data) => {
    state.types = data
  },
  ADD_TYPE: (state, data) => {
    state.types.push(data)
  },
  UPDATE_TYPE: (state, data) => {
    state.types = [...state.types.filter(x => x.id !== data.id), { ...data }]
  },
  DELETE_TYPE: (state, id) => {
    state.types = [...state.types.filter(x => x.id !== id)]
  },
  UPDATE_LOADING_TYPES: (state, boolean) => {
    state.loading_types = boolean
  },
  UPDATE_LOADING_TAMPLATES: (state, boolean) => {
    state.loading_tamplates = boolean
  }

}

const actions = {
  async checkUniqueDocument ({ commit, state }, { contract_type, car_region_park, investor_type }) {
    try {
      const result = state.tamplates.findIndex(x => x.contract_type.id === contract_type && x.car_region_park === car_region_park && x.investor_type === investor_type)
      console.log(result)
      if (result === -1) return true
      return false
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async createTemplate ({ commit }, FormData) {
    try {
      const data = await createTemplate(FormData)

      Message({
        type: 'success',
        message: 'Шаблон успешно создан!'
      })
      commit('ADD_TAMPLATE', data)
      return data
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async getTemplateTypes ({ commit }) {
    try {
      commit('UPDATE_LOADING_TYPES', true)

      const data = await getTemplateTypes()

      commit('SET_TYPES', data)
      commit('UPDATE_LOADING_TYPES', false)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    } finally {
      commit('UPDATE_LOADING_TYPES', false)
    }
  },
  async getTemplates ({ commit, state }) {
    try {
      commit('UPDATE_LOADING_TAMPLATES', true)
      const car_region_park = state.car_region_park
      console.log(car_region_park)
      const data = await getTemplates({ car_region_park })

      commit('SET_TAMPLATES', data)
      commit('UPDATE_LOADING_TAMPLATES', false)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    } finally {
      commit('UPDATE_LOADING_TAMPLATES', false)
    }
  },
  async getTemplateVariables ({ commit }) {
    try {
      const data = await getTemplateVariables()
      commit('SET_VARIABLES', data)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async setCarRegionPark ({ commit }, park_id) {
    try {
      await commit('SET_REGION_PARK', park_id)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async createTemplateType ({ commit }, params) {
    try {
      const data = await createTemplateType(params)
      commit('ADD_TYPE', data)
      Message({
        type: 'success',
        message: 'Тип успешно создан!'
      })
      return data
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async updateTemplateTypeById ({ commit }, body) {
    try {
      console.log('body', body)
      const data = await updateTemplateTypeById(body.id, body)
      commit('UPDATE_TYPE', data)
      Message({
        type: 'success',
        message: 'Тип успешно обновлен!'
      })
      return data
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async deleteTemplateTypeById ({ commit }, id) {
    try {
      const data = await deleteTemplateTypeById(id)
      commit('DELETE_TYPE', id)
      Message({
        type: 'success',
        message: 'Тип успешно удален!'
      })
      return data
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async deleteTemplateById ({ commit }, id) {
    try {
      const data = await deleteTemplateById(id)
      commit('DELETE_TAMPLATE', id)
      // Message({
      //   type: 'success',
      //   message: 'Тип успешно удален!'
      // })
      return data
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async getTemplateById ({ commit }, id) {
    try {
      const data = await getTemplateById(id)
      if (!data?.length) return showDefaultErrorMessage()
      return data.filter(x => x.id === id)[0]
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async updateTemplateById ({ commit }, { id, data, upd }) {
    try {
      console.log(data)
      const res = await updateTemplateById(id, data)
      commit('UPDATE_TAMPLATE', upd)
      Message({
        type: 'success',
        message: 'Шаблон успешно обновлен!'
      })
      return res
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  }
}
const getters = {
  getTamplateById: (state) => (id) => state.tamplates.find(cur => cur.id === id),
  getVariablesCascaderVariant: (state) => {
    return Object.keys(state.variables).map((x, index) => {
      return {
        value: x,
        label: variablesTranslete[x],
        children: Object.keys(state.variables[x]).map((y, idx) => {
          return {
            value: y,
            label: state.variables[x][y]
          }
        })
      }
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
