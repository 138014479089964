
import { getData, addData, changeData, removeData } from '@/requests/roles'
import { Message } from 'element-ui'

const state = {
  data: []
}

const mutations = {
  SET: (state, data) => {
    state.data = data
  },
  REMOVE: (state, id) => {
    state.data = state.data.filter(cur => cur.id !== id)
  },
  ADD: (state, data) => {
    state.data.push(data)
  },
  CHANGE: (state, data) => {
    state.data.forEach(cur => {
      if (cur.id === data.id) {
        cur.name = data.name
      }
    })
  }
}

const actions = {

  fetch ({ commit }) {
    return getData()
      .then(data => {
        commit('SET', data)
        return data
      })
      .catch((error) => {
        return error
      })
  },

  remove ({ commit }, id) {
    return new Promise((resolve, reject) => {
      removeData(id).then(() => {
        commit('REMOVE', id)
        Message({
          message: 'Роль была удалена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(id)
        return id
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },

  add ({ commit }, data) {
    return new Promise((resolve, reject) => {
      addData(data).then(res => {
        commit('ADD', res)
        Message({
          message: 'Роль была добавлена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  },
  change ({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      changeData(id, data).then(res => {
        commit('CHANGE', res)
        Message({
          message: 'Информация о роли была изменена',
          type: 'success',
          duration: 5 * 1000
        })
        resolve(data)
      }).catch(error => {
        Message({
          message: 'Ошибка',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
