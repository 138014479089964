<template>
  <el-row class="app-header" type="flex">
    <div>
      <h1 style="min-width: 345px;">{{ title }}</h1>
    </div>
    <el-row type="flex" class="w100 r-panel">
      <el-select
        v-model="activePark"
        :placeholder="$_getDefaultParkName"
        @change="$_selectPark(activePark)">
        <el-option
          v-for="val in $_parksData"
          :key="val.id"
          :label="val.name"
          :value="val.id">
        </el-option>
      </el-select>
      <img class="icon_sqware" src="@/assets/svg/bell.svg" alt="Icon Rik SIde Menu"/>
      <el-dropdown>
        <el-avatar icon="el-icon-user-solid"></el-avatar>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>{{ this.$_userEmail }}</el-dropdown-item>
          <el-dropdown-item @click.native="logout()">Выйти</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>

  </el-row>
</template>

<script>
import user from '@/mixin/user'
import settings from '@/mixin/settings'

export default {
  name: 'appHeader',
  props: {
    title: String,
    avatar: String
  },
  data () {
    return {
      activePark: null
    }
  },
  mixins: [
    user,
    settings
  ],
  methods: {
    logout () {
      this.$_logout()
    }
  },
  mounted () {
    this.$_fetchSettings()
  }
}
</script>

<style lang="scss">
.el-avatar {
  cursor: pointer;
}
.r-panel{
  justify-content: end;
  gap: 27px;
  align-items: center;
}
.app-header{
  width: 100%;
}
</style>
