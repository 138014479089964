import request from '../utils/request'

export const fetchMaintenanceTypes = () => request({
  method: 'get',
  url: '/maintenance/maintenance_types/'
})

export const fetchMaintenanceWork = ({ is_deleted, on_request }) => request({
  method: 'get',
  url: '/maintenance/maintenance_work/',
  params: {
    is_deleted,
    on_request
  }
})

export const fetchMaintenanceWorkById = (id) => request({
  method: 'get',
  url: `/maintenance/maintenance_work/${id}/`
})

export const createMaintenanceWork = (data) => request({
  method: 'post',
  url: '/maintenance/maintenance_work/',
  data
})

export const deleteMaintenanceWork = (data) => request({
  method: 'delete',
  url: `/maintenance/maintenance_work/${data.id}/`,
  data
})

export const updateMaintenanceWork = (data) => request({
  method: 'put',
  url: `/maintenance/maintenance_work/${data.id}/`,
  data
})

export const acceptMaintenance = ({ id, data }) => request({
  method: 'post',
  url: `/maintenance/maintenance_work/${id}/accept_maintenance/`,
  data
})

export const fetchMaintenanceWorkHistory = () => request({
  method: 'get',
  url: '/maintenance/maintenance_work/history'
})

export const fetchMaintenanceHistoryById = (id) => request({
  method: 'get',
  url: `/maintenance/maintenance_work/history/${id}/`
})

export const returnCarToOnline = (id) => request({
  method: 'post',
  url: `/maintenance/maintenance_work/return_car_online/${id}`
})
