import request from '../utils/request'
const URL = 'taxi/investors/'

export const getInvestors = () => request({
  method: 'get',
  url: URL
})

export const getInvestorById = id => request({
  method: 'get',
  url: URL + id
})

export const addInvestor = data => request({
  method: 'post',
  url: URL,
  data
})

export const removeInvestor = id => request({
  method: 'delete',
  url: URL + id
})

export const updateInvestor = data => request({
  method: 'put',
  url: URL + data.id + '/',
  data
})
