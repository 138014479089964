import {
  changeDriverRatingData,
  changeDriverSchedule,
  createDriverSchedule,
  getCurrenScheduleData, getDriversInChange, getRatings,
  getScheduleInRange, getScheduleReportExel,
  getSheduleDrivers,
  getStatistic, removeSchedule,
  transplantDriver
} from '@/requests/shedule'
import { Message } from 'element-ui'
import { errorResponseMessage, showDefaultErrorMessage, showErrorMessage } from '@/utils/createMessage'
import { callUpdateScheduleFiveMinutely } from '@/requests/tasker'
import { SET_DRIVERS_IN_CHANGE } from './constants'
import * as helpers from '@/store/modules/schedule/helpers'
import {
  createContracts
} from '@/requests/contracts'
export const actions = {
  async transplantDriver ({
    dispatch,
    commit,
    getters,
    rootGetters
  }, {
    date,
    change,
    car_id,
    driver_id,
    current_schedule_id
  }) {
    try {
      const park_id = rootGetters['settings/getDefaultParkPk']
      await transplantDriver({
        date,
        change,
        car_id,
        driver_id,
        park_id,
        current_schedule_id
      })
        .then(() => {
          Message({
            message: 'Водитель был перенесен',
            type: 'success',
            duration: 5 * 1000
          })
        })
        .catch(error => {
          if (!error?.response?.data?.success) {
            Message({
              message: error?.response?.data?.message || 'Ошибка',
              type: 'error',
              duration: 5 * 1000
            })
          } else {
            showDefaultErrorMessage()
          }
        })
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async fetchStatistic ({
    commit,
    state
  }, {
    start_date,
    end_date
  }) {
    try {
      const StatisticShanges = await getStatistic({
        start_date,
        end_date,
        region_park_id: state.currentRegionParkID
      })
      commit('SET_CHANGES', StatisticShanges)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },
  async fetchSchedule ({
    commit,
    state
  }, {
    startDate,
    endDate
  }) {
    try {
      commit('TOOGLE_LOADING', true)

      if (!startDate || !endDate) {
        const week = state.currentWeek

        startDate = week[0] || ''
        endDate = week[week.length - 1] || ''
      } else {
        const dates = helpers.getDatesInRange(startDate, endDate)
        commit('SET_CURRENT_WEEK', dates)
      }

      const schedule = await getScheduleInRange({
        start_date: startDate,
        end_date: endDate,
        region_park_id: state.currentRegionParkID
      })

      commit('SET_SHEDULE', schedule)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    } finally {
      commit('TOOGLE_LOADING', false)
    }
  },

  async fetchCurrentSchedule ({ commit }, id) {
    try {
      const schedule = await getCurrenScheduleData(id)

      commit('SET_CURRENT_SCHEDULE_INFO', schedule)

      return schedule
    } catch (e) {
      showDefaultErrorMessage()
      return Promise.reject(e)
    }
  },

  async fetchAvailableDrivers ({ commit }, {
    timesDay,
    date
  }) {
    try {
      if (timesDay === 'day') {
        timesDay = 'morning'
      }
      const drivers = await getSheduleDrivers(timesDay, date)

      commit('SET_AVALIABLE_DRIVERS', drivers)
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async addDriverSchedule ({
    commit,
    state,
    dispatch
  }, data) {
    try {
      commit('TOOGLE_LOADING', true)
      console.log('addDriverSchedule', data)
      // const res = await createDriverSchedule(data)
      const resultCreateDrivers = await createDriverSchedule(data)

      Message({
        message: 'Расписание было изменено',
        type: 'success',
        duration: 5 * 1000
      })

      const week = state.currentWeek
      const startDate = week[0]
      const endDate = week[week.length - 1]
      const schedule = await getScheduleInRange({
        start_date: startDate,
        end_date: endDate,
        region_park_id: state.currentRegionParkID
      })

      commit('SET_SHEDULE', schedule)
      for (let index = 0; index < resultCreateDrivers.length; index++) {
        console.log(resultCreateDrivers[index])
        const { schedule_id, driver_id, car_id } = resultCreateDrivers[index]
        setTimeout(() => {
          Promise.all([createContracts({ car: car_id, driver: driver_id }), callUpdateScheduleFiveMinutely({ date: data[0]?.date, schedule_id })])
        }, 500)
      }
      // const newSchedule = {
      //   driver_name: res.driver_name,
      //   schedule_id: res.id,
      //   is_documents_exists: res.is_documents_exists,
      //   rik_status: res.rik_status,
      //   id: res.driver,
      //   status: res.status
      // }
      //
      // commit('SET_DRIVER_IN_SCHEDULE', {
      //   carId: res.car,
      //   timesDay: res.change === 'morning' ? 'day' : 'night',
      //   date: res.date,
      //   schedule: newSchedule
      // })
    } catch (e) {
      await errorResponseMessage(e.response.data)
      return Promise.reject(e)
    } finally {
      commit('TOOGLE_LOADING', false)
    }
  },

  async changeDriverFromSchedule ({
    commit,
    state,
    dispatch
  }, {
    data,
    id
  }) {
    try {
      const week = state.currentWeek

      // commit('TOOGLE_LOADING', true)

      await changeDriverSchedule(id, data)

      await Message({
        message: 'Водитель был изменен в этой смене',
        type: 'success',
        duration: 5 * 1000
      })

      await dispatch('fetchSchedule', {
        startDate: week[0],
        endDate: week[week.length - 1]
      })
    } catch (e) {
      // await errorResponseMessage(e.response.data)
      await errorResponseMessage(e.response.data)
      return Promise.reject(e)
    } finally {
      commit('TOOGLE_LOADING', false)
    }
  },

  async fetchRatings ({ commit }, id) {
    try {
      const ratings = await getRatings(id)

      commit('SET_RATINGS', ratings)

      return ratings
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async changeDriverRating ({
    commit,
    dispatch
  }, data) {
    try {
      const responce = await changeDriverRatingData(data)

      Message({
        message: 'Оценка была внесена в систему',
        type: 'success',
        duration: 5 * 1000
      })

      dispatch('fetchRatings', data.driver)

      return responce
    } catch (err) {
      showDefaultErrorMessage()
      return Promise.reject(err)
    }
  },

  async fetchScheduleReportExelData (_, dto) {
    try {
      return await getScheduleReportExel(dto)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  removeScheduleByCarId ({ commit }, car_id) {
    commit('REMOVE_SCHEDULE_BY_CAR_ID', car_id)
  },

  removeSchedule ({
    commit,
    state,
    dispatch
  }, id) {
    return new Promise((resolve, reject) => {
      const week = state.currentWeek

      removeSchedule(id)
        .then(() => {
          Message({
            message: 'Водитель был снят со смены',
            type: 'success',
            duration: 5 * 1000
          })

          dispatch('fetchSchedule', {
            startDate: week[0],
            endDate: week[week.length - 1]
          })

          resolve(id)
        })
        .catch(error => {
          if (error.response?.data?.message) return showErrorMessage(error.response?.data?.message, 5 * 1000)
          errorResponseMessage(error.response?.data?.message).then(r => r)
          reject(error)
        })
    })
  },

  setCurrentRegionParkID ({ commit }, region_park_id) {
    commit('SET_CURRENT_REGION_PARK_ID', region_park_id)
  },

  setCurrentRegionParkSettingsId ({ commit }, region_park_settings_id) {
    commit('SET_CURRENT_REGION_PARK_SETTINGS_ID', region_park_settings_id)
  },

  changeDialogVisible ({ commit }, {
    dialogName,
    value
  }) {
    commit('CHANGE_DIALOG_VISIBLE', {
      dialogName,
      value
    })
  },
  openCarDialog ({ commit }, data) {
    commit('SET_DATA_CAR_DIALOG', data)
    commit('SET_VISIBLE_CAR_DIALOG', true)
  },
  closeCarDialog ({ commit }, data) {
    commit('SET_DATA_CAR_DIALOG', {})
    commit('SET_VISIBLE_CAR_DIALOG', false)
  },
  setCurrentDriver ({ commit }, driver) {
    commit('SET_CURRENT_DRIVER', driver)
  },

  setCurrentDriverInfo ({ commit }, info) {
    commit('SET_CURRENT_DRIVER_INFO', info)
  },

  setCurrentSchedule ({ commit }, schedule) {
    commit('SET_CURRENT_SCHEDULE', schedule)
  },
  setIgnoringDriverWorking ({ commit, state }, data) {
    if (state?.ignoringDriverWorking.filter(driver => driver.driver_id === data?.driver_id)?.length > 0) {
      return Message({
        message: 'Водитель уже добавлен в список игнорирумеых!',
        type: 'error',
        duration: 5 * 1000
      })
    }
    commit('SET_IGNORING_DRIVER_WORKING', data)
    Message({
      message: 'Водитель был добавлен в список игнорируемых!',
      type: 'success',
      duration: 5 * 1000
    })
  },

  filterSchedule ({ commit }, {
    carCallsign,
    carNumber,
    carModel,
    loadSort,
    modelSort,
    dateTime,
    indicators
  }) {
    commit('SET_FILTER', {
      carCallsign,
      carNumber,
      carModel,
      loadSort,
      modelSort,
      dateTime,
      indicators
    })
  },

  filterDriversBySecondName ({ commit }, secondName) {
    commit('SET_DRIVERS_FILTER', secondName)
  },

  setCurrentCar ({ commit }, id) {
    commit('SET_CURRENT_CAR', id)
  },

  async fetchDriversInChange ({ commit }, params) {
    try {
      const drivers = await getDriversInChange(params)
      // debugger
      commit(SET_DRIVERS_IN_CHANGE, drivers)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}
